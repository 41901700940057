import { useEffect, useState } from "react";
import accountIcon from "../images/account.png";
import ConnectEmailAccount from "../modals/ConnectEmailAccount";
import mailIcon from "../images/email-dark.png";
import Variables from "../variables";
import axios from "axios";

function EmailAccounts({ data }) {
  const [showConnectEmailAccount, setShowConnectEmailAccount] = useState(false);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [emailAccounts, setEmailAccounts] = useState([]);
  const [removeDisabledBtn, setRemoveDisabledBtn] = useState("disabled");
  const handleRemoveAccounts = (e) => {
    let req_data = Variables.create_req_data({
      action: "delete",
      accounts: selectedAccounts,
    });
    Variables.loading_btn(e.target, "Deleting");
    axios.post(Variables.serverUrl("email-accounts/"), req_data).then((res) => {
      if (res.status == 200) {
        Variables.unloading_btn(e.target);
        setRemoveDisabledBtn("disabled");
        const filteredList = emailAccounts.filter(
          (obj) => !selectedAccounts.includes(obj.uid)
        );
        setEmailAccounts(filteredList);
      }
    });
  };
  const handleShowConnectEmailAccount = () => setShowConnectEmailAccount(true);
  const handleCloseConnectEmailAccount = () =>
    setShowConnectEmailAccount(false);
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedAccounts([...selectedAccounts, value]);
    } else {
      setSelectedAccounts(selectedAccounts.filter((item) => item !== value));
    }
  };
  useEffect(() => {
    if (selectedAccounts.length) {
      setRemoveDisabledBtn("");
    } else {
      setRemoveDisabledBtn("disabled");
    }
  }, [selectedAccounts]);
  useState(() => {
    let req_data = Variables.create_req_data({
      action: "get-all",
    });
    axios.post(Variables.serverUrl("email-accounts/"), req_data).then((res) => {
      if (res.status == 200) {
        setEmailAccounts(res.data.emails);
      }
    });
  }, []);
  return (
    <>
      <br />
      <div className="flex-horizon">
        <img src={accountIcon} height={25} />
        &nbsp;&nbsp;
        <h5>Your associated email accounts</h5>
      </div>
      <br />
      <button
        className="btn btn-sm btn-dark theme-btn"
        onClick={handleShowConnectEmailAccount}
      >
        + Connect an account
      </button>
      &nbsp;&nbsp;
      <button
        className="btn btn-sm btn-danger"
        onClick={handleRemoveAccounts}
        disabled={removeDisabledBtn}
      >
        Remove
      </button>
      <br />
      <br />
      <p><b>({emailAccounts.length}) Accounts</b></p>
      <table id="tickets-table" class="table table-hover table-borderless">
        <thead className="table-dark">
          <tr>
            <th scope="col"></th>
            <th scope="col">Email ID</th>
            <th scope="col">Type</th>
            <th scope="col">Date added</th>
          </tr>
        </thead>
        <tbody>
          {emailAccounts.length ? (
            <>
              {emailAccounts.map((item, index) => (
                <>
                  <tr>
                    <th scope="row">
                      <input
                        type="checkbox"
                        className="form-check-input ticket-selector"
                        value={item.uid}
                        onClick={handleCheckboxChange}
                      />
                    </th>
                    <td className="limit-text">
                      <font href="#">{item.email}</font>
                    </td>
                    <td className="flex-horizon">
                      <img height={20} src={mailIcon} />
                      &nbsp;&nbsp;{item.type}
                    </td>
                    <td>{Variables.formatDate(item.date_created)}</td>
                  </tr>
                </>
              ))}
            </>
          ) : (
            <>
              <p>No account is associated yet.</p>
            </>
          )}
        </tbody>
      </table>
      <ConnectEmailAccount
        show={showConnectEmailAccount}
        handleClose={handleCloseConnectEmailAccount}
        setEmailAccounts={setEmailAccounts}
        emailAccounts={emailAccounts}
      />
    </>
  );
}

export default EmailAccounts;
