import Variables from "../variables";
import $ from "jquery";
import axios from "axios";
import groupIcon from "../images/group.png";
import emailIcon from "../images/email-dark.png";
import userIcon from "../images/user.svg";
import botIcon from "../images/bot.svg";
import formIcon from "../images/form.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SelectBots from "../modals/SelectBots";
import SelectMembers from "../modals/SelectMembers";
import SelectMails from "../modals/SelectEmails";

let is_member = localStorage.getItem("sharurai_member_login");

function updateBotAccess(e) {
  let access_id = $(e.target).attr("access_id");
  let can_edit = $("#" + access_id)
    .find(".editing")
    .prop("checked");
  let can_chat = $("#" + access_id)
    .find(".chatting")
    .prop("checked");
  let req_data = Variables.create_req_data({
    action: "update",
    uid: access_id,
    ele: "bot",
    chatting: can_chat,
    editing: can_edit,
  });
  Variables.loading_btn(e.target, "Updating");
  axios.post(Variables.serverUrl("groups/"), req_data).then((res) => {
    Variables.unloading_btn(e.target);
  });
}

function Group({ show, handleClose, id, data }) {
  const [assignedEmails, setAssignedEmails] = useState([]);
  useEffect(() => {
    if (data.emails) {
      setAssignedEmails(data.emails);
    }
  }, [data]);
  const [selectEmailsShow, setSelectEmailsShow] = useState(false);
  const handleSelectEmailsShow = () => setSelectEmailsShow(true);
  const handleSelectEmailsClose = () => setSelectEmailsShow(false);
  const [members, setMembers] = useState([]);
  const [bots, setBots] = useState([]);
  const [showSelectBot, setShowSelectBot] = useState(false);
  const handleCloseSelectBot = () => {
    setShowSelectBot(false);
  };
  const handleShowSelectBot = () => {
    setShowSelectBot(true);
  };

  const handleSelectEmails = (ele, data, handleClose) => {
    if (data.length) {
      let lst = [];
      data.forEach((item) => {
        lst.push(item.uid);
      });
      let req_data = Variables.create_req_data({
        action: "add-emails",
        emails: lst,
        uid: id,
      });
      axios
        .post(Variables.serverUrl("groups/"), req_data)
        .then((res) => {
          if (res.status == 200) {
            const combinedList = [...data, ...assignedEmails];

            const newList = combinedList.filter((item, index, self) => {
              return index === self.findIndex((t) => t.uid === item.uid);
            });
            setAssignedEmails(newList);
            handleClose();
          }
        })
        .catch((e) => {
          Variables.unloading_btn(ele);
        });
    }
  };

  const handleRemoveEmail = (data) => {
    let req_data = Variables.create_req_data({
      action: "remove-email",
      email: data.uid,
      uid: id,
    });
    axios.post(Variables.serverUrl("groups/"), req_data).then((res) => {
      if (res.status) {
        const updatedList = assignedEmails.filter((obj) => obj.uid !== data.uid);
        setAssignedEmails(updatedList);
      }
    });
  };

  //Members
  const [showSelectMember, setShowSelectMember] = useState(false);
  const handleCloseSelectMember = () => {
    setShowSelectMember(false);
  };
  const handleShowSelectMember = () => {
    setShowSelectMember(true);
  };

  async function selectBotFunction(data) {
    let botsLst = data.bots;
    let lst = [];
    botsLst.forEach((element) => {
      lst.push(element.uid);
    });
    let req_data = Variables.create_req_data({
      action: "add-bots",
      group_id: id,
      uids: lst,
    });
    axios.post(Variables.serverUrl("groups/"), req_data).then((res) => {
      if (res.status == 201) {
        let bots = res.data.bots;
        bots.forEach((ele) => {
          let bot = ele;
          setBots((prevList) => [
            ...prevList,
            {
              name: bot.name,
              uid: bot.uid,
              editing: ele.editing,
              chat: ele.chat,
              access_id: ele.access_id,
            },
          ]);
        });
      }
    });
  }
  async function removeMember(e) {
    let uid = $(e.target).attr("uid");
    let req_data = Variables.create_req_data({
      action: "remove-member",
      uid: uid,
      group_id: id,
    });
    axios.post(Variables.serverUrl("groups/"), req_data).then((res) => {
      if (res.status == 200) {
        const indexToDelete = members.findIndex((item) => item.uid === uid);
        if (indexToDelete !== -1) {
          members.splice(indexToDelete, 1);
          setMembers(members);
          $("#" + uid).remove();
        }
      }
    });
  }
  async function selectMemberFunction(data) {
    let membersLst = data.members;
    let lst = [];
    membersLst.forEach((element) => {
      lst.push(element.uid);
    });
    let req_data = Variables.create_req_data({
      action: "add-members",
      group_id: id,
      uids: lst,
    });
    axios.post(Variables.serverUrl("groups/"), req_data).then((res) => {
      if (res.status == 201) {
        let members = res.data.members;
        members.forEach((ele) => {
          let member = ele;
          if (!$("#" + member.uid).length) {
            setMembers((prevList) => [
              ...prevList,
              { name: member.name, uid: member.uid, email: member.email },
            ]);
          }
        });
      }
    });
  }
  const revokeBotAccess = async (ele) => {
    Variables.loading_btn(ele.target, "Revoking");
    let uid = $(ele.target).attr("uid");
    let req_data = Variables.create_req_data({
      action: "revoke-bot",
      group_id: id,
      uid: uid,
    });
    await axios.post(Variables.serverUrl("groups/"), req_data).then((res) => {
      if (res.status == 200) {
        const indexToDelete = bots.findIndex((item) => item.uid === uid);
        if (indexToDelete !== -1) {
          bots.splice(indexToDelete, 1);
          setBots(bots);
          $("#" + req_data.uid).remove();
        }
      }
    });
    Variables.unloading_btn(ele.target);
  };

  const updateForm = (ele) => {
    let checked = $(ele.target).prop("checked");
    let req_data = Variables.create_req_data({
      uid: id,
      action: "update",
      ele: "forms",
      checked: checked,
    });
    $(ele.target).prop("disabled", true);
    axios.post(Variables.serverUrl("groups/"), req_data).then((res) => {
      if (res.status == 200) {
        $(ele.target).prop("disabled", false);
      }
    });
  };

  const updateBotInfo = (e) => {
    e.preventDefault();
    let name = $("#group-name-input").val();
    let info = $("#group-info-input").val();
    let req_data = Variables.create_req_data({
      action: "update",
      ele: "group-info",
      name: name,
      info: info,
      uid: id,
    });
    Variables.loading_btn(e.target, "Saving");
    axios.post(Variables.serverUrl("groups/"), req_data).then((res) => {
      if (res.status == 200) {
        Variables.unloading_btn(e.target);
      }
    });
  };
  useEffect(() => {
    setMembers(data.members);
    setBots(data.bots);
  }, [data]);
  return (
    <>
      <div
        className={`offcanvas offcanvas-end ${show ? "show" : ""}`}
        tabIndex="-1"
        style={{ width: "90%" }}
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title">
            <div className="btn btn-light" onClick={handleClose}>
              <b title="back" type="button">
                &#x2190;&nbsp;&nbsp;
              </b>
              Back
            </div>
            &nbsp;&nbsp;
            <img src={groupIcon} height={15} />
            &nbsp;<font style={{ fontSize: ".9rem" }}>Group ({data.name})</font>
          </h5>
        </div>
        <div
          className="offcanvas-body"
          id="ticket-panel"
          style={{ paddingTop: "5px" }}
        >
          <div className="row" style={{ height: "100%" }}>
            <div className="col-7">
              <form id="group-form" style={{ maxWidth: "450px" }}>
                <div class="mb-3">
                  {is_member == "true" ? (
                    <>
                      <b>Group name</b>
                      <br />
                      <b className="form-text">{data.name}</b>
                    </>
                  ) : (
                    <>
                      <label>Name</label>
                      <input
                        id="group-name-input"
                        name="name"
                        className="form-control"
                        placeholder=""
                        defaultValue={data.name}
                      />
                    </>
                  )}
                  <br />
                  <br />
                  {is_member == "true" ? (
                    <>
                      <b>Group info</b>
                      <br />
                      <b className="form-text">{data.info}</b>
                    </>
                  ) : (
                    <>
                      <label>Group info</label>
                      <textarea
                        id="group-info-input"
                        name="group-info"
                        className="form-control"
                        defaultValue={data.info}
                      ></textarea>
                    </>
                  )}
                </div>
                {is_member == "true" ? (
                  <></>
                ) : (
                  <button
                    type="submit"
                    class="btn btn-primary theme-btn"
                    onClick={updateBotInfo}
                  >
                    Save
                  </button>
                )}
              </form>
              <br />
              <div id="group-mail">
                <h5>Group mail</h5>
                <div className="container">
                  <small style={{ color: "grey" }}>
                    Group email empowers members to communicate with customers,
                    sending updates and notifications efficiently.
                  </small>
                  <br />
                  <br />
                  {assignedEmails.length ? (
                    <>
                      {assignedEmails.map((item, index) => (
                        <div
                          className="flex-horizon"
                          style={{
                            padding: ".5rem",
                            border: ".5px solid #d6d6d6",
                            borderRadius: "5px",
                            marginBottom: ".5rem",
                          }}
                        >
                          <img src={emailIcon} height={20} />
                          &nbsp;&nbsp;
                          <small>
                            {item.email} ({item.type})
                          </small>
                          &nbsp;
                          <font
                            title="Remove"
                            className="remove-email"
                            style={{ fontSize: "1.1rem", cursor: "pointer" }}
                            onClick={(e) =>
                              handleRemoveEmail({ uid: item.uid })
                            }
                          >
                            &#x292B;
                          </font>
                        </div>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                  {is_member == "true" ? (
                    <></>
                  ) : (
                    <>
                      <br />
                      <button
                        className="btn btn-small theme-btn"
                        style={{ width: "100%" }}
                        onClick={handleSelectEmailsShow}
                      >
                        Assign a mail
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div
              className="col-5"
              style={{
                maxHeight: "-webkit-fill-available",
                overflowY: "scroll",
              }}
            >
              <div id="group-members">
                <h5>Members</h5>
                <ul style={{ padding: 0 }}>
                  {members.map((item, index) => (
                    <>
                      <li
                        id={item.uid}
                        className="card limit-text"
                        style={{ maxWidth: "100%" }}
                      >
                        <div
                          className="card-body flex-horizon"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div className="flex-horizon">
                            <img src={userIcon} height={25} />
                            &nbsp;&nbsp;
                            <div>
                              <Link href="#" style={{ fontWeight: "bold" }}>
                                {item.name}
                              </Link>
                              <br />
                              <font className="form-text">{item.email}</font>
                            </div>
                          </div>
                          {is_member == "true" ? (
                            <></>
                          ) : (
                            <b
                              style={{ cursor: "pointer" }}
                              uid={item.uid}
                              onClick={removeMember}
                            >
                              &#x292B;
                            </b>
                          )}
                        </div>
                      </li>
                    </>
                  ))}
                </ul>
                {is_member == "true" ? (
                  <></>
                ) : (
                  <button
                    className="btn btn-dark theme-btn"
                    onClick={handleShowSelectMember}
                  >
                    + Add members
                  </button>
                )}
              </div>
              <div id="group-bots-access">
                <h5>Accessible bots</h5>
                <ul
                  style={{
                    padding: 0,
                    listStyle: "none",
                    maxHeight: "400px",
                    overflowY: "scroll",
                  }}
                >
                  {bots.map((item, index) => (
                    <li className="bot" id={item.uid}>
                      <div style={{ maxWidth: "250px" }} className="limit-text">
                        <img src={botIcon} height={25} />
                        &nbsp;&nbsp;
                        <b>
                          <Link
                            to={"/app/insights/?id=" + item.uid}
                            className="limit-text"
                          >
                            {item.name}
                          </Link>
                        </b>
                      </div>
                      <div>
                        <br />
                        <p>Access to</p>
                        <ul className="bot-access" id={item.access_id}>
                          <li
                            className="flex-horizon"
                            style={{ alignItems: "center" }}
                          >
                            {is_member == "true" ? (
                              <>
                                {item.editing ? (
                                  <>
                                    <font style={{ color: "green" }}>
                                      &#x2713;
                                    </font>
                                  </>
                                ) : (
                                  <font style={{ color: "red" }}>&#x292B;</font>
                                )}
                              </>
                            ) : (
                              <input
                                type="checkbox"
                                className="form-check-input editing"
                                defaultChecked={item.editing}
                              />
                            )}
                            <label>&nbsp;&nbsp;Editing</label>
                          </li>
                          <li
                            className="flex-horizon"
                            style={{ alignItems: "center" }}
                          >
                            {is_member == "true" ? (
                              <>
                                {item.chat ? (
                                  <>
                                    <font style={{ color: "green" }}>
                                      &#x2713;
                                    </font>
                                  </>
                                ) : (
                                  <font style={{ color: "red" }}>&#x292B;</font>
                                )}
                              </>
                            ) : (
                              <input
                                type="checkbox"
                                className="form-check-input chatting "
                                defaultChecked={item.chat}
                              />
                            )}
                            <label>&nbsp;&nbsp;Live chat</label>
                          </li>
                        </ul>
                        <br />
                        {is_member == "true" ? (
                          <></>
                        ) : (
                          <>
                            <button
                              className="btn btn-dark round-btn btn-sm"
                              uid={item.uid}
                              onClick={revokeBotAccess}
                            >
                              Revoke access
                            </button>
                            &nbsp;&nbsp;
                            <button
                              className="btn btn-success round-btn btn-sm"
                              access_id={item.access_id}
                              bot_id={item.uid}
                              onClick={updateBotAccess}
                            >
                              Save
                            </button>
                          </>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
                {is_member == "true" ? (
                  <></>
                ) : (
                  <button
                    className="btn btn-dark theme-btn"
                    onClick={handleShowSelectBot}
                  >
                    + Add bot
                  </button>
                )}
              </div>
              <div
                id="forms-access"
                className="card-body"
                style={{ justifyContent: "space-between" }}
              >
                <h5>Forms access</h5>
                <small style={{ color: "grey", fontSize: ".8rem" }}>
                  All the member of this group will have access to all the
                  forms.
                </small>
                <br />
                <br />
                <div className="flex-horizon" style={{ flexDirection: "row" }}>
                  &nbsp;&nbsp;
                  <img src={formIcon} height={25} />
                  &nbsp;&nbsp;
                  <label>&nbsp;&nbsp;Forms</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {show && (
        <div
          className="offcanvas-backdrop fade show"
          onClick={handleClose}
        ></div>
      )}
      <SelectBots
        handleClose={handleCloseSelectBot}
        show={showSelectBot}
        selectBotFunction={selectBotFunction}
      />
      <SelectMembers
        handleClose={handleCloseSelectMember}
        show={showSelectMember}
        selectMemberFunction={selectMemberFunction}
      />
      <SelectMails
        show={selectEmailsShow}
        handleClose={handleSelectEmailsClose}
        func={handleSelectEmails}
      />
    </>
  );
}

export default Group;
