import { useEffect, useState } from "react";
import $ from "jquery";
import Variables from "../variables";
import axios from "axios";

function render_cards(all_cards) {
  console.log("parsing cards ",all_cards)
  let cards = "";
  let cards_container=""
  all_cards.forEach((obj) => {
    cards += `
    <li class="card ui-state-default">
    <div class="top">
      <div class="container card-img" style="background:url('${obj["img-url"]}')">
        <img src="${obj["img-url"]}" />
      </div>
    </div>
    <div class="card-body">
      <b class="card-title">
        ${obj.title}
      </b>
      <br />
      <p class="card-description">
        ${obj.description}
      </p>
      <button class="card-btn btn btn-sm btn-warning">
        ${obj["button-name"]}
      </button>
    </div>
  </li>
    `;
  });
  cards_container = `
    <div id="cards">
      <div class="container">
        <div class="cards-list">
          <ul id="sortable-cards">
            ${cards}
          </ul>
        </div>
      </div>
    </div>`;
  return cards_container;
}

function Section3() {
  const [asking, setAsking] = useState(false);
  const [submitBtnName, setSubmitBtnName] = useState("");
  const [formTitle, setFormTitle] = useState("");
  const [endpointUrl, setEndpointUrl] = useState("");
  const [xbot_intent_id, setXbot_intent_id] = useState("");
  const [xbot_user_id,setXbot_user_id]=useState("")
  const [endpointMethod, setEndpointMethod] = useState("");
  useEffect(() => {
    $("#intent-test-form").on("submit", function (e) {
      e.preventDefault();
      let intent_id = $("#intent-test-btn").attr("uid");
      if (!asking) {
        setAsking(true);
        $("#intent-test-btn").prop("disabled", true);
        let req_data = Variables.create_req_data({
          intent_id: intent_id,
          input: $("#test-input").val(),
        });
        Variables.loading_btn("#intent-test-btn", "Testing");
        $("#message-box").append(
          `<li class="user-message"><div class="message">${$(
            "#test-input"
          ).val()}</div></li>`
        );
        axios
          .post(Variables.serverUrl("test-intent/"), req_data)
          .then((res) => {
            console.log(res.data)
            Variables.unloading_btn("#intent-test-btn");
            $("#intent-test-btn").prop("disabled", false);
            setAsking(false);
            $("#response-intent").html(res.data.intent);
            let form_data = "";
            let form = "";

            setFormTitle(res.data.form_title);
            setEndpointUrl(res.data.url);
            setXbot_intent_id(res.data.intent_id);
            setXbot_user_id(res.data.user_id)
            setEndpointMethod(res.data.form_method);
            if (res.data.is_form) {
              form_data = JSON.parse(res.data.form)[0].fields;
              form = JSON.parse(form_data.data);
              setSubmitBtnName(form_data.submit_button);
              form.forEach((element) => {
                if (Object.keys(element) == "input") {
                  $("#bot-form .fields").prepend(`
                  <br/>
                  <div class="field">
                  <b>${element.input.label}</b>
                   <input class="form-control" type="${element.input.input_type}" name="${element.input.name}" required/>
                  </div>
                `);
                } else if (Object.keys(element) == "select") {
                  let options = "";
                  element.select.options.forEach((ele) => {
                    options += `<option value="${ele}">${ele}</option>`;
                  });
                  $("#bot-form .fields").prepend(`
                <br/>
                <div class="field">
                <b>${element.select.label}</b>
                  <select name="${element.select.name}" class="form-control" required>
                    ${options}
                  </select>
                </div>
                `);
                } else if (Object.keys(element) == "radio") {
                  let options = "";
                  element.radio.options.forEach((ele) => {
                    options += `<input class="form-check-input" type='radio' value="${ele}" name="${element.radio.name}"/>&nbsp;<label class="form-check-label">${ele}</label><br/>`;
                  });
                  $("#bot-form .fields").prepend(`
                <br/>
                <div class="field">
                <b>${element.radio.label}</b><br/>
                  ${options}
                </div>
                `);
                } else if (Object.keys(element) == "checkbox") {
                  let options = "";
                  element.checkbox.options.forEach((ele) => {
                    options += `<input class="form-check-input" type='checkbox' value="${ele}" name="${element.checkbox.name}"/>&nbsp;<label class="form-check-label">${ele}</label><br/>`;
                  });
                  $("#bot-form .fields").prepend(`
                <br/>
                <div class="field">
                <b>${element.checkbox.label}</b><br/>
                  ${options}
                </div>
                `);
                } else if (Object.keys(element) == "textarea") {
                  $("#bot-form .fields").prepend(`
                <br/>
                <div class="field">
                <b>${element.textarea.label}</b><br/>
                  <textarea class="form-control" name="${element.textarea.name}"></textarea>
                </div>
                `);
                } else if (Object.keys(element) == "date") {
                  $("#bot-form .fields").prepend(`
                <br/>
                <div class="field">
                <b>${element.date.label}</b><br/>
                  <input class="form-control" placeholder="Upload" type="date" name="${element.date.name}" />
                </div>
                `);
                }
              });
              $("#bot-form").show();
            } else {
              console.log("now rendring this ",res.data)
              $("#bot-form").hide();
              let buttons = "";
              try{
                res.data.buttons.forEach((ele) => {
                  buttons += `<button class="btn btn-sm btn-outline-primary">${ele.name}</button>`;
                });
              }catch(err){
                //pass
              }
              let cards_container = "";
              if (res.data.cards !== undefined) {
                cards_container=render_cards(res.data.cards);
              } else {
                cards_container = "";
              }
              if (res.data.response !== undefined) {
                $("#message-box").append(
                  `<li class="bot-message"><div class="message">${res.data.response}</div></li>${cards_container}<br/>${buttons}`
                );
              }
            }
            $("#bot-form .form-body").on("submit", function (ev) {
              $("#test-form-submit-btn").prop("disabled", true);
              $.ajax({
                method: "post",
                type: res.data.endpoint_method,
                url: Variables.serverUrl("testing-endpoint/"),
                data: $("#bot-form .form-body").serialize(),
                success: function (data) {
                  data=data.response
                  let buttons = "";
                  let cards_container=""
                  console.log("response data is ",data)
                  $("#test-form-submit-btn").prop("disabled", false);
                  $("#bot-form").hide();
                  $("#bot-form .form-body .field").remove();
                  if (data.err) {
                    $("#test-err-msg").text(data.response.err_msg);
                    $("#test-err-msg").show();
                  } else {
                    $("#test-err-msg").hide();
                    if (data.response.response != undefined) {
                      $("#message-box").append(
                        `<li class="bot-message"><div class="message">${data.response.response}</div>${buttons}</li>`
                      );
                    }
                    if (data.response.buttons != undefined) {
                      data.response.buttons.forEach((ele) => {
                        buttons += `<button class="btn btn-sm btn-outline-primary">${ele.name}</button>`;
                      });
                    }
                    if(data.response.cards!==undefined){
                      cards_container=render_cards(data.response.cards)
                    }
                    if (data.response.response != undefined) {
                      $("#message-box").append(
                        `<li class="bot-message">${cards_container}${buttons}</li>`
                      );
                    }
                  }
                },
              });
              ev.preventDefault();
            });
          })
          .catch((e) => {
            console.log(e)
            console.log("Something went wrong. Please try again.");
            Variables.unloading_btn("#intent-test-btn");
          });
      }
    });
  }, []);
  return (
    <div
      id="intent-test"
      style={{ display: "none" }}
      className="col-md-4 col-sm-12 col-lg-4"
    >
      <div className="header">
        <h5>Test your intent</h5>
      </div>
      <div id="test-chat-body">
        <div
          id="bot-form"
          style={{
            display: "none",
            background: "#FCF8EC",
            "overflow-y": "scroll",
          }}
        >
          <div className="container">
            <br />
            <h5
              style={{ width: "fit-content", margin: "auto" }}
              className="form-title"
            >
              {formTitle}
            </h5>
            <form className="form-body">
              <div className="fields">
              <input
                  name="xbot-user-id"
                  value={xbot_user_id}
                  style={{ display: "none" }}
                />
                <input
                  name="xbot-intent-id"
                  value={xbot_intent_id}
                  style={{ display: "none" }}
                />
                <input
                  id="xbot-endpoint-url"
                  name="xbot-endpoint-url"
                  value={endpointUrl}
                  style={{ display: "none" }}
                />
                <input
                  id="xbot-endpoint-method"
                  name="xbot-endpoint-method"
                  value={endpointMethod}
                  style={{ display: "none" }}
                />
              </div>
              <br />
              <button
                id="test-form-submit-btn"
                type="submit"
                style={{ width: "100%" }}
                className="btn btn-primary"
              >
                {submitBtnName}
              </button>
              <br />
            </form>
          </div>
        </div>
        <div className="top">
          <ul id="message-box"></ul>
        </div>
        <form id="intent-test-form" className="bottom">
          <input
            id="test-input"
            className="form-control message"
            placeholder="Write your test message"
            required
          />
          <button
            id="intent-test-btn"
            className="btn btn-sm theme-btn btn-outline-warning"
          >
            Test
          </button>
        </form>
      </div>
      <br />
      <div className="intent-test-result" style={{ "text-align": "center" }}>
        <font>Intent is </font>
        <div id="success-msg"></div>
      </div>
      <small
        id="test-err-msg"
        style={{ color: "red", display: "none" }}
      ></small>
      <small
        className="result"
        id="response-intent"
        style={{ color: "green" }}
      ></small>
      <br />
      <small>
        <b>Note- </b>Buttons can not be triggered here since this is test mode.
      </small>
    </div>
  );
}

export default Section3;
