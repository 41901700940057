import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import axios from "axios";
import Variables from "../variables";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./CheckoutForm";

function AddPaymentMethod({ show, handleClose, setMethods }) {
  const [options, setOptions] = useState({});
  const stripePromise = loadStripe('pk_test_51NuEF3SDepLvDXOThwT3OMI1CcmNNChBJOFQi2iXaifOBDi9ih4iV6QUZwALkE22ZNjAPMpCIvPC4bHkG6reTgEj00gFzNgrF4');

  useState(() => {
    let req_data = Variables.create_req_data({
      action: "add",
    });
    axios.post(Variables.serverUrl("payment-method/"), req_data).then((res) => {
      if (res.status == 200) {
        loadStripe(res.data.publicKey);
        setOptions({
          clientSecret: res.data.intent,
        });
      }
    });
  }, []);
  return (
    <>
      <Modal
        id="add-payment-method"
        show={show}
        onHide={handleClose}
        backdrop="static"
        fullscreen={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add payment method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm/>
          </Elements>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddPaymentMethod;
