import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "../css/NewBot.css";
import Variables from "../variables";
import $ from "jquery";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router";

function NewForm({ show, handleClose }) {
  const navigate = useNavigate();
  function create_new_form() {
    if ($("#form-title").val() != "") {
      $("#new-form").submit();
    } else {
      $("#form-title").css({
        border: ".5px solid red",
      });
    }
  }
  useEffect(() => {
    $("#new-form").on("submit", function (e) {
      e.preventDefault();
      Variables.loading_btn("#create-new-form-btn", "Creating");
      let req_data = Variables.create_req_data({
        action: "add",
        title: $("#form-title").val(),
      });
      axios.post(Variables.serverUrl("form-action/"), req_data).then((res) => {
        console.log('server reply is ',res)
        navigate("/app/form-builder?id=" + res.data.id);
      });
    });
  });
  return (
    <>
      <Modal
        id="create-new-form"
        show={show}
        onHide={handleClose}
        backdrop="static"
        fullscreen="md-down"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create a new form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="new-form">
            <label>Name your form</label>
            <input
              id="form-title"
              className="form-control"
              maxLength={20}
              required
            />
            <small style={{ color: "grey" }}>Max. length is 20 letters</small>
            <br />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            id="create-new-form-btn"
            className="theme-btn"
            variant="primary"
            onClick={create_new_form}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NewForm;
