import axios from "axios";
import { useEffect, useLayoutEffect, useState } from "react";
import Variables from "./variables";
import $ from "jquery";
import Header from "./Header";
import Leftnav from "./Leftnav";
import Banner from "./banner";
import "./css/GroupsMembers.css";
import groupIcon from "./images/group.png";
import deleteIcon from "./images/delete-light.svg";
import Group from "./offcanvases/group";
import DeleteGroup from "./modals/DeleteGroup";
import CreateGroup from "./modals/CreateGroup";

let is_member = localStorage.getItem("sharurai_member_login");

export default function Groups() {
  const [groupID, setGroupID] = useState("");
  const [groupData, setGroupData] = useState({ members: [], bots: [] });

  const [groupsList, setGroupsList] = useState([]);
  //Group canvas
  const [groupOffCanvasShow, setGroupOffCanvasShow] = useState(false);
  const handleGroupOffCanvasClose = () => setGroupOffCanvasShow(false);
  const handleGroupOffCanvasShow = async (ele) => {
    let uid = $(ele.target).attr("uid");
    setGroupID(uid);
    let req_data = Variables.create_req_data({
      action: "get-one",
      uid: uid,
    });
    await axios.post(Variables.serverUrl("groups/"), req_data).then((res) => {
      if (res.status == 200) {
        let data = JSON.parse(res.data.group)[0].fields;
        data["members"] = res.data.members;
        data["bots"] = res.data.bots;
        data["emails"]=res.data.emails
        setGroupData(data);
      }
    });
    setGroupOffCanvasShow(true);
  };

  //delete handler
  const [deleteShow, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = (ele) => {
    setGroupID($(ele.target).attr("uid"));
    setDeleteShow(true);
  };

  //create handler
  const [createShow, setCreateShow] = useState(false);
  const handleCreateClose = () => setCreateShow(false);
  const handleCreateShow = () => setCreateShow(true);

  useLayoutEffect(() => {
    async function checkAuth() {
      let status = await Variables.checkAuthentication();
    }
    checkAuth.call();
    let req_data = Variables.create_req_data({ action: "get-all" });
    axios.post(Variables.serverUrl("groups/"), req_data).then((res) => {
      let groups = res.data.groups;
      if (res.status > 199 || res.status < 300) {
        setGroupsList(groups);
      }
    });
  }, []);
  useEffect(() => {
    const leftNavLinks = document.getElementsByClassName("left-nav-link");
    for (let i = 0; i < leftNavLinks.length; i++) {
      leftNavLinks[i].classList.remove("active");
    }
    document.getElementsByClassName("groups-link")[0].classList.add("active");
  }, []);
  return (
    <>
      <div className="App">
        <Leftnav />
        <div className="App-body">
          <header className="sticky-top">
            <Header />
            <Banner />
          </header>
          <div className="home-container sticky-top">
            <div
              id="groups-cards"
              className="container"
              style={{ padding: "15px" }}
            >
              <h4>Groups ({groupsList.length})</h4>
              <br />
              {is_member == "true" ? (
                <></>
              ) : (
                <>
                  <div id="create-group-btn">
                    <button
                      className="btn btn-dark btn-sm"
                      onClick={handleCreateShow}
                    >
                      + Create group
                    </button>
                  </div>
                  <br />
                </>
              )}
              <div className="row">
              {is_member == "true" ? (
                <div style={{display:"flex",justifyContent:"center"}}>
                  <i>You have access to the following groups.</i>
                  <br/><br/>
                </div>
              ) : (<></>)}
                {groupsList.map((item, index) => (
                  <div className="col-md-4 col-sm-6 col">
                    <div className="container">
                      <div className="group-card card">
                        <div className="card-body">
                          <h6>
                            <img src={groupIcon} height={20} />
                            &nbsp;
                            <a
                              href="#"
                              uid={item.uid}
                              onClick={handleGroupOffCanvasShow}
                            >
                              {item.name}
                            </a>
                          </h6>
                          <p style={{ fontSize: ".8rem" }}>
                            <b>
                              Created on -{" "}
                              {new Date(item.date_created).toLocaleDateString(
                                "en-US",
                                {
                                  month: "short",
                                  day: "numeric",
                                  year: "numeric",
                                }
                              )}
                            </b>
                          </p>
                          <small style={{ color: "grey", fontSize: ".8rem" }}>
                            {item.info}
                          </small>
                          <br />
                          {is_member == "true" ? (
                            <></>
                          ) : (
                            <>
                              <br />
                              <button
                                className="btn btn-sm btn-danger flex-horizon"
                                style={{
                                  width: "100%",
                                  justifyContent: "center",
                                }}
                                onClick={handleDeleteShow}
                                uid={item.uid}
                              >
                                <img src={deleteIcon} width={18} />
                                Delete
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Group
        show={groupOffCanvasShow}
        handleClose={handleGroupOffCanvasClose}
        id={groupID}
        data={groupData}
      />
      <DeleteGroup
        show={deleteShow}
        handleClose={handleDeleteClose}
        groups={groupsList}
        setGroups={setGroupsList}
        uid={groupID}
      />
      <CreateGroup
        show={createShow}
        handleClose={handleCreateClose}
        setGroupsList={setGroupsList}
      />
    </>
  );
}
