import { useEffect, useState } from "react";
import copy from "../images/copy.svg";
import mailchimp from "../images/mailchimp.png";
import emailIcon from "../images/gmail.svg";
import $ from "jquery";
import Variables from "../variables";
import axios from "axios";
import { Link } from "react-router-dom";

function Section2({ data }) {
  function copyToClipboard() {
    var copyText = document.getElementById("api-key-code");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    $("#copied-message").show().delay(3000).hide(1);
  }
  function change_integration_state(e) {
    let ele = e.target;
    let action = $(ele).attr("action");
    let int_type = $(e.target).attr("int-type");
    Variables.loading_btn(e.target, action);
    if (action == "revoke") {
      let req_data = Variables.create_req_data({});
      axios
        .post(Variables.serverUrl(`revoke/${int_type}/`), req_data)
        .then((res) => {
          if (res.status == 200) {
            window.location.reload();
          } else {
            Variables.unloading_btn(e.target);
          }
        });
    } else if (action == "connect") {
      let req_data = Variables.create_req_data({});
      axios
        .post(Variables.serverUrl(`authorize-${int_type}/`), req_data)
        .then((res) => {
          window.open(res.data.url, "Google", "width=500,height=500").focus();
        });
    }
  }

  useEffect(() => {
    $("#regenerate-key").on("click", function () {
      Variables.loading_btn(this, "Saving");
      let req_data = Variables.create_req_data({});
      axios
        .post(Variables.serverUrl("regenerate-api-key/"), req_data)
        .then((res) => {
          if (res.data.status == "success") {
            $("#api-key-code").val(res.data.key);
          }
          Variables.unloading_btn(this);
        });
    });
    $("#save-api-key").on("click", function () {
      Variables.loading_btn(this, "Saving");
      let val = $("#openAI-api-key-code").val();
      let req_data = Variables.create_req_data({
        action: "openai",
        openai_key: val,
      });
      axios
        .post(Variables.serverUrl("update-profile/"), req_data)
        .then((res) => {
          if (res.data.status == "success") {
            $("#openAI-api-key-code").css({
              border: "1px solid green",
            });
            $("#openai-message").show();
            $("#openai-message").html("Key saved successfully.");
            $("remove-api-key").removeClass("disabled");
          } else {
            $("#openai-message").html(res.data.msg);
            $("#openAI-api-key-code").css({
              border: "1px solid red",
            });
          }
          Variables.unloading_btn(this);
        });
    });
  }, []);
  function removeOpenaiKey(e) {
    Variables.loading_btn(e.target, "Removing");
    let req_data = Variables.create_req_data({
      action: "remove-openai-key",
    });
    axios.post(Variables.serverUrl("update-profile/"), req_data).then((res) => {
      if (res.data.status == "success") {
        $("#openAI-api-key-code").css({
          border: "1px solid green",
        });
        $("#openai-message").show();
        $("#openai-message").html("Removed the key successfully.");
        $("#openAI-api-key-code").val("");
        $(e.target).addClass("disabled");
      } else {
        $("#openai-message").html(
          "Could not remove the key. Please try again."
        );
        $("#openAI-api-key-code").css({
          border: "1px solid red",
        });
      }
      Variables.unloading_btn(e.target);
    });
  }
  return (
    <div className="col-md-12 col-sm-12 col-lg-12">
      <div className="row">
        <div className="col-md-6 col-lg-6 col-sm-12">
          <div className="title">
            <h4>API key</h4>
          </div>
          <div id="api-key">
            <div>
              <input
                id="api-key-code"
                style={{ width: "350px" }}
                value={data.api_key}
                title="Copy"
                disabled
              />
              <img
                className="pointer"
                onClick={copyToClipboard}
                height={18}
                src={copy}
              />
            </div>
            <small id="copied-message">Copied to clipboard</small>
            <br />
            <button id="regenerate-key" className="btn btn-sm theme-btn">
              Regenerate API key
            </button>
          </div>
          <br />
          <div className="title">
            <h4>OpenAI API key</h4>
          </div>
          <div id="openAi-api-key">
            <div>
              <input
                className="form-control"
                id="openAI-api-key-code"
                type="text"
                style={{ width: "350px" }}
                placeholder="Your OpenAI API key"
                defaultValue={data.openai_key}
              />
            </div>
            <small id="openai-message"></small>
            <br />
            <button id="save-api-key" className="btn btn-sm theme-btn">
              Save
            </button>
            &nbsp;
            {data.openai_key ? (
              <>
                <button
                  id="remove-api-key"
                  className="btn btn-sm btn-danger"
                  onClick={removeOpenaiKey}
                >
                  Remove
                </button>
              </>
            ) : (
              <>
                <button
                  id="remove-api-key"
                  className="btn btn-sm btn-danger disabled"
                  onClick={removeOpenaiKey}
                >
                  Remove
                </button>
              </>
            )}
          </div>
          <br />
        </div>
        <div className="col-md-6 col-lg-6 col-sm-12">
          <div className="title">
            <h4>Integrations</h4>
          </div>
          <div id="integrations">
            <div className="integration card">
              <div class="mailchimp">
                <div className="container flex-horizon">
                  <img style={{ height: "40px" }} src={mailchimp} />
                  <b>Mailchimp &nbsp;</b>
                  <button
                    onClick={change_integration_state}
                    int-type="mailchimp"
                    action="connect"
                    className="btn btn-dark btn-sm btn-round"
                  >
                    Connect
                  </button>
                </div>
                <br />
                <p style={{ fontSize: ".7rem", lineHeight: "10px" }}>
                  Mailchimp integration lets Xbot add leads into your
                  Mailchimp's contacts or groups automatically as they arrive.
                </p>
              </div>
            </div>
            <div className="integration card">
              <div class="notion">
                <div className="container flex-horizon">
                  <img style={{ height: "40px" }} src={emailIcon} />
                  <b> &nbsp;Gmail &nbsp;</b>
                  <Link
                    to="/app/emails"
                    int-type="notion"
                    action="connect"
                    className="btn btn-dark btn-sm btn-round"
                  >
                    Connect
                  </Link>
                </div>
                <br />
                <p style={{ fontSize: ".7rem", lineHeight: "10px" }}>
                  Integration with Gamil lets you interact with your customers
                  using the power of AI.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}

export default Section2;
