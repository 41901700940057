import React, { useLayoutEffect } from "react";
import BotTraining from "../BotTraining";
import "../css/Home.css";
import Header from "../Header";
import Leftnav from "../Leftnav";
import Variables from "../variables";

function Bot_training() {
  useLayoutEffect(() => {
    async function checkAuth() {
      let status = await Variables.checkAuthentication()
      console.log('Authentication message is ', status)
    }
    checkAuth.call()
  })
  return (
    <>
      <div className="App">
        <Leftnav />
        <div className="App-body">
          <header className="sticky-top">
            <Header />
          </header>
          <div className="home-container sticky-top">
            <BotTraining />
          </div>
        </div>
      </div>
    </>
  );
}

export default Bot_training;
