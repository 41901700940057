import { Link, useNavigate } from "react-router-dom";
import "./css/Verify.css";
import userIcon from "./images/user.svg";
import { useEffect } from "react";
import Variables from "./variables";
import axios from "axios";
import $ from "jquery";
import logo from "./images/sharurai-logo-2.png";

let loginStatus = false;

const fn = {
  RequestLogin: async function (data) {
    let req_data = {
      email: data.email,
      password: data.password,
      organisation: data.organisation
    };
    await axios.post(Variables.serverUrl("login/member/"), req_data).then(
      (res) => {
        try {
          localStorage.setItem("sharurai_access_key", res.data.access);
          localStorage.setItem("sharurai_refresh_key", res.data.refresh);
          localStorage.setItem("sharurai_member_login", true);
          loginStatus = true;
        } catch (e) {
          Variables.unloading_btn("#login-btn");
          $("#msg").html("Something went wrong. Please try again.");
          $("#msg").show();
        }
      },(err) => {
        if (err.response.status) {
            $("#msg").html(err.response.data.msg);
            $("#msg").show();
        }
      }
    );
    return loginStatus;
  },

  AttemptLogin: async function (data) {
    if (data.type == "google") {
      //pass
    } else if (data.type == "email") {
      let email = $("#email").val();
      let password = $("#password").val();
      let organisation=$("#organisation").val();
      let res = await fn.RequestLogin({ email: email, password: password, organisation:organisation });
      return res;
    }
  },
};

function MemberLogin() {
  let navigate = new useNavigate();
  let token = "";
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const extracted_id = params.get("token");
  token = extracted_id;
  const handleMemberLogin = (e) => {
    e.preventDefault();
    Variables.loading_btn("#member-login-btn", "Logging in");
    (async () => {
      let res = await fn.AttemptLogin({ type: "email" });
      if (res) {
        window.location.href="/app/bots"
      }else{
        Variables.unloading_btn("#member-login-btn");
      }
    })();
  };
  useEffect(() => {
    if (localStorage.getItem("sharurai_access_key") != null) {
      navigate("/app/bots");
    }
  }, []);
  return (
    <>
      <div id="reset-password">
        <div className="container">
          <img src={logo} width={120} />
          <br />
          <br />
          <div className="card">
            <div className="card-body" style={{ textAlign: "center" }}>
              <img src={userIcon} width={80} />
              <br />
              <br />
              <form
                onSubmit={handleMemberLogin}
                id="member-login-form"
                style={{ textAlign: "center", margin: "auto", width: "300px" }}
              >
                <h4>Member Login</h4>
                <p className="form-text">Login to your organisation.</p>
                <small
                  id="msg"
                  style={{ color: "#c32f2f", display: "none" }}
                ></small>
                <input
                  id="organisation"
                  type="text"
                  placeholder="Organisation name"
                  className="form-control"
                  required
                />
                <br />
                <input
                  id="email"
                  type="email"
                  placeholder="Your email"
                  className="form-control"
                  required
                />
                <br />
                <input
                  id="password"
                  type="password"
                  placeholder="Password"
                  className="form-control"
                  required
                />
                <br />
                <button
                  id="member-login-btn"
                  form="member-login-form"
                  type="submit"
                  className="submit-btn btn btn-dark round"
                >
                  Login &#x2192;
                </button>
                <br />
                <br />
              </form>
              <Link style={{ color: "#000", fontWeight: "bold" }} to="/app/login/member">
                &#x2190; Back
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MemberLogin;
