import React, { useEffect, useState } from "react";
import worldMap from "../images/worldmap.png";
import star from "../images/star.svg";
import Variables from "../variables";
import axios from "axios";

function HomeSection3() {
  let botID = "";
  const [languages, setLanguages] = useState("");
  const [countries, setCountries] = useState("");
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const extracted_id = params.get("id");
  botID = extracted_id;
  useEffect(() => {
    (async () => {
      let req_data = Variables.create_req_data({
        bot_id: botID,
      });
      await axios
        .post(Variables.serverUrl("demographics-language/"), req_data)
        .then(async (res) => {
          setLanguages(res.data.languages);
          setCountries(res.data.countries);
        });
    })();
  }, []);
  return (
    <div id="demographics" className="home-section homesection3">
      <div className="container row">
        <div className="card col-sm-12 col-md-6 col-lg-6">
          <div className="card-title">
            <h5>Preferred language</h5>
            <div className="analytics">
              <div className="scrollable">
                <br/>
                {Object.keys(languages).length ? (
                  <>
                    <table class="table">
                      <tbody>
                        {Object.keys(languages).map((obj) => {
                          return (
                            <tr>
                              <th scope="row">
                                {Variables.toLanguageName(obj)}
                              </th>
                              <td>{languages[obj]}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <div style={{ color: "grey" }}>
                    <h6>No data</h6>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card col-sm-12 col-md-6 col-lg-6">
          <div className="card-title">
            <h5>Demographics</h5>
            <div className="analytics">
              <img src={worldMap} width={200} style={{ margin: "auto" }} />
              <div className="scrollable">
                <br/>
                {Object.keys(countries).length ? (
                  <>
                    <table class="table">
                      <tbody>
                        {Object.keys(countries).map((obj) => {
                          return (
                            <tr>
                              <th scope="row">
                                {Variables.toCountryName(obj)}
                              </th>
                              <td>{countries[obj]}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <div style={{ color: "grey" }}>
                    <h6>No data</h6>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeSection3;
