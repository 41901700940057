import { useEffect, useLayoutEffect, useState } from "react";
import $ from "jquery";
import Variables from "./variables";
import Banner from "./banner";
import HomeMenu from "./home/HomeMenu";
import Leftnav from "./Leftnav";
import Header from "./Header";
import axios from "axios";
import jsIcon from "./images/js.svg";
import embedIcon from "./images/embed.svg";
import wordpressIcon from "./images/wordpress.svg";
import shopifyIcon from "./images/shopify.svg";
import whatsappIcon from "./images/whatsapp.svg";
import messangerIcon from "./images/messanger.svg";

function TestBot() {
  let botID = "";
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const extracted_id = params.get("id");
  const [profileID, setProfileID] = useState("");
  botID = extracted_id;
  useEffect(() => {
    (async () => {
      await axios
        .post(
          Variables.serverUrl("get-profile/"),
          Variables.create_req_data({})
        )
        .then((res) => {
          if (res.status) {
            setProfileID(res.data.profile_id);
          }
        });
    })();
  }, []);
  useEffect(() => {
    if (profileID) {
      $("#bot-iframe").append(`<iframe id="sharurai-xbot-embedding" src="https://cdn.sharurai.com/chatbot/chatbo-embed.html?bot_id=${botID}&profile_id=${profileID}" bot-id="${botID}" width="100%" height="500px"></iframe>`)
    }
  }, [profileID]);
  useLayoutEffect(() => {
    async function checkAuth() {
      let status = await Variables.checkAuthentication();
    }
    checkAuth.call();
    const links = document.getElementsByClassName("menu-link");
    for (let i = 0; i < links.length; i++) {
      links[i].classList.remove("active");
    }
    $(".test-bot-link").addClass("active");
  }, []);
  return (
    <>
      <div className="App">
        <Leftnav />
        <div className="App-body">
          <header className="sticky-top">
            <Header />
            <Banner />
          </header>
          <div className="home-container sticky-top">
            <HomeMenu />
            <br />
            <div id="test-bot" className="row" style={{ padding: "15px" }}>
              <h3 className="">Test your bot</h3>
              <div id="bot-iframe"></div>
            </div>
            <br />
            <div id="test-bot" class="row" style={{ padding: "15px" }}>
              <h3 class="">Deploy</h3>
              <div id="bot-iframe"></div>
            </div>
            <br />
            <div
              className="row"
              style={{ justifyContent: "start", padding: "15px" }}
            >
              <div
                id="iframe-script"
                className="script-tag col-sm-6 col-md-6 col-lg-6"
                style={{ maxWidth: "400px", marginRight: "2rem" }}
              >
                <h5
                  className="flex-horizon"
                  style={{ alignItems: "center", width: "fit-content" }}
                >
                  <img src={embedIcon} height={30} /> &nbsp; Iframe script
                </h5>
                <br />
                <small>
                  {'<iframe id="sharurai-xbot-embedding" src="https://cdn.sharurai.com/chatbot/chatbo-embed.html?bot_id=' +
                    botID +
                    '&profile_id=' +
                    profileID +
                    '" width="100%" height="500px"></iframe>'}
                </small>
                <br />
              </div>
              <div
                id="iframe-script"
                className="script-tag col-sm-6 col-md-6 col-lg-6"
                style={{ maxWidth: "400px" }}
              >
                <h5
                  className="flex-horizon"
                  style={{ alignItems: "center", width: "fit-content" }}
                >
                  <img src={jsIcon} height={30} /> &nbsp; JavaScript
                </h5>
                <br />
                <small>
                  {`<script src="https://cdn.sharurai.com/chatbot/xbot.js" bot-id="${botID}" profile-id="${profileID}"></script>`}
                </small>
                <br />
                <br />
              </div>
            </div>

            <br />
            <div className="row">
              <div
                className="col-sm-6 col-md-4 col-lg-4 card"
                style={{
                  padding: "1.5rem",
                  textAlign: "center",
                  margin: "1rem auto",
                }}
              >
                <div>
                  <h5 style={{ fontWeight: "bold" }}>Wordpress</h5>
                </div>
                <div className="card-body">
                  <img src={wordpressIcon} height={70} />
                </div>
                <button className="btn btn-sm btn-primary round-btn theme-btn" onClick={()=>window.location.href="https://cdn.sharurai.com/sharurai-wp.zip"}>
                  Download
                </button>
              </div>
              <div
                className="col-sm-6 col-md-4 col-lg-4 card"
                style={{
                  padding: "1.5rem",
                  textAlign: "center",
                  margin: "1rem auto",
                }}
              >
                <div>
                  <h5 style={{ fontWeight: "bold" }}>Shopify</h5>
                </div>
                <div className="card-body">
                  <img src={shopifyIcon} height={70} />
                </div>
                <p>Coming soon...</p>
              </div>
              <div
                className="col-sm-6 col-md-4 col-lg-4 card"
                style={{
                  padding: "1.5rem",
                  textAlign: "center",
                  margin: "1rem auto",
                }}
              >
                <div>
                  <h5 style={{ fontWeight: "bold" }}>Whatsapp</h5>
                </div>
                <div className="card-body">
                  <img src={whatsappIcon} height={70} />
                </div>
                <p>(Complete solution) Coming soon...</p>
              </div>
              <div
                className="col-sm-6 col-md-4 col-lg-4 card"
                style={{
                  padding: "1.5rem",
                  textAlign: "center",
                  margin: "1rem auto",
                }}
              >
                <div>
                  <h5 style={{ fontWeight: "bold" }}>Messenger</h5>
                </div>
                <div className="card-body">
                  <img src={messangerIcon} height={70} />
                </div>
                <p>Coming soon...</p>
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default TestBot;
