import { useEffect } from "react";
import cross from "./images/red-cross.png"
import { Link } from "react-router-dom";
import Variables from "./variables";

function PaymentFailed(){
    useEffect(()=>{
        async function checkAuth() {
            let status = await Variables.checkAuthentication();
            console.log("Authentication message is ", status);
          }
          checkAuth.call();
    },[])
    return <>
    <div id="failed-payment" className="payment-status">
        <div className="container">
            <img style={{height:"80px",marginBottom:'10px'}} src={cross}/>
            <h4>Payment Failed</h4>
            <Link to="/plans" className="btn round btn-dark">&#x2190; Go back</Link>
        </div>
    </div>
    </>
}

export default PaymentFailed;