import { useEffect, useLayoutEffect, useState } from "react";
import Header from "./Header";
import Leftnav from "./Leftnav";
import Banner from "./banner";
import Variables from "./variables";
import { Link } from "react-router-dom";
import KnowledgeBaseIcon from "./images/knowledge_base.svg";
import options from "./images/options.svg";
import $ from "jquery";
import axios from "axios";
import NewKB from "./modals/NewKB";
import DeleteKB from "./modals/DeleteKB";

function KnowledgeBase() {
  const [kB, setKB] = useState([])
  const [showNewKB, setShowNewKB] = useState(false);
  const handleNewKBClose = () => setShowNewKB(false);
  const handleNewKBShow = () => setShowNewKB(true);
  const [showDeleteKB, setShowDeleteKB] = useState(false);
  const handleDeleteKBClose = () => setShowDeleteKB(false);
  const [deleteKBele, setDeleteKBele]=useState('')
  const handleDeleteKBShow = (e) => {
    setDeleteKBele(e.target)
    setShowDeleteKB(true)
  }
  const findByKeyword = (e) => {
    const eles = $(".form .content .title");
    $(eles).parents("li").hide();
    eles.each((i, obj) => {
      if (
        obj.innerHTML.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
      ) {
        $(obj).parents("li").show();
      }
    });
  };
  useLayoutEffect(() => {
    async function checkAuth() {
      let status = await Variables.checkAuthentication();
      let req_data = Variables.create_req_data({
        action: "get",
      });
      await axios
        .post(Variables.serverUrl("knowledge-base/"), req_data)
        .then(async (res) => {
          setKB(res.data.data);
        });
    }
    checkAuth.call();
  }, []);
  useEffect(() => {
    const links = document.getElementsByClassName("left-nav-link");
    for (let i = 0; i < links.length; i++) {
      links[i].classList.remove("active");
    }
    document
      .getElementsByClassName("knowledge-base-link")[0]
      .classList.add("active");
  });
  return (
    <>
      <div className="App">
        <Leftnav />
        <div className="App-body">
          <header className="sticky-top">
            <Header />
          </header>
          <Banner />
          <div id="knowledge-base">
            <div className="forms">
              <br />
              <div className="top">
                <div className="title">
                  <h4 className="title-color">Knowledge Bases ({kB.length})</h4>
                </div>
                <button className="btn btn-dark btn-sm" onClick={handleNewKBShow}>+ Create new</button>
                <NewKB show={showNewKB} handleClose={handleNewKBClose}  />
                <input
                  style={{ "max-width": "240px" }}
                  onInput={findByKeyword}
                  className="form-control"
                  type="text"
                  placeholder="search"
                />
              </div>
              <div className="middle">
                <ul className="all-forms">
                  {(() => {
                    return (
                      kB.map((ele) => {
                        return (
                          <li id={ele.uid} className="form">
                            <Link
                              to={"/app/knowledge-base?id=" + ele.uid}
                              className="content limit-text"
                            >
                              <img src={KnowledgeBaseIcon} />
                              <font className="title">{ele.name}</font>
                            </Link>
                            <div className="options dropdown">
                              <img data-bs-toggle="dropdown" src={options} />
                              <ul className="dropdown-menu">
                                <li>
                                  <a onClick={handleDeleteKBShow} uid={ele.uid} className="dropdown-item" href="#">
                                    Delete
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </li>
                        );
                      })
                    )
                  })()}
                  <DeleteKB show={showDeleteKB} handleClose={handleDeleteKBClose} ele={deleteKBele} />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default KnowledgeBase;
