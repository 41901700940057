import mailIc from "../images/email-dark.png";
import SelectBots from "../modals/SelectBots";
import SelectMembers from "../modals/SelectMembers";
import Variables from "../variables";

function Email({ show, handleClose, selectedMail }) {
  return (
    <>
      <div
        className={`offcanvas offcanvas-end ${show ? "show" : ""}`}
        tabIndex="1"
        style={{ width: "90%" }}
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title">
            <div className="btn btn-light" onClick={handleClose}>
              <b title="back" type="button">
                &#x2190;&nbsp;&nbsp;
              </b>
              Back
            </div>
            &nbsp;&nbsp;
          </h5>
        </div>
        <div className="offcanvas-body row" style={{ paddingTop: "5px" }}>
          <div className="col-md-5 col-sm-12">
            <b>Date composed</b>
            <br />
            <small className="form-text">
              {Variables.formatDate(selectedMail.date_created)}
            </small>
            <br />
            <br />
            <b>From</b>
            <br />
            <small className="form-text">{selectedMail.email}</small>
            <br />
            <br />
            <b>To</b>
            <br />
            <small className="form-text">
              {selectedMail.other_info?JSON.parse(selectedMail.other_info).to:<></>}
            </small>
            <br />
            <br />
            <b>Subject</b>
            <br />
            <small className="form-text">{selectedMail.subject}</small>
            <br />
            <br />
            <b>Composed by</b>
            <br />
            <small className="form-text">
              {selectedMail.other_info?JSON.parse(selectedMail.other_info).by:<></>}
            </small>
            <br />
            <br />
          </div>
          <div className="col-md-7 col-sm-12" style={{wordWrap:"break-word"}}>
            {" "}
            <b>Body</b>
            <br />
            <br />
            <div dangerouslySetInnerHTML={{ __html: selectedMail.body }}></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Email;
