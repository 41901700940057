import axios from "axios";
import { useEffect, useLayoutEffect, useState } from "react";
import Variables from "./variables";
import $ from "jquery";
import Header from "./Header";
import Leftnav from "./Leftnav";
import Banner from "./banner";
import "./css/GroupsMembers.css";
import deleteIcon from "./images/delete-light.svg";
import userIcon from "./images/user.svg";
import EditMember from "./modals/EditMember";
import DeleteMember from "./modals/DeleteMember";
import CreateMember from "./modals/CreateMember";

export default function Members() {
  const [membersList, setMembersList] = useState([]);
  const [memberUid,setMemberUid]=useState()

  //Edit handler
  const [editShow, setEditShow] = useState(false);
  const handleEditClose = () => {
    setEditShow(false)
  }
  const handleEditShow = (e) => {
    setEditShow(true);
    setMemberUid($(e.target).attr("uid"))
  }

  //create handler
  const [deleteShow, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = (e) => {
    setDeleteShow(true)
    setMemberUid($(e.target).attr("uid"))
  }

  //create handler
  const [createShow, setCreateShow] = useState(false);
  const handleCreateClose = () => setCreateShow(false);
  const handleCreateShow = () => setCreateShow(true);

  useLayoutEffect(() => {
    async function checkAuth() {
      let status = await Variables.checkAuthentication();
    }
    checkAuth.call();
  }, []);
  useEffect(() => {
    const leftNavLinks = document.getElementsByClassName("left-nav-link");
    for (let i = 0; i < leftNavLinks.length; i++) {
      leftNavLinks[i].classList.remove("active");
    }
    document.getElementsByClassName("members-link")[0].classList.add("active");
    let req_data=Variables.create_req_data({action:"get-all"})
    axios.post(Variables.serverUrl("members/"),req_data).then((res)=>{
      let members=res.data.members
      members.forEach(ele => {
        setMembersList((prevList) => [...prevList, {
          uid:ele.uid,
          name:ele.name,
          email:ele.email,
          created:ele.date_created
        }])
      });
    })
  }, []);
  return (
    <>
      <div className="App">
        <Leftnav />
        <div className="App-body">
          <header className="sticky-top">
            <Header />
            <Banner />
          </header>
          <div className="home-container sticky-top">
            <div
              id="groups-cards"
              className="container"
              style={{ padding: "15px" }}
            >
              <h4>Members ({membersList.length})</h4>
              <br />
              <div id="create-member-btn">
                <button
                  className="btn btn-dark btn-sm"
                  onClick={handleCreateShow}
                >
                  + Add member
                </button>
              </div>
              <br />
              <div className="row">
                {membersList.map((item, index) => (
                  <div className="col-md-4 col-sm-6 col">
                    <div className="container">
                      <div className="group-card card">
                        <div className="card-body">
                          <h6>
                            <img src={userIcon} height={20} />
                            &nbsp;
                            <b>Member</b>
                          </h6>
                          <br />
                          <p style={{ fontSize: ".8rem" }}>
                            <b className="limit-text">Name - {item.name}</b>
                          </p>
                          <p style={{ fontSize: ".8rem" }}>
                            <b className="limit-text">
                              Email - {item.email}
                            </b>
                          </p>
                          <p style={{ fontSize: ".8rem" }}>
                            <b>Added on - {new Date(item.created).toLocaleDateString('en-US',{ month: 'short', day: 'numeric', year: 'numeric' })}</b>
                          </p>
                          <br />
                          <button
                            className="btn btn-sm btn-dark theme-btn flex-horizon"
                            style={{ width: "100%", justifyContent: "center" }}
                            onClick={handleEditShow}
                            uid={item.uid}
                          >
                            Change Password
                          </button>
                          <br />
                          <button
                            className="btn btn-sm btn-danger flex-horizon"
                            style={{ width: "100%", justifyContent: "center" }}
                            onClick={handleDeleteShow}
                            uid={item.uid}
                          >
                            <img src={deleteIcon} width={18} />
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditMember show={editShow} handleClose={handleEditClose} uid={memberUid} />
      <DeleteMember show={deleteShow} handleClose={handleDeleteClose} members={membersList} setMembers={setMembersList} uid={memberUid} />
      <CreateMember
        show={createShow}
        handleClose={handleCreateClose}
        membersList={membersList}
        setMembersList={setMembersList}
      />
    </>
  );
}
