import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "../css/NewBot.css";
import Variables from "../variables";
import axios from "axios";

function CancelSubscription({ show, handleClose }) {
    const handleCancellation=(e)=>{
        let req_data=Variables.create_req_data({action:"cancel"})
        Variables.loading_btn(e.target,"Cancelling")
        axios.post(Variables.serverUrl("payment-method/"),req_data).then((res)=>{
            if(res.status){
                window.location.reload();
            }
        }).catch((err)=>{
            Variables.unloading_btn(e.target)
        })
    }
  return (
    <>
      <Modal
        id="change-ticket-assignment"
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Cancel subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body className="form-text" style={{margin:"0"}}>
          <b>🔔 Attention: Please Read Before Canceling 🔔</b> <br/><br/>Before you proceed, we
          want to ensure you're informed: <br/><br/>💰 No Refunds After Cancellation:
          We're unable to provide refunds once your subscription is canceled. <br/>🔄
          Existing Subscription: If you have an active subscription, you can
          continue using our services until it ends. <br/>⚠️ Account Deletion Notice:
          After cancellation, your account will be active for 7 days. After this
          period, your account and all associated data will be permanently
          deleted. Please consider these factors before making any changes to
          your account.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
          <Button id="cancel-btn" className="btn-danger" onClick={handleCancellation}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CancelSubscription;
