import EditBtn from "../modals/EditBtn";
import { useEffect, useState } from "react";
import $ from "jquery";
import Variables from "../variables";
import axios from "axios";
import cameraIcon from "../images/camera.png";
import ticketIcon from "../images/ticket.png";

function Section2({
  all_forms,
  requestedJsonData,
  raiseTicketState,
  actionData,
  groups,
}) {
  const [btnEle, setBtnEle] = useState("");
  const [showEditBtn, setEditBtnShow] = useState(false);
  const handleEditBtnClose = () => setEditBtnShow(false);
  const handleEditBtnShow = function (e) {
    setBtnEle(e.target);
    setEditBtnShow(true);
  };
  const [endpoint_txt, setendpoint_txt] = useState("");
  const [endpoint_method_txt, setendpoint_method_txt] = useState("");
  const [editingElement, setEditingElement] = useState("");
  const [jsonFields, setJsonFields] = useState(requestedJsonData);
  const [raiseTicket, setRaiseTicket] = useState(false);
  const [ticketSubject, setTicketSubject] = useState("");
  const [ticketBody, setTicketBody] = useState("");
  const [ticketDueDate, setTicketDueDate] = useState("");
  const [ticketAssignedToId, setTicketAssignedToId] = useState("");
  const [ticketType,setTicketType]=useState("")
  const [ticketPriority, setTicketPriority]=useState("")

  useEffect(() => {
    setJsonFields(requestedJsonData);
  }, [requestedJsonData]);
  useEffect(() => {
    setRaiseTicket(raiseTicketState);
  }, [raiseTicketState]);
  useEffect(() => {
    let action = actionData;
    if (action.ticket != undefined) {
      setTicketSubject(action.ticket.subject);
      setTicketBody(action.ticket.body);
      setTicketDueDate(action.ticket.dueDate);
      setTicketAssignedToId(action.ticket.assigned_to_id);
      setTicketType(action.ticket.type)
      setTicketPriority(action.ticket.priority)
    }
  }, [actionData]);

  const addJsonField = () => {
    setJsonFields((prevData) => [...prevData, { key: "", val: "" }]);
  };

  const removeJsonField = (e) => {
    let i = Number($(e.target).attr("index"));
    setJsonFields((prevData) => {
      const updatedData = [...prevData];
      updatedData.splice(i, 1);
      return updatedData;
    });
  };

  const handleJsonFieldInputKey = (e) => {
    let val = $(e.target).val();
    let index = $(e.target).attr("index");
    setJsonFields((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], key: val };
      return newData;
    });
  };

  const handleJsonFieldInputVal = (e) => {
    let val = $(e.target).val();
    let index = $(e.target).attr("index");
    setJsonFields((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], val: val };
      return newData;
    });
  };

  // function active_card(e) {
  //   let target;
  //   $("#cards li").removeClass("active");
  //   $(e.target).closest("li").addClass("active");
  //   target = e.target;
  //   setEditingElement(target);
  //   let img_url = $(target).attr("img-url");
  //   let card_title = $(target).attr("title");
  //   let card_description = $(target).attr("description");
  //   let card_btn_text = $(target).attr("button-name");
  //   let card_btn_action = $(target).attr("button-action-type");
  //   let card_btn_data = $(target).attr("button-data");
  //   $("#card-editor").show();
  //   $("#card-title-editor").val(card_title);
  //   $("#card-description-editor").val(card_description);
  //   $("#card-button-name-editor").val(card_btn_text);
  //   $("#card-btn-action").val(card_btn_action).change();
  //   $("#card-editor .card-btn-action-res:visible").val(card_btn_data);
  // }

  async function remove_card(e) {
    let url = $(e.target).closest("li").find(".overlay").attr("img-url");
    if (Variables.validateURL(url)) {
      await Variables.remove_document(url);
    }
    $(e.target)
      .closest("li")
      .fadeOut(300, function () {
        $(this).remove();
      });
    $("#card-editor").hide();
  }

  function addNewCard(e) {
    let card = $(`<li class="card ui-state-default">
    <div class="overlay" img-url="" title="Card title" description="Lorem Ipsum is simply dummy text of the printing and
    typesetting industry. Lorem Ipsum" button-name='Submit' button-action-type="message" button-data="afh akjh">
      <h4>Edit</h4>
      <b class="remove-card">
        x
      </b>
    </div>
    <div class="top">
      <div class="container card-img" style="background:url('');">
        <img src="" />
      </div>
    </div>
    <div class="card-body">
      <b class="card-title">
        Card title
      </b>
      <br />
      <p class="card-description">
        Lorem Ipsum is simply dummy text of the printing and
        typesetting industry. Lorem Ipsum
      </p>
      <button class="card-btn btn btn-sm btn-warning">
        Buy now
      </button>
    </div>
  </li>`);
    $(card)
      .find(".overlay")
      .on("click", function () {
        let target;
        $("#cards li").removeClass("active");
        $(this).closest("li").addClass("active");
        setEditingElement(this);
        let img_url = $(this).attr("img-url");
        let card_title = $(this).attr("title");
        let card_description = $(this).attr("description");
        let card_btn_text = $(this).attr("button-name");
        let card_btn_action = $(this).attr("button-action-type");
        let card_btn_data = $(this).attr("button-data");
        $("#card-editor").show();
        $("#card-title-editor").val(card_title);
        $("#card-description-editor").val(card_description);
        $("#card-button-name-editor").val(card_btn_text);
        $("#card-btn-action").val(card_btn_action).change();
        $("#card-editor .card-btn-action-res:visible").val(card_btn_data);
      });
    $(card)
      .find(".remove-card")
      .on("click", function () {
        $(this)
          .closest("li")
          .fadeOut(300, async function () {
            let url = $(this).find(".overlay").attr("img-url");
            if (Variables.validateURL(url)) {
              await Variables.remove_document(url);
            }
            $(this).remove();
          });
        $("#card-editor").hide();
      });
    $("#sortable-cards").prepend(card);
  }

  async function saveIntentDescription(e) {
    let error = false;
    let intent_id = $(e.target).attr("uid");
    if (intent_id != "") {
      Variables.loading_btn("#intent-description-save-btn", "Saving");
      let name = $("#intent-name").val();
      let description = $("#intent-description-area").val();
      let intent = $("#intent-code").val();
      let intent_action = $("#intent-action").val();
      let ask_for_switch = $("#ask-for-switch").is(":checked");
      let ask_for_text = $("#ask-for-switch-text").val();
      let preset_form = $("#preset-form").val();
      let endpoint_method = $("#endpoint-method").val();
      let endpoint_url = $("#endpoint-url").val();
      let intent_response = $("#intent-response").val();
      let preset_buttons = [];
      let action = {};
      let response = {};
      setendpoint_txt(endpoint_url);
      setendpoint_method_txt(endpoint_method);
      action["action_type"] = intent_action;
      if (name == "") {
        error = true;
        $("#intent-name").css({
          border: "1px solid red",
        });
      } else {
        error = false;
        $("#intent-name").css({
          border: "1px solid green",
        });
      }
      if (intent == "") {
        error = true;
        $("#intent-code").css({
          border: "1px solid red",
        });
      } else {
        error = false;
        $("#intent-code").css({
          border: "1px solid green",
        });
      }
      if (intent_action == "webhook") {
        if (
          $("#endpoint-method").val() == "" ||
          $("#endpoint-method").val() == undefined
        ) {
          error = true;
          $("#endpoint-method").css({
            border: "1px solid red",
          });
        } else {
          error = false;
          action["endpoint_method"] = endpoint_method;
          $("#endpoint-method").css({
            border: "1px solid green",
          });
        }
        if (endpoint_url == "") {
          error = true;
          $("#endpoint-url").css({
            border: "1px solid red",
          });
        } else {
          error = false;
          action["endpoint_url"] = endpoint_url;
          $("#endpoint-url").css({
            border: "1px solid green",
          });
          //Check all the JSON key values
          jsonFields.forEach((element, index) => {
            if (element.key == "" || element.val == "") {
              error = true;
              $("#json-fields .json-field").eq(index).find("input").css({
                border: "1px solid red",
              });
            } else {
              $("#json-fields .json-field").find("input").css({
                border: "var(--bs-border-width) solid var(--bs-border-color)",
              });
            }
          });

          if (!error) {
            action["requested_json_body"] = jsonFields;
          }
        }
        if (ask_for_switch) {
          action["action_switch"] = true;
          if (ask_for_text == "") {
            error = true;
            $("#ask-for-switch-text").css({
              border: "1px solid red",
            });
          } else {
            error = false;
            action["ask_for_text"] = ask_for_text;
            $("#ask-for-switch-text").css({
              border: "1px solid green",
            });
          }
          if (
            $("#preset-form").val() == "" ||
            $("#preset-form").val() == undefined
          ) {
            error = true;
            $("#preset-form").css({
              border: "1px solid red",
            });
          } else {
            error = false;
            action["preset_form"] = preset_form;
            $("#preset-form").css({
              border: "1px solid green",
            });
          }
        } else {
          action["action_switch"] = false;
        }
      } else {
        if (intent_response == "") {
          error = true;
          $("#intent-response").css({
            border: "1px solid red",
          });
        } else {
          error = false;
          response["response"] = intent_response;
          $("#intent-response").css({
            border: "1px solid green",
          });
        }
      }

      //Check ticket data
      if (raiseTicket) {
        $("#ticket-info .field").each((index, element) => {
          let val = $(element).val();
          if (val == "") {
            error = true;
            $(element).css({
              border: "1px solid red",
            });
          } else {
            $(element).css({
              border: "1px solid green",
            });
            if (action.ticket == undefined) {
              action["ticket"] = {};
            }
            let name = $(element).attr("name");
            action.ticket[name] = val;
          }
        });
      } else {
        if (action.ticket !== undefined) {
          delete action.ticket;
        }
      }

      $(".edit-preset-btns").each((i, element) => {
        let btn_name = $(element).attr("name");
        let btn_action_type = $(element).attr("action-type");
        let btn_data = $(element).attr("data");
        preset_buttons.push({
          name: btn_name,
          action: btn_action_type,
          data: btn_data,
        });
      });

      let cards = [];
      await Promise.all(
        $("#intent-description #cards .card").map(async (i, obj) => {
          let overlay = $(obj).find(".overlay");
          let card = {};
          card["img-url"] = $(overlay).attr("img-url");
          if (!Variables.validateURL(card["img-url"])) {
            await fetch(card["img-url"])
              .then((res) => res.blob())
              .then(async (blob) => {
                let res = await Variables.upload_document(blob);
                card["img-url"] = res.url;
                $(obj)
                  .find(".card-img")
                  .css({
                    "background-image": `url('${res.url}')`,
                  });
                $(obj)
                  .find(".card-img img")
                  .css({
                    "background-image": `url('${res.url}')`,
                  });
                $(obj).find(".overlay").attr("src", res.url);
                $(obj).find(".overlay").attr("img-url", res.url);
              });
          }
          card["title"] = $(overlay).attr("title");
          card["description"] = $(overlay).attr("description");
          card["button-name"] = $(overlay).attr("button-name");
          card["button-action-type"] = $(overlay).attr("button-action-type");
          card["button-data"] = $(overlay).attr("button-data");
          cards.push(card);
        })
      );

      response["buttons"] = preset_buttons;
      response["cards"] = cards;
      let req_data = Variables.create_req_data({
        action: "update_description",
        intent_id: intent_id,
        name: name,
        description: description,
        intent: intent,
        intent_action: JSON.stringify(action),
        response: JSON.stringify(response),
      });

      if (!error) {
        $("#err-msg").hide();
        axios.post(Variables.serverUrl("intent/"), req_data).then((res) => {
          Variables.unloading_btn("#intent-description-save-btn");
          $("#xbot-endpoint-url").val(endpoint_txt);
          $("#xbot-endpoint-method").val(endpoint_method_txt);
        });
      } else {
        Variables.unloading_btn("#intent-description-save-btn");
        $("#err-msg").show();
      }
    }
  }

  function saveIntentExamples(e) {
    let mdata = [];
    $("#all-examples .example").each(function (i, element) {
      let desc = $(element).find(".desc").val();
      let code = $(element).find(".code").val();
      let d = {};
      d["desc"] = desc;
      d["code"] = code;
      mdata.push(d);
    });
    Variables.loading_btn("#intent-examples-save-btn", "Saving");
    let req_data = Variables.create_req_data({
      action: "update_example",
      intent_id: $(e.target).attr("uid"),
      example: JSON.stringify(mdata),
    });
    axios.post(Variables.serverUrl("intent/"), req_data).then(async (res) => {
      Variables.unloading_btn("#intent-examples-save-btn");
    });
  }

  function changeCheckboxProp(e) {
    if ($(e.target).is(":checked")) {
      $(e.target).prop("checked", true);
      $("#ask-for-info .container").show();
    } else {
      $(e.target).removeProp("checked");
      $("#ask-for-info .container").hide();
    }
  }
  function card_title_editing(e) {
    let val = $(e.target).val();
    $(editingElement).closest("li").find(".card-title").text(val);
    $(editingElement).attr("title", val);
  }
  function card_description_editing(e) {
    let val = $(e.target).val();
    $(editingElement).closest("li").find(".card-description").text(val);
    $(editingElement).attr("description", val);
  }
  function card_button_name_editing(e) {
    let val = $(e.target).val();
    $(editingElement).closest("li").find(".card-btn").html(val);
    $(editingElement).attr("button-name", val);
  }
  function card_button_data_editing(e) {
    let val = $(e.target).val();
    $(editingElement).attr("button-data", val);
  }
  useEffect(() => {
    $("#card-image-editor").on("change", async function () {
      let file = $(this)[0].files[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        $("#sortable-cards > .active")
          .find(".card-img")
          .css({
            "background-image": `url('${reader.result}')`,
          });
        $("#sortable-cards > .active")
          .find(".card-img img")
          .css({
            "background-image": `url('${reader.result}')`,
          });
        $("#sortable-cards > .active")
          .find(".card-img img")
          .attr("src", reader.result);
        $("#sortable-cards > .active")
          .find(".overlay")
          .attr("img-url", reader.result);
      };
    });
    $(document).on("click", ".card .overlay", function () {
      setEditingElement(this);
    });
    $(function () {
      $("#sortable-cards").sortable();
    });
    $("#card-btn-action").on("change", function () {
      $("#sortable-cards > .active .overlay").attr(
        "button-action-type",
        $(this).val()
      );
      $("#sortable-cards > .active .overlay").attr("button-data", "");
      $(".card-btn-action-res").val("");
      if ($(this).val() == "message") {
        $(".card-btn-action-res").hide();
        $("#card-btn-message").show();
      } else if ($(this).val() == "link") {
        $(".card-btn-action-res").hide();
        $("#card-btn-link").show();
      }
      if ($(this).val() == "form") {
        $(".card-btn-action-res").hide();
        $("#card-btn-forms").show();
      }
    });
    $(".remove-example").on("click", function () {
      $(this).parent(".example").remove();
    });
    $("#add-example-form").on("submit", function (e) {
      e.preventDefault();
      let text = $(this).find(".message").val();
      let code = $(this).find(".intent-code").val();
      function removeExample(e) {
        $(this).parent(".example").remove();
      }
      let example = $(`
        <div class="example">
          <input class="limit-text form-control desc" value="${text}"/>
          : <input class="form-control limit-text code" style="width:'fit-content';max-width:'50px !important'" value="${code}" />
          <b style="font-size: 1.1rem; cursor: pointer;" class="remove-example">&nbsp;&#x292B;</b>
        </div>
      `);
      $(example).find(".remove-example").on("click", removeExample);
      $("#all-examples").prepend(example);
      $(this).find("input").val("");
    });
    if ($("#intent-action").val() == "message") {
      $("#ask-for-info,#endpoint").hide();
    } else if ($("#intent-action").val() == "webhook") {
      $("#intent-response").hide();
    }
    $("#intent-action").on("change", function () {
      let val = $(this).val();
      if (val == "message") {
        $("#ask-for-info,#endpoint,#request-method-label").hide();
        $("#intent-response").show();
        $("#json-fields-container").hide();
      } else if ($("#intent-action").val() == "webhook") {
        $("#ask-for-info,#endpoint,#request-method-label").show();
        $("#intent-response").hide();
        $("#json-fields-container").show();
      }
    });
    $(".remove-btn").on("click", function () {
      $(this).parent("li").remove();
    });
    $("#intent-settings .menu h6").on("click", function (e) {
      $("#intent-settings .menu h6").removeClass("active");
      if ($(this).attr("target") == "intent-description") {
        $("#intent-description").show();
        $("#intent-examples").hide();
      } else {
        $("#intent-examples").show();
        $("#intent-description").hide();
      }
      $(this).addClass("active");
    });
  }, []);
  return (
    <div
      id="intent-settings"
      style={{ display: "none" }}
      className="col-md-4 col-sm-12 col-lg-5"
    >
      <div
        className="menu"
        style={{
          display: "flex",
          "justify-content": "space-around",
          "margin-bottom": "10px",
          cursor: "pointer",
        }}
      >
        <h6 target="intent-description" className="active">
          Description
        </h6>
      </div>
      <div
        id="intent-description"
        style={{
          "max-height": "600px",
          "overflow-y": "scroll",
          padding: "2px 8px",
        }}
      >
        <form>
          <input
            id="intent-name"
            className="form-control"
            placeholder="Name"
            required
          />
          <br />
          <textarea
            id="intent-description-area"
            className="form-control"
            placeholder="Description (optional)"
          ></textarea>
          <br />
          <input
            id="intent-code"
            className="form-control"
            placeholder="Intent"
            required
          />
        </form>
        <hr></hr>
        <br />
        <h5>Action</h5>
        <form>
          <select
            className="form-control form-select"
            id="intent-action"
            style={{ width: "200px" }}
          >
            <option value="message">Message</option>
            <option value="webhook">Webhook</option>
          </select>
          <div
            id="ask-for-info"
            style={{
              background: "rgb(236 236 236)",
              padding: "15px",
              "border-radius": "10px",
              width: "fit-content",
              "margin-top": "20px",
            }}
          >
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="ask-for-switch"
                onClick={changeCheckboxProp}
              />
              <label
                class="form-check-label"
                style={{ "margin-right": "10px" }}
                for="ask-for-switch"
              >
                Ask for additional information
              </label>
            </div>
            <div className="container" style={{ display: "none" }}>
              <br />
              <textarea
                className="form-control"
                id="ask-for-switch-text"
                placeholder="Form title"
              ></textarea>
              <label>Select a form</label>
              <select
                id="preset-form"
                className="form-control form-select"
                width="100%"
              >
                {(() => {
                  if (all_forms !== undefined) {
                    return (
                      <>
                        {all_forms.map((obj) => {
                          if ("botPresetForm" == obj.uid) {
                            return (
                              <option value={obj.uid} selected>
                                {obj.title}
                              </option>
                            );
                          } else {
                            return <option value={obj.uid}>{obj.title}</option>;
                          }
                        })}
                      </>
                    );
                  } else {
                    return <>No forms</>;
                  }
                })()}
              </select>
            </div>
          </div>
          <br />
          <small id="request-method-label" className="form-text">
            Method
          </small>
          <div id="endpoint">
            <select
              className="form-control form-select"
              id="endpoint-method"
              style={{ "max-width": "fit-content" }}
            >
              <option value="post">POST</option>
              <option value="get">GET</option>
            </select>
            <input
              className="form-control"
              type="url"
              id="endpoint-url"
              placeholder="Endpoint URL"
            />
          </div>
        </form>
        <br />
        <div id="json-fields-container">
          <p className="form-text">
            Request body <b>(JSON)</b>
          </p>
          <div id="json-fields">
            <ul className="container">
              <li
                className="flex-horizon"
                style={{ justifyContent: "space-around" }}
              >
                <p>Key</p>
                <p>Value</p>
              </li>
              {jsonFields.length ? (
                <>
                  {jsonFields.map((item, index) => (
                    <div className="flex-horizon">
                      <li className="json-field flex-horizon" index={index}>
                        <input
                          className="form-control key"
                          defaultValue=""
                          value={item.key}
                          onChange={handleJsonFieldInputKey}
                          index={index}
                        />
                        <input
                          className="form-control value"
                          defaultValue=""
                          value={item.val}
                          onChange={handleJsonFieldInputVal}
                          index={index}
                        />
                      </li>
                      <b
                        onClick={removeJsonField}
                        className="flex-horizon"
                        style={{
                          padding: "3px",
                          height: "20px",
                          width: "20px",
                          borderRadius: "50%",
                          cursor: "pointer",
                        }}
                        index={index}
                      >
                        &#x292B;
                      </b>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <small style={{ width: "100%", textAlign: "center" }}>
                    No fields yet. Add one now.
                  </small>
                </>
              )}
            </ul>
            <br />
            <button
              style={{ width: "100%" }}
              className="btn btn-sm btn-dark"
              onClick={addJsonField}
            >
              + Add field
            </button>
          </div>
        </div>
        <br />
        <div id="raise-ticket" style={{ maxWidth: "450px" }}>
          <div className="form-check form-switch">
            <input
              type="checkbox"
              className="form-check-input"
              role="switch"
              id="ask-for-raise-ticket"
              checked={raiseTicket}
              onChange={() => setRaiseTicket(!raiseTicket)}
            />
            &#xa0;
            <label class="form-check-label" for="ask-for-raise-ticket">
              Raise ticket{" "}
              <img
                height={18}
                src={ticketIcon}
                style={{ transform: "rotate(-45deg)" }}
              />
            </label>
          </div>

          {raiseTicket ? (
            <>
              <div id="ticket-info">
                <br />
                <p>Ticket info</p>
                <input
                  className="form-control ticket-subject field"
                  placeholder="Subject"
                  name="subject"
                  value={ticketSubject}
                  defaultValue={""}
                  onChange={(event) => {
                    setTicketSubject(event.target.value);
                  }}
                />
                <br />
                <input
                  className="form-control ticket-body field"
                  placeholder="Body"
                  name="body"
                  value={ticketBody}
                  defaultValue={""}
                  onChange={(event) => {
                    setTicketBody(event.target.value);
                  }}
                />
                <br />
                <input
                  className="form-control ticket-due-date field"
                  placeholder="Due date in days"
                  type="number"
                  name="dueDate"
                  value={ticketDueDate}
                  defaultValue={""}
                  onChange={(event) => {
                    setTicketDueDate(event.target.value);
                  }}
                />
                <br />
                <small className="form-text">Assign to</small>
                <br />
                <select
                  className="form-select ticket-assigned-to-id field"
                  style={{ width: "fit-content" }}
                  name="assigned_to_id"
                  value={ticketAssignedToId}
                  defaultValue={""}
                  onChange={(event) => {
                    setTicketAssignedToId(event.target.value);
                  }}
                >
                  {groups.map((item, index) => (
                    <option value={item.uid}>{item.name}</option>
                  ))}
                </select>
                <small className="form-text">
                  You can only assign tickets to groups if it is raised by an
                  intent.
                </small>
                <br/>
                <br />
                <small className="form-text">Ticket type</small>
                <br />
                <select
                  className="form-select ticket-type field"
                  style={{ width: "fit-content" }}
                  name="type"
                  value={ticketType}
                  defaultValue={""}
                  onChange={(event) => {
                    setTicketType(event.target.value);
                  }}
                >
                  <option value="problem">Problem</option>
                  <option value="question">Question</option>
                  <option value="task">Task</option>
                  <option value="incident">Incident</option>
                </select>
                <br/>
                <small className="form-text">Ticket priority</small>
                <br />
                <select
                  className="form-select ticket-priority field"
                  style={{ width: "fit-content" }}
                  name="priority"
                  value={ticketPriority}
                  defaultValue={""}
                  onChange={(event) => {
                    setTicketPriority(event.target.value);
                  }}
                >
                  <option value="high">High</option>
                  <option value="medium">Medium</option>
                  <option value="low">Low</option>
                </select>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <hr></hr>
        <br />
        <h5>Response</h5>
        <div>
          <textarea
            className="form-control"
            id="intent-response"
            placeholder="Response Message"
          ></textarea>
          <br />
          <p>Buttons</p>
          <ul id="response-preset-btns">
            <button
              onClick={handleEditBtnShow}
              id="add-button"
              class="add-preset-btn theme-btn"
              do="Add"
            >
              + Add new button
            </button>
          </ul>
          <br />
          <p>Cards</p>
          <div id="cards">
            <div className="container">
              <div className="cards-list">
                <ul id="sortable-cards"></ul>
              </div>
            </div>
          </div>
          <button
            id="add-card"
            className="btn btn-outline-dark btn-sm"
            onClick={addNewCard}
          >
            + Add new card
          </button>
          <div id="card-editor">
            <div className="container">
              <div className="card-title">
                <h6>Edit your card</h6>
                <br />
              </div>
              <div className="card-body">
                <div className="container">
                  <div>
                    <label>Upload an image</label>
                    <br />
                    <input
                      id="card-image-editor"
                      className="form-control"
                      type="file"
                      accept="image/*"
                      placeholder="Card title"
                      style={{
                        background: `url('${cameraIcon}') center center no-repeat`,
                        "background-size": "contain",
                      }}
                    />
                    <br />
                    <br />
                    <label>Card title</label>
                    <br />
                    <input
                      id="card-title-editor"
                      className="form-control"
                      placeholder="Card title"
                      maxLength={60}
                      defaultValue={"Card title"}
                      onInput={card_title_editing}
                    />
                    <small>Maximum 60 letters</small>
                    <br />
                    <br />
                    <label>Card description</label>
                    <br />
                    <textarea
                      className="form-control"
                      id="card-description-editor"
                      maxLength={120}
                      placeholder="Card description"
                      onChange={card_description_editing}
                    ></textarea>
                    <small>Maximum 120 letters</small>
                    <br />
                    <br />
                    <label>Call to action (CTA)</label>
                    <br />
                    <input
                      id="card-button-name-editor"
                      className="form-control"
                      placeholder="Button name"
                      maxLength={20}
                      defaultValue={"Card title"}
                      onInput={card_button_name_editing}
                    />
                    <small>Maximum 20 letters</small>
                    <br />
                    <br />
                    <label>Select a button action</label>
                    <select id="card-btn-action" className="form-control">
                      <option value={"message"}>Message</option>
                      <option value={"link"}>Link</option>
                      <option value={"form"}>Form</option>
                    </select>
                    <textarea
                      id="card-btn-message"
                      onInput={card_button_data_editing}
                      className="card-btn-action-res form-control"
                      placeholder="Write a message"
                    ></textarea>
                    <input
                      id="card-btn-link"
                      onInput={card_button_data_editing}
                      className="card-btn-action-res form-control"
                      placeholder="http://example.com/"
                      style={{ display: "none" }}
                    />
                    <select
                      id="card-btn-forms"
                      onChange={card_button_data_editing}
                      className="card-btn-action-res form-control"
                      style={{ display: "none" }}
                    >
                      {(() => {
                        if (all_forms !== undefined) {
                          return (
                            <>
                              {all_forms.map((obj) => {
                                if ("botPresetForm" == obj.uid) {
                                  return (
                                    <option value={obj.uid} selected>
                                      {obj.title}
                                    </option>
                                  );
                                } else {
                                  return (
                                    <option value={obj.uid}>{obj.title}</option>
                                  );
                                }
                              })}
                            </>
                          );
                        }
                      })()}
                    </select>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <EditBtn
            show={showEditBtn}
            handleClose={handleEditBtnClose}
            ele={btnEle}
            handleShow={handleEditBtnShow}
            forms={all_forms}
          />
        </div>
        <br />
        <button
          id="intent-description-save-btn"
          onClick={saveIntentDescription}
          className="btn btn-success theme-btn"
        >
          Save
        </button>
        <p id="err-msg" style={{ color: "red", display: "none" }}>
          Fix the errors above.
        </p>
      </div>
    </div>
  );
}

export default Section2;
