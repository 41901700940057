import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "../css/NewBot.css";
import Variables from "../variables";
import axios from "axios";

function DeleteGroup({ show, handleClose, groups, setGroups, uid }) {
  const delete_group = (e) => {
    const indexToDelete = groups.findIndex((item) => item.uid === uid);
    let req_data = Variables.create_req_data({ action: "delete", uid: uid });
    Variables.loading_btn("#delete-group-btn", "Deleting");
    axios.post(Variables.serverUrl("groups/"), req_data).then((res) => {
      if (res.status == 200) {
        Variables.unloading_btn("#delete-group-btn");
        handleClose();
        if (indexToDelete !== -1) {
          groups.splice(indexToDelete, 1);
          setGroups(groups);
        }
      }
    });
  };
  return (
    <>
      <Modal
        id="delete-group"
        show={show}
        onHide={handleClose}
        backdrop="static"
        fullscreen="md-down"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b>Note- </b>
          <font>Delete the group.</font>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            id="delete-group-btn"
            className="theme-btn"
            variant="primary"
            onClick={delete_group}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteGroup;
