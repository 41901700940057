import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Variables from "../variables";
import $ from "jquery";
import axios from "axios";
import { useEffect, useState } from "react";

function UpdateTicket({
  show,
  handleClose,
  id,
  field,
  allTickets,
  setAllTickets,
  ticket,
  setTicket,
  comments,
  setComments
}) {
  const [errMsg,setErrMsg]=useState("")
  function submitUpdate(e) {
    let val = $("#" + field + "-field").val();
    let req_data = Variables.create_req_data({
      action: "update-field",
      field: field,
      uid: id,
      val: val,
    });

    Variables.loading_btn(e.target, "Updating")
    axios.post(Variables.serverUrl("tickets/"),req_data).then((res) => {
      if (res.status == 200) {
        setAllTickets((currentTickets) =>
          currentTickets.map((ticket) => {
            if (ticket.uid == id) {
              return { ...ticket, [field]: val };
            }
            return ticket;
          })
        );
        setTicket((ticket) => ({
          ...ticket,
          [field]: val,
        }));
        setComments([...comments,res.data.comment])
        Variables.unloading_btn(e.target)
        handleClose()
        setErrMsg("")
      }
    }).catch((err)=>{
      setErrMsg(err.response.data.msg)
      Variables.unloading_btn(e.target)
    });
  }
  return (
    <>
      <Modal
        id="update-ticket-modal"
        show={show}
        onHide={handleClose}
        backdrop="static"
        fullscreen="md-down"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Update ticket's {field.replace("_", " ")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="msg" style={{color: "#cd1010" }}>{errMsg}</p>
          <br />
          {field == "status" ? (
            <>
              <select id={field + "-field"} className="form-select">
                <option value="open">Open</option>
                <option value="processing">Processing</option>
                <option value="resolved">Resolved/Closed</option>
              </select>
            </>
          ) : (
            <></>
          )}
          {field == "priority" ? (
            <>
              <select id={field + "-field"} className="form-select">
                <option value="high">High</option>
                <option value="medium">Medium</option>
                <option value="low">Low</option>
              </select>
            </>
          ) : (
            <></>
          )}
          {field == "type" ? (
            <>
              <select id={field + "-field"} className="form-select">
                <option value="question">Question</option>
                <option value="problem">Problem</option>
                <option value="task">Task</option>
              </select>
            </>
          ) : (
            <></>
          )}
          {field == "due_date" ? (
            <>
              <input
                id={field + "-field"}
                type="date"
                className="form-select"
              />
            </>
          ) : (
            <></>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{handleClose();setErrMsg("")}}>
            Close
          </Button>
          <Button
            id="update-ticket-btn"
            className="theme-btn"
            variant="primary"
            onClick={submitUpdate}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateTicket;
