import React, { useEffect, useRef, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Variables from "../variables";
import $ from "jquery";

function HomeSection2({ conv_analysis }) {
  const [data, setData] = useState([]);
  const l_data=useRef(conv_analysis)
  useEffect(() => {
    setData(conv_analysis.data);
    l_data.current=conv_analysis
    setTimeout(() => {
      $("#total-conversations").trigger("click");
      setToday_data(conv_analysis.today);
      setYesterday_data(conv_analysis.yesterday);
      setData(conv_analysis.data)
    }, 2500);
  }, [conv_analysis]);
  const [analyticsType, setAnalyticsType] = useState("");
  const [yesterday_data, setYesterday_data] = useState("0");
  const [today_data, setToday_data] = useState("0");
  const [delta_rate, setDelta_rate] = useState("0");
  const [delta_rate_sign, setDelta_rate_sign] = useState("");

  useEffect(()=>{
    if(isNaN(delta_rate)){
      setDelta_rate(0)
    }
  },[delta_rate])

  useEffect(() => {
    $("#leads-card").on("click", function () {
      let form_id = $(this).attr("uid");
      setAnalyticsType("Leads");
      if (form_id) {
        (async () => {
          let data = await Variables.leadsAnalytics(form_id);
          setYesterday_data(data.yesterday);
          setToday_data(data.today);
          setData(data.data);
          if (data.yesterday == 0) {
            setDelta_rate_sign("+");
            setDelta_rate(Math.floor(data.today * 100));
          }
          if (data.today == 0 && data.yesterday > 0) {
            setDelta_rate_sign("-");
            setDelta_rate(Math.floor(data.yesterday * 100));
          }
          if (data.today > 0 && data.yesterday == 0) {
            setDelta_rate_sign("+");
            setDelta_rate(data.today * 100);
          } else if (data.yesterday > data.today) {
            setDelta_rate_sign("-");
            setDelta_rate(100 - Math.floor(data.today / data.yesterday) * 100);
          } else {
            setDelta_rate_sign("+");
            setDelta_rate(100 - Math.floor(data.yesterday / data.today) * 100);
          }
        })();
      }
    });
    
    $("#total-conversations").on("click", function () {
      setAnalyticsType("Conversations");
      setData(l_data.current.data);
      setToday_data(l_data.current.today);
      setYesterday_data(l_data.current.yesterday);
      if (conv_analysis.yesterday == 0) {
        setDelta_rate_sign("+");
        setDelta_rate(Math.floor(conv_analysis.today * 100));
      }
      if (conv_analysis.today == 0 && conv_analysis.yesterday > 0) {
        setDelta_rate_sign("-");
        setDelta_rate(Math.floor(conv_analysis.yesterday * 100));
      }
      if (conv_analysis.today > 0 && conv_analysis.yesterday == 0) {
        setDelta_rate_sign("+");
        setDelta_rate(Math.floor(conv_analysis.today * 100));
      } else if (conv_analysis.yesterday > conv_analysis.today) {
        setDelta_rate_sign("-");
        setDelta_rate(
          100 -
            Math.floor((conv_analysis.today / conv_analysis.yesterday) * 100)
        );
      } else {
        setDelta_rate_sign("+");
        setDelta_rate(
          100 -
            Math.floor((conv_analysis.yesterday / conv_analysis.today) * 100)
        );
      }
    });
  }, []);
  return (
    <div
      className="home-section homesection2 card row"
      width="100%"
      height="100%"
    >
      <div
        className="col-sm-12 col-md-8 col-lg-8"
        style={{ background: "#f9f9f9" }}
      >
        <div className="title row">
          <h5 className="col-6">Activity ({analyticsType})</h5>
          <p className="col-6" style={{ color: "black" }}>
            Last 30 days
          </p>
        </div>
        <ResponsiveContainer width="100%" height={250}>
          <LineChart
            width={400}
            height={250}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis
              dataKey="name"
              angle={-25}
              tick={{
                display: "none",
                marginTop: "20px",
                fontSize: 12,
                fill: "black",
              }}
            />
            <YAxis tick={{ fontSize: 12, fill: "black" }} />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey={analyticsType}
              stroke="#8884d8"
              activeDot={{ r: 6 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="comparison col-sm-12 col-md-4 col-md-4">
        <div className="container">
          <h5>Comparison</h5>
          <div className="row">
            <div className="col-6">
              <p>Yesterday</p>
              <h3 id="yesterday-data">{yesterday_data}</h3>
            </div>
            <div className="col-6">
              <p>Today</p>
              <h3 id="today-data">{today_data}</h3>
            </div>
          </div>
          <br />
          <br />
          <p>
            Growth by{" "}
            <font className="analysis-color" id="delta-rate">
              {delta_rate_sign}
              {delta_rate}%
            </font>
          </p>
        </div>
      </div>
    </div>
  );
}

export default HomeSection2;
