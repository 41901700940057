import { Link } from "react-router-dom";
import "./css/Verify.css";
import emailIcon from "./images/email.svg";
import { useEffect } from "react";
import Variables from "./variables";
import axios from "axios";
import $ from "jquery"

function Verify() {
  let token = "";
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const extracted_id = params.get("token");
  token = extracted_id;
  useEffect(() => {
    let req_data = {
      token: token,
    };
    console.log(req_data);
    axios.post(Variables.serverUrl("verify-mail/"), req_data).then((res) => {
      if (res.data.status=='success') {
        $("#verified").show();
      } else {
        $("#unverified").show();
      }
    });
  }, []);
  return (
    <>
      <div id="verify">
        <div className="container">
          <div className="card">
            <div className="card-body" style={{ textAlign: "center" }}>
              <img src={emailIcon} width={150} />
              <br />
              <br />
              <div
                id="verified"
                style={{ textAlign: "center", margin: "auto", display: "none" }}
              >
                <h4 style={{ color: "#00a236" }}>&#x2714; Email verified</h4>
                <p>
                  Your email address has been verified. Login to your account
                  and enjoy our services.
                </p>
                <Link to="/login" className="btn btn-dark round">
                  Login &#x2192;
                </Link>
                <br />
                <br />
              </div>
              <div
                id="unverified"
                style={{ textAlign: "center", margin: "auto", display: "none" }}
              >
                <h4 style={{ color: "#d91313" }}>
                  &#x292C; Email verification failed
                </h4>
                <p>
                  The token is expired or invalid. Please try again with a valid
                  token.
                </p>
                <Link to="/login" className="btn btn-dark round">
                  Login &#x2192;
                </Link>
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Verify;
