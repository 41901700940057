import React, { useEffect, useLayoutEffect, useState } from "react";
import "./css/forms.css";
import options from "./images/options.svg";
import form from "./images/form.png";
import $ from "jquery";
import { Link } from "react-router-dom";
import Header from "./Header";
import Leftnav from "./Leftnav";
import Banner from "./banner";
import Variables from "./variables";
import NewForm from "./modals/NewForm";
import axios, { all } from "axios";

function Forms() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [allForms, setAllForms] = useState([]);
  const [formID, setFormID] = useState("");
  async function deleteForm(e) {
    let ele = e.target;
    let id = $(ele).attr("uid");
    let req_data = Variables.create_req_data({
      id: id,
      ele: "Form",
    });
    await axios.post(Variables.serverUrl("delete/"), req_data).then((res) => {
      $("#" + req_data.id).remove();
    });
  }
  useLayoutEffect(() => {
    async function checkAuth() {
      let status = await Variables.checkAuthentication();
      let req_data = Variables.create_req_data({
        action: "add",
        title: $("#form-title").val(),
      });
      await axios
        .post(Variables.serverUrl("all-forms/"), req_data)
        .then((res) => {
          setAllForms(res.data.forms);
        });
    }
    checkAuth.call();
  }, []);
  useEffect(() => {
    const leftNavLinks = document.getElementsByClassName("left-nav-link");
    for (let i = 0; i < leftNavLinks.length; i++) {
      leftNavLinks[i].classList.remove("active");
    }
    document.getElementsByClassName("forms-link")[0].classList.add("active");
  }, []);
  const findByKeyword = (e) => {
    const eles = $(".form .content .title");
    $(eles).parents("li").hide();
    eles.each((i, obj) => {
      if (
        obj.innerHTML.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
      ) {
        $(obj).parents("li").show();
      }
    });
  };
  return (
    <>
      <div className="App">
        <Leftnav />
        <div className="App-body">
          <header className="sticky-top">
            <Header />
          </header>
          <Banner />
          <div className="forms">
            <div className="top">
              <div className="title">
                <h4 className="title-color">Forms ({allForms.length})</h4>
              </div>
              <button className="btn btn-sm btn-dark" onClick={handleShow}>
                + Create a new form
              </button>
              <NewForm show={show} handleClose={handleClose} />
              <input
                style={{ "max-width": "240px" }}
                onInput={findByKeyword}
                className="form-control"
                type="text"
                placeholder="search"
              />
            </div>
            <div className="middle">
              <ul className="all-forms">
                {(() => {
                  if (allForms.length) {
                    return (
                      <>
                        {allForms.map((obj) => (
                          <li className="form" id={obj.uid}>
                            <Link
                              to={"/app/form-builder?id=" + obj.uid}
                              className="content limit-text"
                            >
                              <img src={form} />
                              <font className="title">{obj.title}</font>
                            </Link>
                            <div className="options dropdown">
                              <img data-bs-toggle="dropdown" src={options} />
                              <ul className="dropdown-menu">
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to={"/app/form-submissions?id=" + obj.uid}
                                  >
                                    Submissions
                                  </Link>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={deleteForm}
                                    uid={obj.uid}
                                  >
                                    Delete
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </li>
                        ))}
                      </>
                    );
                  } else {
                    return <p>You have no forms yet.</p>;
                  }
                })()}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Forms;
