import axios from "axios";
import { useEffect, useLayoutEffect, useState } from "react";
import Variables from "./variables";
import $ from "jquery";
import Header from "./Header";
import Leftnav from "./Leftnav";
import Banner from "./banner";
import "./css/Tickets.css";
import filter from "./images/filter.png";
import deleteIcon from "./images/delete-light.svg";
import Ticket from "./offcanvases/ticket";
import CreateTicket from "./modals/CreateTicket";

function capitalizeFirstLetter(str) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function calculateTimeDifference(utcDateString) {
  const utcDate = new Date(utcDateString);
  const today = new Date();
  const diffInMilliseconds = today - utcDate;
  const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
  const diffInMonths = Math.floor(diffInDays / 30);
  const diffInYears = Math.floor(diffInDays / 365);
  if (diffInYears > 0) {
    return `${diffInYears} year(s)`;
  } else if (diffInMonths > 0) {
    return `${diffInMonths} month(s)`;
  } else {
    return `${diffInDays} day(s)`;
  }
}

export default function Tickets() {
  const [showTickets, setShowTickets] = useState([]);
  const [totalTickets, setTotalTickets] = useState([]);
  const [tags, setTags] = useState([]);
  const [emails, setEmails] = useState([]);
  const [filterObj, setFilterObj] = useState({
    searchKeyword: "",
    searchKey: "id",
    priority: "",
    status: "",
    type: "",
    dateCreated: "",
    dueDate: "",
  });
  const [deleteBtnDisabled, setDeleteBtnDisabled] = useState("disabled");
  const [selectedTickets, setSelectedTickets] = useState([]);
  useEffect(() => {
    let filteredItems = [];
    if (filterObj.searchKey == "id") {
      filteredItems = totalTickets.filter((ticket) =>
        ticket.uid.includes(filterObj.searchKeyword)
      );
    } else if (filterObj.searchKey == "tag") {
      let filteredIds = tags
        .filter((tag) => tag.name.includes(filterObj.searchKeyword))
        .map((tag) => tag.id);
      filteredItems = totalTickets.filter((ticket) =>
        ticket.tags.some((tagId) => filteredIds.includes(tagId))
      );
    }
    if (filterObj.dateCreated != "") {
      filteredItems = filteredItems.filter((ticket) => {
        const ticketDate = new Date(ticket.date_created);
        const filterDate = new Date(filterObj.dateCreated);
        return (
          ticketDate.getFullYear() === filterDate.getFullYear() &&
          ticketDate.getMonth() === filterDate.getMonth() &&
          ticketDate.getDate() === filterDate.getDate()
        );
      });
    }
    if (filterObj.dueDate != "") {
      filteredItems = filteredItems.filter((ticket) => {
        const ticketDate = new Date(ticket.due_date);
        const filterDate = new Date(filterObj.dueDate);
        return (
          ticketDate.getFullYear() === filterDate.getFullYear() &&
          ticketDate.getMonth() === filterDate.getMonth() &&
          ticketDate.getDate() === filterDate.getDate()
        );
      });
    }
    if (filterObj.status != "") {
      filteredItems = filteredItems.filter((ticket) => {
        if (ticket.status.toLowerCase() == filterObj.status) {
          return true;
        } else {
          return false;
        }
      });
    }
    if (filterObj.priority != "") {
      filteredItems = filteredItems.filter((ticket) => {
        if (ticket.priority == filterObj.priority) {
          return true;
        } else {
          return false;
        }
      });
    }
    if (filterObj.type != "") {
      filteredItems = filteredItems.filter((ticket) => {
        if (ticket.type.toLowerCase() == filterObj.type) {
          return true;
        } else {
          return false;
        }
      });
    }
    setShowTickets(filteredItems);
  }, [filterObj]);
  function searchKeyChange(e) {
    let val = $(e.target).val();
    const updatedObject = {
      ...filterObj,
      searchKey: val,
    };
    setFilterObj(updatedObject);
  }
  function searchKeywordChange(e) {
    let val = $(e.target).val();
    const updatedObject = {
      ...filterObj,
      searchKeyword: val,
    };
    setFilterObj(updatedObject);
  }
  function createdDateChange(e) {
    let val = $(e.target).val();
    const updatedObject = {
      ...filterObj,
      dateCreated: val,
    };
    setFilterObj(updatedObject);
  }
  function statusChange(e) {
    let val = $(e.target).val();
    const updatedObject = {
      ...filterObj,
      status: val,
    };
    setFilterObj(updatedObject);
  }
  function typeChange(e) {
    let val = $(e.target).val();
    const updatedObject = {
      ...filterObj,
      type: val,
    };
    setFilterObj(updatedObject);
  }
  function priorityChange(e) {
    let val = $(e.target).val();
    const updatedObject = {
      ...filterObj,
      priority: val,
    };
    setFilterObj(updatedObject);
  }
  function dueDateChange(e) {
    let val = $(e.target).val();
    const updatedObject = {
      ...filterObj,
      dueDate: val,
    };
    setFilterObj(updatedObject);
  }
  function ticketSelected(e) {
    let checked = $(e.target).is(":checked");
    let uid = $(e.target).attr("uid");
    let x = selectedTickets;
    if (checked) {
      setSelectedTickets([...selectedTickets, uid]);
    } else {
      let updatedSelectedTickets = selectedTickets.filter(
        (ticketId) => ticketId !== uid
      );
      setSelectedTickets(updatedSelectedTickets);
    }
  }
  useEffect(() => {
    if (selectedTickets.length) {
      setDeleteBtnDisabled("");
    } else {
      setDeleteBtnDisabled("disabled");
    }
  }, [selectedTickets]);

  function deleteTickets(e) {
    let req_data = Variables.create_req_data({
      action: "delete",
      uids: selectedTickets,
    });
    Variables.loading_btn(e.target, "Deleting");
    $("#tickets-table").find("input").prop("disabled", true);
    axios
      .post(Variables.serverUrl("tickets/"), req_data)
      .then((res) => {
        Variables.unloading_btn(e.target);
        $("#tickets-table").find("input").prop("disabled", false);
        if (res.status == 200) {
          const updatedObjects = showTickets.filter(
            (obj) => !selectedTickets.includes(obj.uid)
          );
          setShowTickets(updatedObjects);
          Variables.unloading_btn(e.target);
          $("#tickets-table").find("input").prop("checked", false);
          setSelectedTickets([]);
        }
      })
      .catch((e) => {
        $("#tickets-table").find("input").prop("disabled", false);
        Variables.unloading_btn(e.target);
        $("#tickets-table").find("input").prop("checked", false);
        setSelectedTickets([]);
      });
  }

  const [ticketID, setTicketID] = useState("");
  //Ticket canvas
  const [ticketOffCanvasShow, setTicketOffCanvasShow] = useState(false);
  const handleTicketOffCanvasClose = () => setTicketOffCanvasShow(false);

  //handle ticket popup
  const [createTicketShow, setCreateTicketShow] = useState(false);
  const handleCreateTicketShow = () => setCreateTicketShow(true);
  const handleCreateTicketClose = () => setCreateTicketShow(false);

  function showTicket(ele) {
    setTicketID($(ele.target).attr("uid"));
    setTicketOffCanvasShow(true);
  }

  useLayoutEffect(() => {
    async function checkAuth() {
      let status = await Variables.checkAuthentication();
    }
    checkAuth.call();
    let req_data = Variables.create_req_data({
      action: "get-all",
    });
    axios.post(Variables.serverUrl("tickets/"), req_data).then((res) => {
      let data = JSON.parse(res.data.tickets);
      let tickets = [];
      data.forEach((element) => {
        let ele = element.fields;
        ele["uid"] = element.pk;
        tickets.push(ele);
      });
      setTotalTickets(tickets);
      if (tickets.length) {
        axios
          .post(
            Variables.serverUrl("email-accounts/"),
            Variables.create_req_data({ action: "get-all" })
          )
          .then((res) => {
            if (res.status == 200) {
              setEmails(res.data.emails);
            }
          });
      }
    });
    axios.post(Variables.serverUrl("tags/"), req_data).then((res) => {
      setTags(res.data.tags);
    });
  }, []);
  useEffect(() => {
    setShowTickets(totalTickets);
  }, [totalTickets]);
  useEffect(() => {
    const leftNavLinks = document.getElementsByClassName("left-nav-link");
    for (let i = 0; i < leftNavLinks.length; i++) {
      leftNavLinks[i].classList.remove("active");
    }
    document.getElementsByClassName("tickets-link")[0].classList.add("active");
  }, []);
  return (
    <>
      <div className="App">
        <Leftnav />
        <div className="App-body">
          <header className="sticky-top">
            <Header />
            <Banner />
          </header>
          <div className="home-container sticky-top">
            <div id="tickets-list" className="row" style={{ padding: "15px" }}>
              <h4>Tickets ({showTickets.length})</h4>
              <br />
              <br />
              <div className="top">
                <div
                  className="container flex-horizon"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="left">
                    <div className="ticket-search input-group mb-3">
                      <select
                        className="form-select"
                        id="ticket-search-option"
                        onChange={searchKeyChange}
                      >
                        <option value="id">Ticket ID</option>
                        <option value="tag">Tag</option>
                      </select>
                      <input
                        id="ticket-search-input"
                        className="form-control"
                        placeholder="Search"
                        onChange={searchKeywordChange}
                      />
                    </div>
                  </div>
                  <div className="right">
                    <div
                      className="options dropdown"
                      style={{ cursor: "pointer" }}
                    >
                      <font>Filter </font>
                      <img data-bs-toggle="dropdown" src={filter} height={20} />
                      <ul className="dropdown-menu" id="ticket-filter">
                        <li className="container">
                          <b>Priority</b>
                          <select
                            id="priority-filter"
                            className="form-select"
                            style={{ fontSize: ".8rem" }}
                            onChange={priorityChange}
                          >
                            <option value="">None</option>
                            <option value="high">High</option>
                            <option value="medium">Medium</option>
                            <option value="low">Low</option>
                          </select>
                        </li>
                        <li className="container">
                          <b>Type</b>
                          <select
                            id="type-filter"
                            className="form-select"
                            style={{ fontSize: ".8rem" }}
                            onChange={typeChange}
                          >
                            <option value="">None</option>
                            <option value="question">Question</option>
                            <option value="problem">Problem</option>
                            <option value="task">Task</option>
                          </select>
                        </li>
                        <li className="container">
                          <b>Status</b>
                          <select
                            id="status"
                            className="form-select"
                            style={{ fontSize: ".8rem" }}
                            onChange={statusChange}
                          >
                            <option value="">None</option>
                            <option value="open">Open</option>
                            <option value="processing">Processing</option>
                            <option value="resolved">Resolved/closed</option>
                          </select>
                        </li>
                        <li className="container">
                          <b>Date created</b>
                          <input
                            type="date"
                            id="date-created"
                            className="form-control"
                            style={{ fontSize: ".8rem" }}
                            onChange={createdDateChange}
                          />
                        </li>
                        <li className="container">
                          <b>Due date</b>
                          <input
                            type="date"
                            id="due-date"
                            className="form-control"
                            style={{ fontSize: ".8rem" }}
                            onChange={dueDateChange}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="bottom">
                <div className="container">
                  <div id="crud" className="flex-horizon">
                    <button
                      className="btn round-btn btn-sm btn-primary"
                      onClick={handleCreateTicketShow}
                    >
                      + Add
                    </button>
                    &nbsp;&nbsp;
                    <button
                      className={
                        "btn round-btn btn-sm btn-danger " + deleteBtnDisabled
                      }
                      id="delete-tickets"
                      onClick={deleteTickets}
                    >
                      <img src={deleteIcon} height={10} /> Delete
                    </button>
                  </div>
                  <br />
                  <table
                    id="tickets-table"
                    class="table table-hover table-borderless"
                  >
                    <thead className="table-dark">
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Subject</th>
                        <th scope="col">Ticket ID</th>
                        <th scope="col">Priority</th>
                        <th scope="col">Status</th>
                        <th scope="col">Type</th>
                        <th scope="col">Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      {showTickets.length === 0 ? (
                        <tr>
                          <td colSpan="7" className="text-center">
                            No records
                          </td>
                        </tr>
                      ) : (
                        showTickets.map((item, index) => (
                          <tr className="ticket-row">
                            <th scope="row">
                              <input
                                type="checkbox"
                                className="form-check-input ticket-selector"
                                uid={item.uid}
                                onChange={ticketSelected}
                              />
                            </th>
                            <td className="limit-text">
                              <a href="#" uid={item.uid} onClick={showTicket}>
                                {item.subject}
                              </a>
                            </td>
                            <td>{item.uid}</td>
                            <td className={item.priority}>
                              {capitalizeFirstLetter(item.priority)}
                            </td>
                            <td className={item.status}>{item.status}</td>
                            <td>{item.type}</td>
                            <td>
                              {calculateTimeDifference(item.date_created)} ago
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Ticket
        show={ticketOffCanvasShow}
        handleClose={handleTicketOffCanvasClose}
        id={ticketID}
        tags={tags}
        setTags={setTags}
        allTickets={totalTickets}
        setAllTickets={setTotalTickets}
        emails={emails}
      />
      <CreateTicket
        show={createTicketShow}
        handleClose={handleCreateTicketClose}
        setTicketList={setTotalTickets}
      />
    </>
  );
}
