import Variables from "../variables";
import $ from "jquery";
import axios from "axios";
import ticketIcon from "../images/ticket.png";
import mailIcon from "../images/email.svg";
import pencil from "../images/pencil.svg";
import { useEffect, useState } from "react";
import UpdateTicket from "../modals/UpdateTicket";
import ChangeTicketAssignment from "../modals/ChangeTicketAssignment";
import ComposeMail from "../toasts/ComposeMail";

let is_member = localStorage.getItem("sharurai_member_login");

function capitalizeFirstLetter(str) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function showTime(utcDateTimeString, convertToLocal = false) {
  const utcDate = new Date(utcDateTimeString + "Z");
  if (convertToLocal) {
    const localDate = new Date(
      utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
    );
    return localDate.toISOString().replace("T", " ").slice(0, 19);
  } else {
    return utcDateTimeString;
  }
}

function Ticket({
  show,
  handleClose,
  id,
  tags,
  setTags,
  allTickets,
  setAllTickets,
  emails
}) {
  const [ticket, setTicket] = useState({});
  const [assignedToName, setAssignedToName] = useState("Unknown");
  const [assignedToEmail, setAssignedToEmail] = useState("");
  const [filteredTags, setFilteredTags] = useState([]);
  const [ticketTags, setTicketTags] = useState([]);
  const [showTags, setShowTags] = useState(false);
  const [TagInputValue, setTagInputValue] = useState("");
  const [tagName, setTagName] = useState("");
  const [comments, setComments] = useState([]);
  const [ticketFieldToUpdate, setTicketFieldToUpdate] = useState("");
  const [activeMember, setActiveMember] = useState("");
  const [you, setYou] = useState(false);
  const [showUpdateTicket, setShowUpdateTicket] = useState(false);
  const [activeMemberId, setActiveMemberId] = useState("");
  const handleShowUpdateTicket = () => setShowUpdateTicket(true);
  const handleCloseUpdateTicket = () => setShowUpdateTicket(false);
  const [composeMailShow,setComposeMailShow]=useState(false)
  const handleComposeMailShow=()=>setComposeMailShow(true)
  const handleComposeMailClose=()=>setComposeMailShow(false)

  const [showUpdateAssignment, setShowUpdateAssignment] = useState(false);
  const handleShowUpdateAssignment = () => setShowUpdateAssignment(true);
  const handleCloseUpdateAssignment = () => setShowUpdateAssignment(false);
  const [conversation,setConversation]=useState({})

  function relatedTags(e) {
    let val = $(e.target).val();
    setTagInputValue(val);
    setTagName(val);
    if (val) {
      const newFilteredTags = tags.filter((tag) => tag.name.includes(val));
      setFilteredTags(newFilteredTags);
      setShowTags(true);
    } else {
      setShowTags(false);
    }
  }
  const handleInputFocus = () => {
    if (TagInputValue) {
      setShowTags(true);
    }
  };
  const handleInputBlur = () => {
    setShowTags(false);
  };
  const claimTicket = (e) => {
    let req_data = Variables.create_req_data({
      action: "claim",
      uid: id,
    });
    axios.post(Variables.serverUrl("tickets/"), req_data).then((res) => {
      if (res.status == 200) {
        $(e.target).replaceWith(
          `<p style="color:green">Ticket claimed by you.</p>`
        );
        setYou(true);
      }
    });
  };
  useEffect(() => {
    let req_data = Variables.create_req_data({
      action: "get-one",
      uid: id,
    });
    if (id != "") {
      axios.post(Variables.serverUrl("tickets/"), req_data).then((res) => {
        if (res.status == 200) {
          let data = res.data.tickets;
          setComments(JSON.parse(data.comments));
          setTicketTags(res.data.tags);
          setTicket(data);
          setConversation(res.data.conversation)
          if (data.assigned_member_id != null) {
            setAssignedToName(`Member (${res.data.assigned_data.name})`);
            setAssignedToEmail(res.data.assigned_data.email);
          } else if (data.assigned_group_id != null) {
            setAssignedToName(`Group (${res.data.assigned_data.name})`);
            setAssignedToEmail("");
            if (data.working_member_id) {
              setActiveMemberId(data.working_member_id);
              if (res.data.assigned_data.working_member.you == true) {
                setYou(true);
                setAssignedToName(`Group (${res.data.assigned_data.name})`);
                setActiveMember("You");
              } else {
                setAssignedToName(`Group (${res.data.assigned_data.name})`);
                setActiveMember(
                  `${res.data.assigned_data.working_member.name} (${res.data.assigned_data.working_member.email})`
                );
              }
            } else {
              setAssignedToName(`Group (${res.data.assigned_data.name})`);
            }
          } else {
            setAssignedToEmail("");
          }
        }
      });
    }
  }, [id]);
  function createTicketEvent(e) {
    e.preventDefault();
    $(e.target).prop("disabled", true);
    let comment = $("#new-ticket-event").val();
    let req_data = Variables.create_req_data({
      action: "add-comment",
      comment: comment,
      uid: id,
      type: "text",
    });
    if (comment != "") {
      Variables.loading_btn("#add-ticket-comment-btn", "Adding");
      axios.post(Variables.serverUrl("tickets/"), req_data).then((res) => {
        if (res.status == 200) {
          setComments([...comments, res.data.comment]);
          $("#new-ticket-event").val("");
          Variables.unloading_btn("#add-ticket-comment-btn");
        }
      });
    }
  }
  useEffect(() => {
    $("#comments-list").scrollTop($("#comments-list").prop("scrollHeight"));
  }, [comments]);
  const addTag = (e) => {
    e.preventDefault();
    let val = tagName;
    $("#tag-input").prop("disabled", true);
    let req_data = Variables.create_req_data({
      action: "add-tag",
      name: val,
      uid: id,
    });
    axios.post(Variables.serverUrl("tickets/"), req_data).then((res) => {
      if (res.status == 200) {
        $("#tag-input").val("");
        $("#tag-input").prop("disabled", false);
        let itemExists = tags.find((item) => item.name === val);
        if (!itemExists) {
          tags = tags.push({ name: val });
          setTags(tags);
        }
        itemExists = ticketTags.find((item) => item.name === val);
        if (!itemExists) {
          setTicketTags([...ticketTags, { name: val }]);
        }
      }
    });
  };
  function removeTag(e) {
    let name = $(e.target).attr("tagName");
    let req_data = Variables.create_req_data({
      action: "remove-tag",
      name: name,
      uid: id,
    });
    axios.post(Variables.serverUrl("tickets/"), req_data).then((res) => {
      if ((res.data.status = 200)) {
        const updatedTags = ticketTags.filter((tag) => tag.name !== name);
        setTicketTags(updatedTags);
      }
    });
  }
  return (
    <>
      <div
        className={`offcanvas offcanvas-end ${show ? "show" : ""}`}
        tabIndex="-1"
        style={{ width: "90%" }}
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title">
            <div className="btn btn-light" onClick={handleClose}>
              <b title="back" type="button">
                &#x2190;&nbsp;&nbsp;
              </b>
              Back
            </div>
            &nbsp;&nbsp;
            <img
              src={ticketIcon}
              style={{ transform: "rotate(45deg)" }}
              height={15}
            />
            &nbsp;<font style={{ fontSize: ".9rem" }}>Ticket ({id})</font>
          </h5>
        </div>
        <div
          className="offcanvas-body"
          id="ticket-panel"
          style={{ paddingTop: "5px" }}
        >
          <div className="container row">
            <div className="col-8 left">
              <div className="top">
                <div className="container">
                  <h5>{ticket.subject}</h5>
                  <div style={{ fontSize: ".85rem" }}>
                    <font style={{ color: "grey" }}>Assigned to - </font>
                    <b>{assignedToName}</b>&nbsp;&nbsp;
                    <img
                      title="Change ticket assignment."
                      style={{ cursor: "pointer" }}
                      src={pencil}
                      height={15}
                      onClick={handleShowUpdateAssignment}
                    />
                    <font className="form-text">{assignedToEmail}</font>
                  </div>
                  {is_member == "true" ? (
                    <>
                      {ticket.working_member_id ? (
                        <>
                          <font style={{ color: "grey" }}>
                            Assigned member -{" "}
                          </font>{" "}
                          <b>{activeMember}</b>
                        </>
                      ) : (
                        <>
                          <br />
                          <button
                            className="btn btn-success btn-sm round-btn"
                            onClick={claimTicket}
                          >
                            Claim
                          </button>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {(ticket.assigned_group_id && activeMemberId) ||
                      ticket.assigned_member_id ? (
                        <>
                          <font style={{ color: "grey" }}>
                            Assigned member -{" "}
                          </font>{" "}
                          <b>{activeMember}</b>
                        </>
                      ) : (
                        <>
                          <br />
                          <b>No member assigned.</b>
                        </>
                      )}
                    </>
                  )}
                  <br />
                  <br />
                  <div id="ticket-properties" className="flex-horizon row">
                    <div className="status processing col-3">
                      <b>Status :</b>{" "}
                      <font>{capitalizeFirstLetter(ticket.status)}</font>
                    </div>
                    <div className="priority high col-3">
                      <b>Priority :</b>{" "}
                      <font>{capitalizeFirstLetter(ticket.priority)}</font>
                    </div>
                    <div className="type col-3">
                      <b>Type :</b> <font>{ticket.type}</font>
                    </div>
                    <div className="date-created processing col-3">
                      <b>Date created :</b>{" "}
                      <font>
                        {new Date(ticket.date_created).toLocaleDateString(
                          "en-US",
                          { month: "short", day: "numeric", year: "numeric" }
                        )}
                      </font>
                    </div>
                    <div className="due-date">
                      <b>Due date</b>:{" "}
                      {new Date(ticket.due_date).toLocaleDateString("en-US", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      })}
                    </div>
                  </div>
                </div>
                <div id="ticket-events" className="medium">
                  <div className="container">
                    <div className="top">
                      <b>Comments</b>
                    </div>
                    <div
                      className="medium"
                      style={{ height: "360px", maxHeight: "360px" }}
                    >
                      <div className="container" style={{ padding: 0 }}>
                        {comments.length < 1 ? (
                          <small style={{ margin: "1rem" }}>
                            No comments yet.
                          </small>
                        ) : (
                          <></>
                        )}
                        <ul id="comments-list">
                          {comments.map((item, index) => (
                            <li
                              className="event"
                              style={{
                                padding: "0 1rem",
                                borderBottom: "1px solid #efefef",
                              }}
                            >
                              <div className="top">
                                <b style={{ fontSize: ".85rem" }}>
                                  {item.member.name}
                                </b>
                                <br />
                                <i style={{ fontSize: ".8rem" }}>
                                  {showTime(item.time)}
                                </i>
                              </div>
                              <br />
                              <div className="middle">
                                <small
                                  dangerouslySetInnerHTML={{
                                    __html: Variables.updateUrlsInHtml(
                                      item.comment
                                    ),
                                  }}
                                ></small>
                              </div>
                              <br />
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="bottom">
                      <form
                        id="add-ticket-comment-form"
                        onSubmit={createTicketEvent}
                        className="reply"
                        style={{ padding: 0 }}
                      >
                        <textarea
                          id="new-ticket-event"
                          className="form-control"
                          placeholder="Add comment"
                          required
                        ></textarea>
                        <button
                          id="add-ticket-comment-btn"
                          type="submit"
                          class="round-button"
                          form="add-ticket-comment-form"
                        >
                          + Add comment
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-4 right"
              style={{ maxHeight: "630px", overflowY: "scroll" }}
            >
              {is_member == "false" || you ? (
                <>
                  <div className="top">
                    <select
                      className="ticket-action form-select form-select-lg mb-3"
                      style={{
                        fontSize: "1.1rem",
                        padding: "1rem",
                        fontWeight: "bold",
                      }}
                      id="update-ticket"
                      onChange={(e) => {
                        setTicketFieldToUpdate(e.target.value);
                        handleShowUpdateTicket();
                        e.target.value = "";
                      }}
                    >
                      <option value="">Ticket action</option>
                      <option value="priority">Change priority</option>
                      <option value="status">Change status</option>
                      <option value="type">Change type</option>
                      <option value="due_date">Due date</option>
                    </select>
                  </div>
                </>
              ) : (
                <></>
              )}
              {conversation ? (
                <>
                  <div className="middle" id="visitor-info">
                    <p style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                      Visitor's info
                    </p>
                    <div className="info">
                      <div>
                        <font>Email </font>
                        <b>{conversation.email?<>{conversation.email}</>:<>Unknown</>}</b>
                      </div>
                      <div>
                        <font>Phone </font>
                        <b>{conversation.phone?<>{conversation.phone}</>:<>Unknown</>}</b>
                      </div>
                      <div>
                        <font>Location </font>
                        <b>{conversation.location?<>{conversation.location}</>:<>Unknown</>}</b>
                      </div>
                      <div>
                        <font>Language </font>
                        <b>{conversation.language?<>{conversation.language}</>:<>Unknown</>}</b>
                      </div>
                      <div>
                        <font>Conversation ID </font>
                        <b style={{textAlign:"right"}}>
                          <b>{conversation.uid}</b>
                        </b>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="bottom" style={{ width: "100%" }}>
                    <button
                      className="btn theme-btn"
                      style={{
                        width: "100%",
                        padding: ".5rem",
                        display: "flex",
                        justifyContent: "center",
                        background: "#f0f0f0",
                      }}
                      onClick={handleComposeMailShow}
                    >
                      <img src={mailIcon} height={20} />
                      &nbsp;Send a mail
                    </button>
                  </div>
                  <br />
                </>
              ) : (
                <></>
              )}
              <div className="ticket-body">
                <h5>Ticket body</h5>
                <p>{ticket.body}</p>
              </div>
              <br />
              <div className="tags">
                <h5>Tags</h5>
                <div
                  className="all-tags"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  {ticketTags.map((item, index) => (
                    <>
                      <div
                        className="flex-horizon tag"
                        style={{ alignItems: "center",fontSize:".85rem" }}
                      >
                        <b>{item.name}</b>
                        <b
                          tagName={item.name}
                          style={{ cursor: "pointer" }}
                          onClick={removeTag}
                        >
                          &#x292B;
                        </b>
                      </div>
                    </>
                  ))}
                </div>

                <form id="add-tag-form" onSubmit={addTag}>
                  <input
                    className="form-control round-btn btn-sm"
                    placeholder="Add a tag"
                    onChange={relatedTags}
                    id="tag-input"
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                  />
                  {showTags && (
                    <ul
                      className="limit-text recommender"
                      id="tags-recommender"
                    >
                      {filteredTags.map((tag) => (
                        <li key={tag.name}>{tag.name}</li>
                      ))}
                    </ul>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {show && (
        <div
          className="offcanvas-backdrop fade show"
          onClick={handleClose}
        ></div>
      )}
      <UpdateTicket
        show={showUpdateTicket}
        handleClose={handleCloseUpdateTicket}
        id={id}
        field={ticketFieldToUpdate}
        allTickets={allTickets}
        setAllTickets={setAllTickets}
        ticket={ticket}
        setTicket={setTicket}
        comments={comments}
        setComments={setComments}
      />
      <ChangeTicketAssignment
        show={showUpdateAssignment}
        handleClose={handleCloseUpdateAssignment}
        id={id}
        setAssignedToName={setAssignedToName}
        setAssignedToEmail={setAssignedToEmail}
        setComments={setComments}
        comments={comments}
      />
      <ComposeMail show={composeMailShow} setShow={setComposeMailShow} data={{fromEmail:emails,toEmail:(conversation.email ? conversation.email : ""),subject:`Re: [Ticket #${id}] ${ticket.subject}`}}/>
    </>
  );
}

export default Ticket;
