import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "../css/NewBot.css";
import Variables from "../variables";
import axios from "axios";
import { useState } from "react";

function EditMember({ show, handleClose, uid }) {
  const [password,setPassword]=useState()
  const submitForm=(e)=>{
    e.preventDefault();
    let req_data = Variables.create_req_data({action: "change-password",uid:uid, password:password});
    Variables.loading_btn("#edit-member-btn","Changing")
    axios.post(Variables.serverUrl("members/"),req_data).then((res)=>{
      if(res.status==200){
        Variables.unloading_btn("#edit-member-btn")
        handleClose()
      }
    })
  }
  return (
    <>
      <Modal
        id="edit-member"
        show={show}
        onHide={handleClose}
        backdrop="static"
        fullscreen="md-down"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Change password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submitForm} id="change-password-form">
            <label>Change password</label>
            <input type="password" minLength={8} className="form-control" onChange={(e) => setPassword(e.target.value)} required />
            <div class="form-text">At least 8 charecters</div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button type="submit" form="change-password-form" id="edit-member-btn" className="theme-btn" variant="primary">
            Change password
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditMember;
