import visa from "../images/visa.svg";
import debitCard from "../images/debit-card.svg";
import mastercard from "../images/mastercard.svg";
import discover from "../images/discover.svg";
import jcb from "../images/jcb.svg";
import diners_club from "../images/diners_club.svg";
import american_express from "../images/american_express.svg";
import Variables from "../variables";
import { useEffect, useState } from "react";
import AddPaymentMethod from "../modals/AddPaymentMethod";
import $ from "jquery";
import axios from "axios";

function convertUnixTimestampToDate(timestamp) {
  let date = new Date(timestamp * 1000);

  // Options for formatting the date
  let options = {
    year: "numeric",
    month: "short", // Abbreviated month name (e.g., "Jul")
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true, // 12-hour format
    timeZone: "UTC", // Convert to UTC time zone
  };

  // Format the date using options
  let formattedDate = date.toLocaleString("en-US", options);

  return formattedDate;
}

const card_brand_images = {
  visa: visa,
  mastercard: mastercard,
  american_express: american_express,
  discover: discover,
  jcb: jcb,
  diners_club: diners_club,
  unionpay: debitCard,
};

export default function Billings({ methods, setMethods, unpaidInvoices }) {
  const [addPaymentMethodShow, setAddPaymentMethodShow] = useState(false);
  const handleClosePaymentMethod = () => setAddPaymentMethodShow(false);
  useEffect(() => {
    console.log(unpaidInvoices);
  }, [unpaidInvoices]);
  const markDefault = (data) => {
    let id = data.id;
    let req_data = Variables.create_req_data({
      action: "set-default",
      id: data.id,
    });
    $(data.e).hide();
    axios
      .post(Variables.serverUrl("payment-method/"), req_data)
      .then((res) => {
        if (res.status == 200) {
          $(data.e).show();
          setMethods(
            methods.map((method) => ({
              ...method,
              is_default: method.payment_method_id === id,
            }))
          );
        }
      })
      .catch((e) => {
        $(data.e).show();
      });
  };
  const handleRemovePaymentMethod = (data) => {
    $(data.e).hide();
    let req_data = Variables.create_req_data({
      action: "remove",
      id: data.id,
    });
    axios
      .post(Variables.serverUrl("payment-method/"), req_data)
      .then((res) => {
        if (res.status == 200) {
          setMethods((methods) =>
            methods.filter((method) => method.payment_method_id !== data.id)
          );
        }
      })
      .catch((e) => {
        $(data.e).show();
      });
  };
  return (
    <>
      <div class="title">
        <h4>Billing</h4>
      </div>
      <br />
      <div>
        <div className="container">
          {unpaidInvoices.length ? (
            <>
              <h5 style={{ color: "#989898" }}>Your unpaid invoices</h5>
              <div id="invoices">
                <table class="table table-hover table-borderless">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">Amount</th>
                      <th scope="col">Paid</th>
                      <th scope="col">Created on</th>
                      <th scope="col">PDF</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {unpaidInvoices.length ? (
                      <>
                        {unpaidInvoices.map((item, index) => (
                          <>
                            <tr>
                              <th scope="row">
                                <b>
                                  {item.amount} ({item.currency})
                                </b>
                              </th>
                              <td className="limit-text">
                                <font>
                                  {item.amount_paid} ({item.currency})
                                </font>
                              </td>
                              <td className="flex-horizon">
                                {convertUnixTimestampToDate(item.created)}
                              </td>
                              <td>
                                <a href={item.invoice_url}>
                                  {" "}
                                  &#x2193;&nbsp;Download
                                </a>
                              </td>
                              <td>
                                <button
                                  className="btn btn-success btn-sm round-btn"
                                  onClick={()=>window.open(item.payment_url, '_blank')}
                                >
                                  Pay &#x2192;
                                </button>
                              </td>
                            </tr>
                          </>
                        ))}
                      </>
                    ) : (
                      <>
                        <p>No invoices.</p>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <></>
          )}
          <br/>
          <h6>Your payment methods</h6>
          <div className="row">
            {methods.map((method, index) => {
              return (
                <div
                  className="col-4 card"
                  style={{ width: "fit-content", margin: ".7rem .5rem" }}
                >
                  <div
                    className="card-body flex-horizon"
                    style={{ padding: ".5rem .3rem !important" }}
                  >
                    <div className="left" style={{ alignItems: "center" }}>
                      {card_brand_images[method.card_type] ? (
                        <>
                          <img
                            src={card_brand_images[method.card_type]}
                            height={65}
                          />
                        </>
                      ) : (
                        <>
                          <img src={debitCard} height={65} />
                        </>
                      )}
                    </div>
                    &nbsp;&nbsp;
                    <div className="right" style={{ textAlign: "right" }}>
                      <h5>
                        <b>....</b>&nbsp;&nbsp;{method.card_last4}
                      </h5>
                      <b className="form-text">
                        Validity: {method.card_exp_month}/{method.card_exp_year}
                      </b>
                    </div>
                  </div>
                  <div
                    className="flex-horizon"
                    style={{ justifyContent: "space-between" }}
                  >
                    {method.is_default ? (
                      <>
                        <b
                          className="form-text"
                          style={{
                            margin: ".3rem",
                            fontWeight: "bolder",
                            background: "#d8ffd8",
                            padding: ".2rem .5rem",
                            borderRadius: "40px",
                          }}
                        >
                          Default
                        </b>
                      </>
                    ) : (
                      <>
                        <b
                          className="form-text"
                          style={{
                            margin: ".3rem",
                            fontWeight: "bolder",
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onClick={(e) =>
                            markDefault({
                              id: method.payment_method_id,
                              e: e.target,
                            })
                          }
                        >
                          Mark as default
                        </b>
                      </>
                    )}
                    {methods.length > 1 ? (
                      <>
                        <b
                          style={{
                            fontSize: ".95rem",
                            color: "#d2142a",
                            cursor: "pointer",
                          }}
                          onClick={(e) =>
                            handleRemovePaymentMethod({
                              id: method.payment_method_id,
                              e: e.target,
                            })
                          }
                        >
                          &#x292B; Remove
                        </b>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              );
            })}
            <div
              className="card flex-horizon col-4"
              onClick={() => setAddPaymentMethodShow(true)}
              style={{
                width: "150px",
                height: "150px",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                margin: "auto .5rem",
                cursor: "pointer",
              }}
            >
              <div className="card-body" style={{ padding: ".5rem" }}>
                <h1>+</h1>
                <small style={{ fontSize: ".8rem" }}>
                  Add new payment method
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <AddPaymentMethod
        handleClose={handleClosePaymentMethod}
        show={addPaymentMethodShow}
        setMethods={setMethods}
      />
    </>
  );
}
