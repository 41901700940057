import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Variables from "../variables";
import $ from "jquery";
import axios from "axios";
import { useState } from "react";

function IntentModal({ show, handleClose, modalActionType, id }) {
  let botID = "";
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const extracted_id = params.get("id");
  const [forms, setForms] = useState([]);
  botID = extracted_id;
  function delete_btn(e) {
    Variables.loading_btn("#create-intent-bot-btn",'Deleting')
    let req_data=Variables.create_req_data({
      action:'delete',
      intent_id:$(e.target).attr('uid')
    })
    axios.post(Variables.serverUrl("intent/"), req_data).then((res) => {
      window.location.reload();
    });
    handleClose();
  }
  function save_btn(e) {
    Variables.loading_btn(e.target, "Creating");
    let name = $("#new-intent-name").val();
    let description = $("#new-intent-description").val();
    let code = $("#new-intent-code").val();
    let req_data = Variables.create_req_data({
      action: "create",
      name: name,
      description: description,
      intent: code,
      bot_id:botID
    })
    if (name != "" && description != "" && code != "") {
      axios.post(Variables.serverUrl("intent/"), req_data).then((res) => {
        if(res.data.status=='err'){
          $("#create-intent-bot-btn").hide()
          $(".modal-body").html(`<b>${res.data.msg}</b>`)
        }else{
          window.location.reload();
        }
      });
    }
  }
 
  if (modalActionType == "Create") {
    return (
      <>
        <Modal
          id="edit-btn"
          show={show}
          onHide={handleClose}
          backdrop="static"
          fullscreen="md-down"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{modalActionType} intent</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label>Name your intent</label>
              <input
                id="new-intent-name"
                className="form-control"
                maxLength={200}
              />
              <small style={{ color: "grey" }}>
                Max. length is 200 letters
              </small>
              <br />
              <br />
              <label>Description</label>
              <textarea
                id="new-intent-description"
                className="form-control"
                placeholder="Description"
              ></textarea>
              <br />
              <label>Intent</label>
              <input
                id="new-intent-code"
                className="form-control"
                maxLength={200}
                placeholder="ex: BookAppointment"
              />
              <small style={{ color: "grey" }}>
                Max. length is 200 letters
              </small>
              <br />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              id="create-intent-bot-btn"
              className="theme-btn"
              variant="primary"
              onClick={save_btn}
            >
              {modalActionType}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  } else {
    return (
      <>
        <Modal
          id="edit-btn"
          show={show}
          onHide={handleClose}
          backdrop="static"
          fullscreen="md-down"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{modalActionType} intent</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>Are you sure that you want to delete the intent.</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              id="create-intent-bot-btn"
              className="theme-btn"
              variant="primary"
              uid={id}
              onClick={delete_btn}
            >
              {modalActionType}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default IntentModal;
