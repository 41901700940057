import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import Variables from "../variables";

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    Variables.loading_btn("#add-payment-method-btn","Submitting")

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const hostname = window.location.hostname;

    const result = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "http://"+hostname+"/app/profile",
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      Variables.unloading_btn("#add-payment-method-btn")
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <br />
      <button id="add-payment-method-btn" className="btn btn-success round-btn">Submit</button>
    </form>
  );
};

export default CheckoutForm;
