import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "../css/NewBot.css";
import Variables from "../variables";
import axios from "axios";
import $ from "jquery";
import { useEffect, useState } from "react";

function ChangeTicketAssignment({ show, handleClose, id, setAssignedToName, setAssignedToEmail, setComments, comments }) {
  const [assignedTo, setAssignedTo] = useState();
  const [allMembers, setAllMembers] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [showMembers, setShowMembers] = useState("none");
  const [showGroups, setShowGroups] = useState("none");

  const assignmentChanged = (e) => {
    let assignment = e.target.value;
    setAssignedTo(e.target.value);
    $("button").prop("disabled", true);
    if (assignment == "member") {
      let req_data = Variables.create_req_data({
        action: "get-all",
      });
      if (!allMembers.length) {
        let members = axios
          .post(Variables.serverUrl("members/"), req_data)
          .then((res) => {
            if (res.status == 200) {
              setAllMembers(res.data.members);
              setShowMembers("block");
              setShowGroups("none");
            }
          });
      } else {
        setShowMembers("block");
        setShowGroups("none");
      }
    } else if (assignment == "group") {
      let req_data = Variables.create_req_data({
        action: "get-all",
      });
      if (!allGroups.length) {
        let groups = axios
          .post(Variables.serverUrl("groups/"), req_data)
          .then((res) => {
            if (res.status == 200) {
              setAllGroups(res.data.groups);
              setShowGroups("block");
              setShowMembers("none");
            }
          });
      } else {
        setShowGroups("block");
        setShowMembers("none");
      }
    }
    $("button,input[type='radio']").prop("disabled", false);
  };
  const submitForm = (e) => {
    e.preventDefault();
    if (!assignedTo) {
      return null;
    }
    let assignmentID = "";
    if (assignedTo == "group") {
      assignmentID = $("#assign-to-group").val();
    } else if (assignedTo == "member") {
      assignmentID = $("#assign-to-member").val();
    }
    if (!assignmentID || assignmentID == "") {
      return null; 
    }
    
    let req_data=Variables.create_req_data({uid:id,action:"update-assignment",assigned_to_id:assignmentID,assigned_to:assignedTo})
    console.log(req_data)
    Variables.loading_btn("#create-groups-btn","Creating")
    axios.post(Variables.serverUrl("tickets/"),req_data).then((res)=>{
      if(res.status==200){
        let data=res.data
        if(data.data.type=="member"){
            setAssignedToName(`Member (${data.data.name})`)
            setAssignedToEmail(data.data.email)
        }else if(data.data.type=="group"){
            setAssignedToName(`Group (${data.data.name})`)
            setAssignedToEmail("")
        }
        setComments([...comments,res.data.comment])
      }
      Variables.unloading_btn("#change-ticket-assignment-btn")
      handleClose()
      setShowMembers("none")
      setShowGroups("none")
    })
  };
  return (
    <>
      <Modal
        id="change-ticket-assignment"
        show={show}
        onHide={handleClose}
        backdrop="static"
        fullscreen="md-down"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Change ticket assignment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="change-ticket-assignment-form" onSubmit={submitForm}>
            <label>Assign to</label>
            <ul className="flex-horizon" style={{ listStyle: "none" }}>
              <li>
                <input
                  className="form-check-input"
                  type="radio"
                  name="assign_to"
                  value="group"
                  onChange={assignmentChanged}
                />
                &nbsp;<label>Group</label>
              </li>
              &nbsp;&nbsp;
              <li>
                <input
                  className="form-check-input"
                  type="radio"
                  name="assign_to"
                  value="member"
                  onChange={assignmentChanged}
                />
                &nbsp;<label>Member</label>
              </li>
            </ul>
            <select
              id="assign-to-group"
              className="form-select"
              style={{ display: showGroups }}
            >
              {allGroups.map((item, index) => (
                <option value={item.uid}>{item.name}</option>
              ))}
            </select>
            <select
              id="assign-to-member"
              className="form-select"
              style={{ display: showMembers }}
            >
              {allMembers.map((item, index) => (
                <option value={item.uid}>
                  {item.name} ({item.email})
                </option>
              ))}
            </select>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{handleClose();setShowMembers("none");setShowGroups("none")}}>
            Close
          </Button>
          <Button
            id="change-ticket-assignment-btn"
            className="theme-btn"
            variant="primary"
            type="submit"
            form="change-ticket-assignment-form"
          >
            Assign
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ChangeTicketAssignment;
