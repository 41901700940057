import { useEffect, useState } from "react";
import attachment from "../images/attachment.png";
import messagesCardIcons from "../images/message-card.svg";
import formImg from "../images/form.png";
import "../css/livechat.css";

function getTimeAgo(timestamp) {
  const utcDate = new Date(timestamp);
  const localDate = new Date(
    utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
  );

  const currentTime = new Date();

  const timeDifference = currentTime - localDate;

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  const options = { year: "numeric", month: "short", day: "numeric" };
  const formattedDate = localDate.toLocaleDateString(undefined, options);

  if (seconds < 60) {
    return `${seconds} seconds ago`;
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (hours < 24) {
    return `${hours} hours ago`;
  } else if (localDate.toDateString() === currentTime.toDateString()) {
    return "Today";
  } else {
    return formattedDate;
  }
}

function convertLinksToHTML(text) {
  const linkRegex = /(\bhttps?:\/\/[^\s]+)/gi;

  const htmlText = text.replace(
    linkRegex,
    '<a href="$1" style="color:black;" target="_blank">$1</a>'
  );

  return htmlText;
}

function Section2({ conversation }) {
  const [messages, setMessages] = useState([]);
  useEffect(() => {
    let tmp_msg_lst = [];
    if (conversation.messages && typeof conversation.messages !== "object") {
      conversation.messages = JSON.parse(conversation.messages);
    }
    let msgs = conversation.messages;
    if(typeof msgs=='string'){
      try{
        msgs=JSON.parse(msgs)
      }catch(e){
        msgs=[]
      }
    }
    if (msgs) {
      msgs.forEach((item) => {
        if (item.message_type == "group") {
          Object.keys(item.message).forEach((k) => {
            if (k == "buttons") {
              let x = { ...item };
              x.message = item.message[k];
              x.message_type = k;
              tmp_msg_lst.push(x);
            }
            if (k == "cards") {
              let x = { ...item };
              x.message = item.message[k];
              x.message_type = k;
              tmp_msg_lst.push(x);
            }
            if (k == "text") {
              let x = { ...item };
              x.message = item.message["response"];
              x.message_type = k;
              tmp_msg_lst.push(x);
            }
          });
        } else {
          tmp_msg_lst.push(item);
        }
      });
    }

    if (tmp_msg_lst) {
      setMessages(tmp_msg_lst);
    }
  }, [conversation]);
  return (
    <div
      className="right col-lg-5 col-md-5 col-sm-5"
      style={{ borderRight: "1px solid rgb(205, 205, 205)" }}
    >
      {messages.length ? (
        <>
          <div id="conversation">
            <div className="container">
              <ul>
                {messages ? (
                  <>
                    <div className="container">
                      <ul className="messages">
                        {messages.map((message, index) => {
                          return (
                            <>
                              {message.message_type == "text" ? (
                                <>
                                  <li
                                    className={
                                      message.sent_by !== "user" &&
                                      message.sent_by !== "bot"
                                        ? "bot" + "-message message"
                                        : message.sent_by + "-message message"
                                    }
                                  >
                                    <div style={{ maxWidth: "70%" }}>
                                      <b
                                        style={{
                                          fontSize: ".8rem",
                                          color: "grey",
                                        }}
                                      >
                                        {message.sent_by}
                                      </b>
                                      <div className="text">
                                        <font
                                          dangerouslySetInnerHTML={{
                                            __html: convertLinksToHTML(
                                              message.message
                                            ),
                                          }}
                                        ></font>
                                      </div>
                                      <small
                                        className="form-text"
                                        style={{
                                          fontSize: ".7rem",
                                          margin: "auto 1rem",
                                        }}
                                      >
                                        <i>{getTimeAgo(message.time)}</i>
                                      </small>
                                    </div>
                                  </li>
                                </>
                              ) : (
                                <></>
                              )}
                              {message.message_type == "cards" ? (
                                <>
                                  <li
                                    className={
                                      message.sent_by !== "user" &&
                                      message.sent_by !== "bot"
                                        ? "bot" + "-message message"
                                        : message.sent_by + "-message message"
                                    }
                                  >
                                    <div style={{ maxWidth: "70%" }}>
                                      <b
                                        style={{
                                          fontSize: ".8rem",
                                          color: "grey",
                                        }}
                                      >
                                        {message.sent_by}
                                      </b>
                                      <ul className="cards">
                                        {message.message.map((card) => (
                                          <>
                                            <li className="card">
                                              <img src={card["img-url"]} />
                                              <h6>{card.title}</h6>
                                              <p>{card.description}</p>
                                              <button className="btn btn-sm theme-btn">
                                                {card["button-name"]}
                                              </button>
                                            </li>
                                          </>
                                        ))}
                                      </ul>
                                      <small
                                        className="form-text"
                                        style={{
                                          fontSize: ".7rem",
                                          margin: "auto 1rem",
                                        }}
                                      >
                                        <i>{getTimeAgo(message.time)}</i>
                                      </small>
                                    </div>
                                  </li>
                                </>
                              ) : (
                                <></>
                              )}
                              {message.message_type == "buttons" ? (
                                <>
                                  <li
                                    className={
                                      message.sent_by !== "user" &&
                                      message.sent_by !== "bot"
                                        ? "bot" + "-message message"
                                        : message.sent_by + "-message message"
                                    }
                                  >
                                    <div style={{ maxWidth: "70%" }}>
                                      <b
                                        style={{
                                          fontSize: ".8rem",
                                          color: "grey",
                                        }}
                                      >
                                        {message.sent_by}
                                      </b>
                                      <ul className="list-style">
                                        {message.message.map((button) => (
                                          <>
                                            <li className="button">
                                              <button className="btn btn-primary round-btn btn-sm">
                                                {button.name}
                                              </button>
                                            </li>
                                          </>
                                        ))}
                                      </ul>
                                      <small
                                        className="form-text"
                                        style={{
                                          fontSize: ".7rem",
                                          margin: "auto 1rem",
                                        }}
                                      >
                                        <i>{getTimeAgo(message.time)}</i>
                                      </small>
                                    </div>
                                  </li>
                                </>
                              ) : (
                                <></>
                              )}
                              {message.message_type == "form" ? (
                                <>
                                  <li
                                    className={
                                      message.sent_by !== "user" &&
                                      message.sent_by !== "bot"
                                        ? "bot" + "-message message"
                                        : message.sent_by + "-message message"
                                    }
                                  >
                                    <div style={{ maxWidth: "70%" }}>
                                      <b
                                        style={{
                                          fontSize: "0.8rem",
                                          color: "grey;",
                                        }}
                                      >
                                        {message.sent_by} (
                                        <img src={formImg} height={10} />
                                        <font>Form</font>)
                                      </b>
                                      <div
                                        className="text"
                                        style={{ padding: "10px" }}
                                      >
                                        <b>Form: </b>
                                        <a
                                          href={
                                            "/app/form-builder?id=" +
                                            message.message.uid
                                          }
                                          target="_blank"
                                        >
                                          {message.message.title}
                                        </a>
                                      </div>
                                      <small
                                        className="form-text"
                                        style={{
                                          fontSize: ".7rem",
                                          margin: "auto 1rem",
                                        }}
                                      >
                                        <i>{getTimeAgo(message.time)}</i>
                                      </small>
                                    </div>
                                  </li>
                                </>
                              ) : (
                                <></>
                              )}
                              {message.message_type == "file" ? (
                                <>
                                  <li
                                    className={
                                      message.sent_by !== "user" &&
                                      message.sent_by !== "bot"
                                        ? "bot" + "-message message"
                                        : message.sent_by + "-message message"
                                    }
                                  >
                                    <div style={{ maxWidth: "70%" }}>
                                      <b
                                        style={{
                                          fontSize: ".8rem",
                                          color: "grey",
                                        }}
                                      >
                                        {message.sent_by} (
                                        <img src={attachment} height="10px" />
                                        <font>Attachment</font>)
                                      </b>
                                      <div
                                        className="text"
                                        style={{
                                          display: "flex",
                                          "flex-direction": "column",
                                          alignItems: "end",
                                        }}
                                      >
                                        <a
                                          href="http://google.com"
                                          style={{ color: "black" }}
                                          target="_blank"
                                        >
                                          File: ${message.message.name}
                                        </a>
                                        <i
                                          style={{
                                            "font-size": ".7rem",
                                            "margin-bottom": "10px",
                                            color: "grey",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {message.message.type}{" "}
                                          <font>${message.message.size}</font>
                                          <br />
                                        </i>
                                      </div>
                                      <small
                                        className="form-text"
                                        style={{
                                          fontSize: ".7rem",
                                          margin: "auto 1rem",
                                        }}
                                      >
                                        <i>{getTimeAgo(message.time)}</i>
                                      </small>
                                    </div>
                                  </li>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          );
                        })}
                      </ul>
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      height: "70vh",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h6>No conversations yet.</h6>
                  </div>
                )}
              </ul>
            </div>
          </div>
        </>
      ) : (
        <div
          className="flex-horizon"
          style={{
            justifyContent: "center",
            alignItems: "center",
            height: "500px",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <img
            style={{
              opacity: ".5",
            }}
            height={200}
            wist
            src={messagesCardIcons}
          />
          <b style={{ fontSize: "1.2rem", opacity: ".5" }}>No messages</b>
        </div>
      )}
    </div>
  );
}

export default Section2;
