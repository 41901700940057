import { useEffect, useState } from "react";
import Section1 from "./FormSubmission/Section1";
import Section2 from "./FormSubmission/Section2";
import "./css/FormSubmission.css";
import Header from "./Header";
import Leftnav from "./Leftnav";
import Variables from "./variables";

function FormSubmission() {
  let formID=useState('')
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const [formName,setFormName]=useState("")
  const extracted_id = params.get("id");
  formID=extracted_id
  const [data,setData]=useState([])

  useEffect(() => {
    async function checkAuth() {
      let status = await Variables.checkAuthentication()
    }
    checkAuth.call()
    const leftNavLinks = document.getElementsByClassName("left-nav-link");
    for (let i = 0; i < leftNavLinks.length; i++) {
      leftNavLinks[i].classList.remove("active");
    }
    document.getElementsByClassName("forms-link")[0].classList.add("active");
  },[]);
  return (
    <div className="App">
      <Leftnav />
      <div className="App-body">
        <header className="sticky-top">
          <Header />
        </header>
        <div id="form-submissions" uid={""}>
          <Section1 id={formID} formName={formName} />
          <Section2 id={formID} data={setData} setFormName={setFormName} />
        </div>
      </div>
    </div>
  );
}

export default FormSubmission;
