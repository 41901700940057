import { Link } from "react-router-dom";
import "./css/Verify.css";
import passwordIcon from "./images/password.svg";
import { useEffect } from "react";
import Variables from "./variables";
import axios from "axios";
import $ from "jquery"

function ResetPassword() {
  let token = "";
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const extracted_id = params.get("token");
  token = extracted_id;
  useEffect(() => {
    if(token){
        $("#reset").show()
        $("#forgot").hide()
    }
    $("#forgot").on('submit',(e)=>{
        e.preventDefault()
        Variables.loading_btn(".submit-btn",'Resetting')
        let email=$("#email").val()
        let req_data={
            action:'forgot',
            email:email
        }
        console.log(req_data)
        axios.post(Variables.serverUrl("reset-password/"),req_data)
        .then((res)=>{
            $('#forgot').html(res.data.msg)
        })
    })
    $("#reset").on('submit',(e)=>{
        e.preventDefault()
        Variables.loading_btn(".submit-btn",'Changing')
        let password1=$("#password1").val()
        let password2=$("#password2").val()
        let req_data={
            action:'reset',
            password1:password1,
            password2:password2,
            token:token
        }
        console.log(req_data)
        axios.post(Variables.serverUrl("reset-password/"),req_data)
        .then((res)=>{
            if(res.data.status=='err'){
                $('#err-msg').html(res.data.msg)
                $('#err-msg').show()
            }else if(res.data.status=='success'){
                $("#reset").html(res.data.msg)
                $('#err-msg').hide()
            }
            Variables.unloading_btn(".submit-btn")
        })
    })
  }, []);
  return (
    <>
      <div id="reset-password">
        <div className="container">
          <div className="card">
            <div className="card-body" style={{ textAlign: "center" }}>
              <img src={passwordIcon} width={150} />
              <br />
              <br />
              <form
                id="forgot"
                style={{ textAlign: "center", margin: "auto", width:'300px' }}
              >
                <h4>Reset password</h4>
                <p>
                  Enter your registered email address.
                </p>
                <input id="email" type="email" placeholder="Your email" className="form-control"/>
                <br/>
                <button type="submit" className="submit-btn btn btn-dark round">
                  Reset &#x2192;
                </button>
                <br/><br/>
              </form>
              <form
                id="reset"
                style={{ textAlign: "center", margin: "auto",display:'none'}}
              >
                <h4 >
                  Enter new password
                </h4>
                <p>
                  Make sure that your password is strong.
                </p>
                <input className="form-control" placeholder="New password" type="password" id="password1" minLength={8} required/>
                <br/>
                <input className="form-control" placeholder="Confirm password" type="password" id="password2" minLength={8} required/>
                <br/>
                <button to="/login" className="submit-btn btn btn-dark round">
                  Set password &#x2192;
                </button>
                <br/>
                <small id="err-msg" style={{display:'none'}}></small>
                <br/>
                <br />
              </form>
              <Link style={{color:'#000',fontWeight:'bold'}} to="/login">Login &#x2192;</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
