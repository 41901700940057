import "./css/login.css";
import bot_banner from "./images/robot-banner.png";
import logo from "./images/sharurai-logo-2.png";
import Variables from "./variables";
import $ from "jquery";
import { React, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

let loginStatus=false;

const fn = {
  RequestLogin: async function (data) {
    let req_data = {
      username: data.user,
      password: data.password,
    };
    Variables.loading_btn("#login-btn", "Loggin in");
    await axios.post(Variables.serverUrl("token/"), req_data).then(
      (res) => {
        try {
          localStorage.setItem("sharurai_access_key", res.data.access)
          localStorage.setItem("sharurai_refresh_key", res.data.refresh)
          localStorage.setItem("sharurai_member_login", false);
          loginStatus = true;
        } catch (e) {
          Variables.unloading_btn("#login-btn");
          $("#msg").html("Something went wrong. Please try again.");
          $("#msg").show();
        }
      },
      (err) => {
        if (err.response.status) {
          Variables.unloading_btn("#login-btn");
          $("#msg").html("Wrong username or password");
          $("#msg").show();
        }
      }
    );
    return loginStatus;
  },

  AttemptLogin: async function (data) {
    if (data.type == "google") {
      //pass
    } else if (data.type == "email") {
      let user = $("#username").val();
      let password = $("#password").val();
      let res = await fn.RequestLogin({ user: user, password: password })
      return res;
    }
  },
};

function Login() {
  let navigate = new useNavigate()
  useEffect(() => {
    if(localStorage.getItem("sharurai_access_key")!=null){
      // navigate("/app/bots")
      window.location.href = "/app/bots";
    }
    $("#login-form").on("submit", async function (event) {
      event.preventDefault();
      let res=await fn.AttemptLogin({ type: "email" });
      if(res){
        // navigate("/app/bots")
        window.location.href = "/app/bots";
      }
    });
  });

  return (
    <>
      <div id="login">
        <div className="container">
          <div className="card row">
            <div className="left col-lg-6 col-md-6 col-sm-12">
              <div className="login-banner">
                <img src={bot_banner} />
              </div>
            </div>
            <div className="right col-lg-6 col-md-6 col-sm-12">
              <div>
                <img
                  className="logo"
                  src={logo}
                  height={50}
                  style={{ width: "fit-content", margin: "auto" }}
                />
                <br />
                <br />
                <form id="login-form">
                  <h2>Login</h2>
                  <input
                    id="username"
                    className="round form-control"
                    type="text"
                    placeholder="Username"
                    required
                  />
                  <input
                    id="password"
                    className="round form-control"
                    type="password"
                    placeholder="Password"
                    required
                  />
                  <p
                    id="msg"
                    style={{
                      display: "none",
                      color: "red",
                      "font-size": ".8rem",
                    }}
                  ></p>
                  <button id="login-btn" className="round btn btn-dark">
                    Login
                  </button>
                </form>
                <br />
                <Link to="/app/login/member">Member login &#x2192;</Link>
                <Link to="/reset-password">Forgot password?</Link>
                <br />
                <Link
                  style={{ "margin-top": "5px" }}
                  className="round btn btn-dark"
                  to="/sign-up"
                >
                  Create a new account
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
