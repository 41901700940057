import React, { useEffect, useLayoutEffect, useState } from "react";
import "./css/Home.css";
import { Link } from "react-router-dom";
import Leftnav from "./Leftnav";
import Banner from "./banner";
import Header from "./Header";
import botIcon from "./images/bot.svg";
import "./css/bots.css";
import axios from "axios";
import Variables from "./variables";
import NewBot from "./modals/NewBot";
import deleteIcon from "./images/delete-light.svg";
import $, { type } from "jquery";
import DeleteBot from "./modals/deleteBot";
import MessageModal from "./modals/MessageModal";

let is_member=localStorage.getItem("sharurai_member_login");

function Bots() {
  const [bots, setBots] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedBotID, setSelectedBotID] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteModalClose = () => setShowDeleteModal(false);
  const [msgModalShow, setMsgModalShow] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const handleMsgModalClose = () => {
    setMsgModalShow(false);
  };
  const handleMsgModalShow = () => {
    setMsgModalShow(true);
  };

  function changeBotStatus(e) {
    $(e.target).prop('disabled',true)
    if ($(e.target).is(":checked")) {
      let req_data = Variables.create_req_data({
        action: "on",
        id: $(e.target).attr("uid"),
      });
      axios
        .post(Variables.serverUrl("change-bot-state/"), req_data)
        .then((res) => {
          $(e.target).prop('disabled',false)
          if (res.data.status == "success") {
            $(e.target).prop("checked", true);
          } else {
            setModalMsg(res.data.msg);
            handleMsgModalShow();
          }
        });
    } else {
      let req_data = Variables.create_req_data({
        action: "off",
        id: $(e.target).attr("uid"),
      });
      axios
        .post(Variables.serverUrl("change-bot-state/"), req_data)
        .then((res) => {
          $(e.target).prop('disabled',false)
          if (res.data.status == "success") {
            $(e.target).removeProp("checked");
            $(e.target).removeProp("checked");
          } else {
            setModalMsg(res.data.msg);
            handleMsgModalShow();
          }
        });
    }
    // $(ele).prop('disabled',true)
  }

  const handleDeleteModalShow = function (e) {
    let id = $(e.target).attr("uid");
    setSelectedBotID(id);
    setShowDeleteModal(true);
  };
  function get_bots() {
    let req_data = Variables.create_req_data({});
    axios.post(Variables.serverUrl("all-bots/"), req_data).then((res) => {
      if (res.data.status > 400) {
        if (res.data.relogin) {
          localStorage.removeItem("sharurai_access_key");
          localStorage.removeItem("sharurai_refresh_key");
          // window.location.href = "/login";
        }
      } else if (res.data.status >= 200) {
        setBots(res.data.bots);
      }
    });
  }
  useLayoutEffect(() => {
    async function checkAuth() {
      let status = await Variables.checkAuthentication();
    }
    checkAuth.call();
    const leftNavLinks = document.getElementsByClassName("left-nav-link");
    for (let i = 0; i < leftNavLinks.length; i++) {
      leftNavLinks[i].classList.remove("active");
    }
    document
      .getElementsByClassName("dashboard-link")[0]
      .classList.add("active");
  }, []);
  useEffect(() => {
    get_bots();
  }, []);
  return (
    <>
      <div className="App">
        <Leftnav />
        <div className="App-body">
          <header className="sticky-top">
            <Header />
          </header>
          <Banner />
          <div id="bots">
            <div className="container">
              <br />
              <h3>Your bots</h3>
              <br />
              <br />
              {(() => {
                if (bots.length > 0) {
                  return (
                    <div id="bots-list" className="row">
                      {bots.map((bot) => (
                        <div
                          id={bot.uid}
                          className="bot col-sm-12 col-md-4 col-md-4"
                        >
                          <div className="card">
                            <div class="form-check form-switch bot-status">
                              <label
                                class="form-check-label"
                                for={bot.uid + "_status"}
                              >
                                On/Off
                              </label>
                              {bot.live ? (
                                <input
                                  uid={bot.uid}
                                  class="form-check-input change-bot-status"
                                  onClick={changeBotStatus}
                                  type="checkbox"
                                  role="switch"
                                  id={bot.uid + "_status"}
                                  checked
                                />
                              ) : (
                                <input
                                  uid={bot.uid}
                                  class="form-check-input change-bot-status"
                                  onClick={changeBotStatus}
                                  type="checkbox"
                                  role="switch"
                                  id={bot.uid + "_status"}
                                />
                              )}
                            </div>
                            <small
                              className={"bot-type " + bot.type + "-bot-color"}
                            >
                              {Variables.getBotTypeName(bot.type)}
                            </small>
                            <div className="container">
                              <img className="bot-icon" src={botIcon} />
                              &nbsp;&nbsp;
                              <Link to={"/app/insights/?id=" + bot.uid}>
                                {bot.name} &#x2192;
                              </Link>
                              <small className="bot-description">
                                {bot.description}
                              </small>
                            </div>
                            <br />
                            <button
                              uid={bot.uid}
                              onClick={handleDeleteModalShow}
                              className="btn btn-sm btn-danger delete-bot"
                            >
                              <img src={deleteIcon} height={18} />
                              <b>&nbsp;Delete</b>
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  );
                } else {
                  return (
                    <>
                      <p style={{ color: "grey", "font-size": "1.1rem" }}>
                        You have no bots yet
                      </p>
                    </>
                  );
                }
                return null;
              })()}
              {is_member=='true' ?<></>: <button className="btn btn-dark" onClick={handleShow}>
                + Create new
              </button>}
              <NewBot show={show} handleClose={handleClose} />
              <DeleteBot
                show={showDeleteModal}
                handleClose={handleDeleteModalClose}
                data={{ id: selectedBotID }}
              />
              <MessageModal
                show={msgModalShow}
                handleClose={handleMsgModalClose}
                msg={modalMsg}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Bots;
