import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "../css/NewBot.css";
import Variables from "../variables";
import axios from "axios";
import $ from "jquery";
import { useState } from "react";

function CreateGroup({ show, handleClose, setGroupsList }) {
  const [name, setName] = useState();
  const [info, setInfo] = useState();
  const [error, setError] = useState("");
  const submitForm = (e) => {
    e.preventDefault();
    let req_data = Variables.create_req_data({
      action: "create",
      name: name,
      info: info,
    });
    Variables.loading_btn("#create-groups-btn", "Creating");
    axios.post(Variables.serverUrl("groups/"), req_data).then((res) => {
      if (res.status == 200 || res.status >= res.status) {
        let data = res.data;
        setGroupsList((prevList) => [
          ...prevList,
          {
            uid: data.uid,
            name: data.name,
            info: data.info,
          },
        ]);
      }
      Variables.unloading_btn("#create-groups-btn");
      handleClose();
    }).catch((err)=>{
      if(err.response.data.msg){
        setError(err.response.data.msg)
      }
    })
  };
  return (
    <>
      <Modal
        id="create-group"
        show={show}
        onHide={handleClose}
        backdrop="static"
        fullscreen="md-down"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error ? (
            <>
              <div
                class="alert alert-danger d-flex align-items-center"
                role="alert"
              >
                <div>{error}</div>
              </div>
            </>
          ) : (
            <></>
          )}
          <form id="create-group-form" onSubmit={submitForm}>
            <label>Name</label>
            <input
              className="form-control"
              onChange={(e) => setName(e.target.value)}
            />
            <label>Info</label>
            <textarea
              className="form-control"
              onChange={(e) => setInfo(e.target.value)}
            ></textarea>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            id="create-group-btn"
            className="theme-btn"
            variant="primary"
            type="submit"
            form="create-group-form"
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateGroup;
