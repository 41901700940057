import React from "react";
import { PieChart, Pie } from "recharts";

function HomeSection4({  analysis_data }) {
  const data = [
    {
      name: "Intentful",
      intents: analysis_data.total_intents_used,
      fill: "#57c0e8",
    },
    {
      name: "Unintentful",
      intents: analysis_data.total_msg - analysis_data.total_intents_used,
      fill: "#000",
    },
  ];
  return (
    <>
      <br />
      <br />
      <div id="intent" className="home-section homesection4">
        <div className="container row">
          <div id="intent-summary" className="col-lg-6 col-sm-12 col-md-12">
            <h4>Intent summary</h4>
            <small>
              The following chart shows the number of intentful or unintentful
              messages in the whole conversations.
            </small>
            <PieChart width={500} height={400}>
              <Pie
                data={data}
                dataKey="intents"
                outerRadius={80}
                innerRadius={40}
                fill="green"
                label={({
                  cx,
                  cy,
                  midAngle,
                  innerRadius,
                  outerRadius,
                  value,
                  index,
                }) => {
                  const RADIAN = Math.PI / 180;
                  // eslint-disable-next-line
                  const radius = 25 + innerRadius + (outerRadius - innerRadius);
                  // eslint-disable-next-line
                  const x = cx + radius * Math.cos(-midAngle * RADIAN);
                  // eslint-disable-next-line
                  const y = cy + radius * Math.sin(-midAngle * RADIAN);

                  return (
                    <text
                      x={x}
                      y={y}
                      fill="#8884d8"
                      textAnchor={x > cx ? "start" : "end"}
                      dominantBaseline="central"
                    >
                      {data[index].name} ({value})
                    </text>
                  );
                }}
              />
            </PieChart>
          </div>
          <div
            id="other-intents"
            style={{ width: "400px" }}
            className="card col-lg-6 col-sm-12 col-md-12"
          >
            <div className="card-title">
              <h5>User intents</h5>
              <br />
              <div className="scrollable">
                {Object.keys(analysis_data.intents).length ? (
                  <>
                    <table class="table">
                      <tbody>
                        {Object.keys(analysis_data.intents).map((obj) => {
                          return (
                            <tr>
                              <th scope="row">{obj}</th>
                              <td>{analysis_data.intents[obj]}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <div style={{height:"100%",width:"100%","display":"flex",justifyContent:"center",alignItems:"center",color:"grey",marginTop:"20px"}}><h6>No intents yet</h6></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeSection4;
