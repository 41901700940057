import CustomizationContent from "./BotInfoCustomization/Content"
import CustomizationPreview from "./BotInfoCustomization/Preview"
import "./css/BotInfoCustomization.css"
import { useState,useEffect } from "react"

function BotInfoCustomization() {
  const [eleHeight,setEleHeight]=useState(0)
  useEffect(() => {
    const links=document.getElementsByClassName('menu-link')
    for(let i=0;i<links.length;i++){
      links[i].classList.remove('active')
    }
    document.getElementsByClassName('bot-info-link')[0].classList.add('active')
    return document.ready = () => {
      const height=window.height-document.getElementById('navbar')+document.getElementById('home-menu')
      setEleHeight(height)
    }
  })
  return (
    <div style={{"max-height":eleHeight+'px'}} className="botinfo row" id="bot-info">
      <div className="col-4 overflow-auto">
        <CustomizationContent />
        <br/>
      </div>
      <div className="col-8">
        <CustomizationPreview />
      </div>
    </div>
  );
}

export default BotInfoCustomization;
