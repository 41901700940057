import React, { useEffect, useLayoutEffect, useState } from "react";
import HomeSection1 from "./HomeSection1";
import HomeSection2 from "./HomeSection2";
import HomeSection3 from "./HomeSection3";
import HomeSection4 from "./HomeSection4";
import { HomeMenu } from "./HomeMenu";
import Header from "../Header";
import Leftnav from "../Leftnav";
import Banner from "../banner";
import Variables from "../variables";
import axios from "axios";
import $ from 'jquery';

function Insights() {
  let botID
  const [bot,setBot]=useState('')
  const [avgQuery,setAvgQuery]=useState(0)
  const [avgDuration,setAvgDuration]=useState(0)
  const [showAnalysis,setShowAnalysis]=useState('none')
  const [activeUsers,setActiveUsers]=useState(0)
  const [total_intents_used,setTotal_intents_used]=useState(0)
  const [total_msg,setTotal_msg]=useState(0)
  const [intents,setIntents]=useState(0)
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const extracted_id = params.get("id");
  botID=extracted_id
  const [t_data,setT_data]=useState({"data":[],today:0,yesterday:0})
  useEffect(()=>{
    let req_data=Variables.create_req_data({id:botID})
    axios.post(Variables.serverUrl("get-analyzed-data/"),req_data).then(function(res){
      if(res.data.active){
        setShowAnalysis('flex')
        let data=res.data.data
        setAvgQuery(data.avg_query)
        setAvgDuration(data.avg_duration)
        setTotal_msg(data.total_msg)
        setTotal_intents_used(data.total_intents_used)
        setIntents(data.intents)
        setActiveUsers(data.active_users)
        let tData={"data":[],today:0,yesterday:0}
        Object.keys(data.conv_analysis).forEach(function(key) {
          let currentYear = new Date().getFullYear()
          let dateObject = new Date(`${key} ${currentYear}`).valueOf()
          let today = new Date().setHours(0,0,0,0)
          let previous_day=new Date(new Date(new Date().setDate(new Date().getDate()-1)).setHours(0,0,0,0)).valueOf()
          if(dateObject==today){
           tData["today"]=data.conv_analysis[key]
          }else if(dateObject==previous_day){
           tData["yesterday"]=data.conv_analysis[key]
          }  
         tData["data"].push({name:key,Conversations:data.conv_analysis[key]})
        })
        setT_data(tData)
      }else{
        setShowAnalysis('hide')
      }
    }).catch((e)=>{
      //pass
    })
    const links=document.getElementsByClassName('menu-link')
    for(let i=0;i<links.length;i++){
      links[i].classList.remove('active')
    }
    document.getElementsByClassName('insight-link')[0].classList.add('active')
  },[])
  useLayoutEffect(() => {
    async function checkAuth() {
      let status = await Variables.checkAuthentication();
    }
    (async()=>{
      let req_data = Variables.create_req_data({
        bot_id: botID
      });
      await axios
        .post(Variables.serverUrl("get-bot-info/"), req_data)
        .then(async (res) => {
          let bot_data=JSON.parse(res.data.bot)[0].fields
          setBot(bot_data)
          $("#chatbot-name").html("&nbsp;"+JSON.parse(res.data.bot)[0].fields.name)
        });
    })()
    checkAuth.call();
  },[]);
  return (
    <>
      <div className="App">
        <Leftnav />
        <div className="App-body">
          <header className="sticky-top">
            <Header />
          </header>
          <Banner />
          <div className="home-container sticky-top">
            <HomeMenu />
            <HomeSection1 bot={bot} avg_query={avgQuery} avg_duration={avgDuration} activeUsers={activeUsers}/>
            <HomeSection2 conv_analysis={t_data} />
            <HomeSection4 analysis_data={{intents:intents,total_msg:total_msg,total_intents_used:total_intents_used}} />
            <HomeSection3 />
          </div>
        </div>
      </div>
    </>
  );
}

export default Insights;
