import $ from "jquery";
import Variables from "../variables";
import sentMailIcon from "../images/email-sent.png"
import accountIcon from "../images/account.png"

function Section1({ setData }) {
    const handleMenuClick=(e,data)=>{
        $(".email-menus").find('li').removeClass('active')
        $(e.target).addClass('active')
        if(data.type=="email-accounts"){
            let req_data=Variables.create_req_data({})
            // axios.post()
            setData({type:data.type})
        }else if(data.type=="emails"){
            let req_data=Variables.create_req_data({})
            // axios.post()
            setData({type:data.type})
        }
    }
  return (
    <div className="left col-lg-2 col-md-2 col-sm-2 menu">
        <br/>
        <h5>Menu</h5>
        <ul className="email-menus">
            <li className="active" onClick={(e)=>handleMenuClick(e,{type:"email-accounts"})}><img src={accountIcon} height={15} /> Email accounts</li>
            <li onClick={(e)=>handleMenuClick(e,{type:"emails"})}><img src={sentMailIcon} height={15} /> Sent mails</li>
        </ul>
    </div>
  );
}

export default Section1;
