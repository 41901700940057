import { useEffect } from "react";
import link from "../images/link.svg"
import options from "../images/options.svg"
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Variables from "../variables";
import $, { isFunction } from "jquery"

function Section2() {
    function remove_doc(e){
        $(e.target).closest('.slide').remove()
    }
    useEffect(()=>{
        $("#url-input").on('input',function(){
            let val=$(this).val()
            if(val!='' && Variables.validateURL(val)){
                $(this).css({
                    'border':'1px solid green'
                })
                $("#add-url-btn").prop('disabled',false)
            }else{
                $(this).css({
                    'border':'1px solid red'
                })
                $("#add-url-btn").prop('disabled',true)
            }
        })
        $("#add-url-btn").on('click',function(){
            let val=$("#url-input").val()
            if(val==''){
                $("#add-url-btn").prop('disabled',true)
            }else{
                let file_slide
                let is_sitemap=$("#sitemap").prop("checked")
                if(is_sitemap){
                    file_slide=$(`<li type='sitemap' url="${val}" class="flex-horizon slide"><div class="content limit-text"><img src="${link}"><b class="limit-text">${val}</b><br></div><div class="options dropdown"><img data-bs-toggle="dropdown" src="${options}"><ul class="dropdown-menu"><li><a class="dropdown-item remove">Remove</a></li></ul></div></li>`)
                }else{
                    file_slide=$(`<li type='url' url="${val}" class="flex-horizon slide"><div class="content limit-text"><img src="${link}"><b class="limit-text">${val}</b><br></div><div class="options dropdown"><img data-bs-toggle="dropdown" src="${options}"><ul class="dropdown-menu"><li><a class="dropdown-item remove">Remove</a></li></ul></div></li>`)
                }
                $(file_slide).find('.remove').on('click',remove_doc)
                $("#url-list").prepend(file_slide)
                $("#url-input").val('')
            }
        })
    },[])
    return (
        <div className="row files">
            <div className="upload-file-card col-sm-12 col-md-6 col-lg-6">
                <h5 className="title-color"><b>Add URL</b></h5>
                <input id="url-input" placeholder="http://example.com/" style={{width:"350px"}} className="form-control" />
                <small style={{color:'grey'}}>Please enter a valid URL (http:// or https://).</small>
                <br/>
                <input type="checkbox" id="sitemap" className="form-check-input" />&nbsp;<label for="sitemap"> Sitemap URL</label><br/><br/>
                <button id="add-url-btn" className="btn theme-btn" disabled>Add</button>
                <br/><br/>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
                <h5><b>URLs</b></h5>
                <ul id="url-list" className="files-list">
                </ul>
            </div>
        </div>
    )
}

export default Section2;