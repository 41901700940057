import "../chatbot/chatbot.css"
import send from "../chatbot/img/send-white.svg"
import down from "../chatbot/img/down-arrow.svg"
import user from "./Content"

function CustomizationPreview() {
    console.log(user)
    return (
        <>
            <div id="bot">
                <div id="chatbot-container">
                    <div class="container horizon-flex">
                        <div class="top">
                            <div class="overlay horizon-flex">
                                <div id="overlay-chatting-top">
                                    <div class="left">
                                        <img src="https://cdn.pixabay.com/photo/2017/10/24/00/39/bot-icon-2883144_1280.png" />
                                        <p>Chatting with <b>Xbot</b></p>
                                    </div>
                                </div>
                                <div class="top">
                                    <button id="close-chat-popup"><img src={down} /></button>
                                </div>
                                <div class="middle">
                                    <div class="left">
                                        <font>Chat with</font>
                                        <br/>
                                        <font id="preview-bot-name">Xbot</font>
                                    </div>
                                    <div class="right">
                                        <div class="container" id="test-chat-icon" style={{background:"url('https://cdn.sharurai.com/bot-icon/bot.svg')",backgroundPosition:'center',backgroundSize:'cover'}}>
                                        </div>
                                    </div>
                                </div>
                                <div id="preview-bot-description" class="bottom">
                                    <p>Get 24x7 support by Xbot. Ask an expert
                                        who can answer your questions and resolve
                                        your query.</p>
                                </div>
                            </div>
                        </div>
                        <div id="chat-area" class="middle">
                            <div class="chat-container">
                                
                            </div>
                        </div>
                        <div class="bottom">
                            <form class="top" id="message-form">
                                <input id="message-input" type="text" placeholder="Write your message" oninvalid="this.setCustomValidity('Please enter your message')" oninput="this.setCustomValidity('')" disabled required/><button type="submit" class="shadow" id="send-btn" disabled><img src={send} /></button>
                            </form>
                            <div class="bottom">
                                <div class="right">
                                    <font>Powered by <a href="#" style={{"font-weight": "bold"}}>Xbot</a></font>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomizationPreview;