import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Variables from "../variables";
import $, { contains } from "jquery";
import axios from "axios";
import { useEffect, useState } from "react";

function SelectForm({ show, handleClose, sendFunction }) {
  const [forms, setForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState({});
  useEffect(() => {
    let req_data = Variables.create_req_data({ action: "get" });
    axios.post(Variables.serverUrl("all-forms/"), req_data).then((res) => {
      setForms(res.data.forms);
    });
  }, []);
  function send_form() {
    let selectedEle = $("#select-form").find(
      "input[name='selectedForm']:checked"
    );
    if (selectedEle.length) {
      let index = selectedEle.attr("index");
      let form = forms[index];
      if (form) {
        Variables.loading_btn("#send-form", "Sending");
        async function anonymous() {
          await sendFunction(form);
          handleClose(true);
          Variables.unloading_btn("#send-form");
        }
        anonymous();
      }
    }
  }
  return (
    <>
      <Modal
        id="select-form"
        show={show}
        onHide={handleClose}
        backdrop="static"
        fullscreen="md-down"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select a form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {forms.length > 0 ? (
            <ul id="all-forms">
              {forms.map((ele, i) => (
                <li key={i} className="limit-text">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="selectedForm"
                    uid={ele.uid}
                    title={ele.title}
                    index={i}
                  />
                  &nbsp;&nbsp;
                  <a
                    className="selectableForm"
                    target="_blank"
                    href={"/app/form-builder?id=" + ele.uid}
                  >
                    {ele.title}
                  </a>
                </li>
              ))}
            </ul>
          ) : (
            <p>No form available</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            id="send-form-btn"
            className="theme-btn"
            variant="primary"
            onClick={send_form}
          >
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SelectForm;
