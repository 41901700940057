import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import mailIcon from "../images/email-dark.png";
import { useState } from "react";
import axios from "axios";
import Variables from "../variables";

function ConnectEmailAccount({
  show,
  handleClose,
  emailAccounts,
  setEmailAccounts,
}) {
  const [emailType, setEmailType] = useState("gmail");
  const [error, setError] = useState("");
  const [smtpEmail, setSmtpEmail] = useState("");
  const [smtpServer, setSmtpServer] = useState("");
  const [smtpPassword, setSmtpPassword] = useState("");
  const [smtpPort, setSmtpPort] = useState("");
  const handleConnectAccount = (e) => {
    if (emailType == "gmail") {
      Variables.loading_btn(e.target, "Connecting");
      let req_data = Variables.create_req_data({
        type: emailType,
      });
      axios
        .post(Variables.serverUrl("email-authentication/"), req_data)
        .then((res) => {
          if (res.status == 200) {
            Variables.unloading_btn(e.target);
            handleClose();
            let url = res.data.url;
            var width = 500;
            var height = 600;

            // Calculate the position of the small window
            var left = (window.screen.width - width) / 2;
            var top = (window.screen.height - height) / 2;

            // Open the small window with the specified dimensions and position
            window.open(
              url,
              "_blank",
              "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
                width +
                ", height=" +
                height +
                ", top=" +
                top +
                ", left=" +
                left
            );
          } else {
            setError(res.data.msg);
          }
        })
        .catch((res) => {
          Variables.unloading_btn(e.target);
          if (res.response.data.msg) {
            setError(res.response.data.msg);
          }
        });
    } else if (emailType == "smtp") {
      Variables.loading_btn(e.target, "Connecting");
      let req_data = Variables.create_req_data({
        type: emailType,
        email: smtpEmail,
        password: smtpPassword,
        server: smtpServer,
        port: smtpPort,
      });
      Variables.loading_btn(e.target, "Connecting");
      axios
        .post(Variables.serverUrl("email-authentication/"), req_data)
        .then((res) => {
          Variables.unloading_btn(e.target);
          if (res.status == 200 || res.status == 201) {
            handleClose();
            setEmailAccounts([...emailAccounts, res.data.data]);
          } else {
            setError(res.data.msg);
          }
        })
        .catch((res) => {
          Variables.unloading_btn(e.target);
          if (res.response.data.msg) {
            setError(res.response.data.msg);
          }
        });
    }
  };
  return (
    <>
      <Modal
        id="connect-email-account-cards"
        show={show}
        onHide={handleClose}
        backdrop="static"
        fullscreen={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img height={20} src={mailIcon} /> Connect an account
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error ? (
            <>
              <div
                class="alert alert-danger d-flex align-items-center"
                role="alert"
              >
                <div>Message: {error}</div>
              </div>
            </>
          ) : (
            <></>
          )}
          <label>Email Type</label>
          <br />
          <select
            className="form-select"
            onChange={(e) => setEmailType(e.target.value)}
          >
            <option value="gmail">Gmail</option>
            <option value="smtp">SMTP (Custom)</option>
          </select>
          <br />
          {emailType == "smtp" ? (
            <>
              <>
                <label>Email ID</label>
                <br />
                <input
                  type="email"
                  className="form-control"
                  placeholder="example@website.com"
                  onChange={(e) => setSmtpEmail(e.target.value)}
                />
                <br />
                <label>SMTP Server</label>
                <br />
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setSmtpServer(e.target.value)}
                />
                <br />
                <label>SMTP port</label>
                <br />
                <input
                  type="number"
                  className="form-control"
                  onChange={(e) => setSmtpPort(e.target.value)}
                />
                <br />
                <label>Email Password</label>
                <br />
                <input
                  style={{ fontSize: "1.2rem", fontWeight: "bolder" }}
                  type="password"
                  className="form-control"
                  onChange={(e) => setSmtpPassword(e.target.value)}
                />
                <br />
              </>
            </>
          ) : (
            <></>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            id="connect-email-account-btn"
            className="theme-btn"
            variant="primary"
            onClick={handleConnectAccount}
          >
            Connect
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConnectEmailAccount;
