import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "../css/NewBot.css";
import Variables from "../variables";
import axios from "axios";
import { useState } from "react";

function CreateMember({ show, handleClose, membersList, setMembersList }) {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState("");
  const submitForm = (e) => {
    e.preventDefault();
    let req_data = Variables.create_req_data({
      action: "create",
      name: name,
      email: email,
      password: password,
    });
    Variables.loading_btn("#create-member-btn", "Creating");
    axios
      .post(Variables.serverUrl("members/"), req_data)
      .then((res) => {
        if (res.status == 200 || res.status >= res.status) {
          let data = res.data;
          setMembersList((prevList) => [
            ...prevList,
            {
              uid: data.uid,
              name: data.name,
              email: data.email,
              created: data.created,
            },
          ]);
        }
        Variables.unloading_btn("#create-member-btn");
        handleClose();
      })
      .catch((e) => {
        if (e.response.data.msg !== undefined) {
          setError(e.response.data.msg);
        }
      });
  };
  return (
    <>
      <Modal
        id="create-member"
        show={show}
        onHide={handleClose}
        backdrop="static"
        fullscreen="md-down"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error ? (
            <>
              <div
                class="alert alert-danger d-flex align-items-center"
                role="alert"
              >
                <div>{error}</div>
              </div>
            </>
          ) : (
            <></>
          )}

          <form id="member-form" onSubmit={submitForm}>
            <div class="mb-3">
              <label class="form-label">Name</label>
              <input
                type="text"
                class="form-control"
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div class="mb-3">
              <label class="form-label">Email address</label>
              <input
                type="email"
                class="form-control"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <div class="form-text">
                This email will be used to as login credential for the member
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">Password</label>
              <input
                type="password"
                class="form-control"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            id="create-member-btn"
            className="theme-btn"
            variant="primary"
            form="member-form"
            type="submit"
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateMember;
