import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "../css/NewBot.css";
import Variables from "../variables";
import $ from "jquery";
import axios from "axios";

function NewKB({ show, handleClose }) {
  async function create_new_kb() {
    Variables.loading_btn("#create-new-kb-btn", "Creating");
    let kb_name = $("#new-kb-name").val();
    let req_data = Variables.create_req_data({
      action: 'add',
      name: kb_name
    });
    if (kb_name != "") {
      await axios
        .post(Variables.serverUrl("knowledge-base/"), req_data)
        .then(async (res) => {
          console.log(res);
          if (res.data.status > 400) {
            if (res.data.relogin) {
              localStorage.removeItem("sharurai_access_key");
              localStorage.removeItem("sharurai_refresh_key");
              window.location.href = "/login";
            }
          } else if(res.data.status=='err'){
            $("#create-new-kb-btn").hide()
            $(".modal-body").html(`<b>${res.data.msg}</b>`)
          } else if (res.status >= 200) {
            window.location.href = "/app/knowledge-base?id=" + res.data.kb_id;
          }
        });
    }
  }
  return (
    <>
      <Modal
        id="new-kb"
        show={show}
        onHide={handleClose}
        backdrop="static"
        fullscreen="md-down"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create a new Knowledge Base</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>Name your knowledge base</label>
          <input id="new-kb-name" className="form-control" maxLength={20} />
          <small style={{ color: "grey" }}>Max. length is 20 letters</small>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            id="create-new-kb-btn"
            className="theme-btn"
            variant="primary"
            onClick={create_new_kb}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NewKB;
