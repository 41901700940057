import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Variables from "../variables";
import $ from "jquery";
import axios from "axios";
import { useEffect, useState } from "react";

function SelectMembers({ show, handleClose, selectMemberFunction }) {
  const [members, setMembers] = useState([]);
  let selectedMembers = [];
  useEffect(() => {
    let req_data = Variables.create_req_data({ action: "get-all" });
    axios.post(Variables.serverUrl("members/"), req_data).then((res) => {
        setMembers(res.data.members);
    });
  }, []);
  async function sendBots() {
    $(".selectableMember:checked").each(function () {
      var uid = $(this).attr("uid");
      var name = $(this).attr("title");
      var email = $(this).attr("email");
      selectedMembers.push({ uid: uid, name: name,email:email });
    });
    if (selectedMembers) {
      await selectMemberFunction({ members: selectedMembers });
      handleClose();
    }
  }
  return (
    <>
      <Modal
        id="select-member"
        show={show}
        onHide={handleClose}
        backdrop="static"
        fullscreen="md-down"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select members</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {members.length > 0 ? (
            <ul id="all-members">
              {members.map((ele, i) => (
                <li key={i} className="limit-text">
                  <input
                    className="form-check-input selectableMember"
                    type="checkbox"
                    name="selectedForm"
                    uid={ele.uid}
                    title={ele.name}
                  />
                  &nbsp;&nbsp;
                  <a
                    className="selectableForm"
                    target="_blank"
                    href={"/app/insights/?id=" + ele.uid}
                  >
                    {ele.name}
                  </a>
                </li>
              ))}
            </ul>
          ) : (
            <p>No member available</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button id="send-member-btn" className="theme-btn" onClick={sendBots} variant="primary">
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SelectMembers;
