import React, { useEffect, useState } from "react";
import lead from "../images/funnel.svg";
import chat from "../images/chat.svg";
import clockIcon from "../images/clock.svg";
import questionIcon from "../images/question.svg";
import sessionIcon from "../images/session.svg";
import userIcon from "../images/account.png";
import Variables from "../variables";
import axios from "axios";
import { Link } from "react-router-dom";

function secondsToHoursMinutesOrSeconds(seconds) {
  if (seconds >= 3600) {
    const hours = Math.floor(seconds / 3600);
    return `${hours} Hr`;
  } else if (seconds >= 60) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes} Min`;
  } else {
    return `${seconds} Sec`;
  }
}

let is_member = localStorage.getItem("sharurai_member_login");

function HomeSection1({ bot, avg_query, avg_duration, activeUsers }) {
  let botID = "";
  const [data, setData] = useState("");
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const extracted_id = params.get("id");
  botID = extracted_id;
  useEffect(() => {
    (async () => {
      let req_data = Variables.create_req_data({
        bot_id: botID,
      });
      await axios
        .post(Variables.serverUrl("quick-insights/"), req_data)
        .then(async (res) => {
          setData(res.data);
        });
    })();
  }, []);
  return (
    <div className="home-section homesection1">
      <div className="row">
        <div
          params={{ testvalue: "hello" }}
          className="quick-insight col-sm-6 col-md-4 col-lg-3"
        >
          <div className="card">
            <div className="card-content">
              <img src={userIcon} />
              <div className="data">
                <font>Live users</font>
                <h4>{activeUsers}</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="quick-insight col-sm-6 col-md-4 col-lg-3">
          <div className="card" id="leads-card" uid={bot.lead_form}>
            <div className="card-content" style={{ position: "relative" }}>
              {(() => {
                if (
                  bot.lead_form &&
                  bot.lead_form != "" &&
                  bot.lead_form != null &&
                  is_member == "false"
                )
                  return (
                    <small
                      className="card-hover-link"
                      style={{
                        display: "none",
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        padding: "2px 4px",
                        "border-radius": "15px",
                        color: "white",
                      }}
                    >
                      <a
                        style={{ color: "black", "text-decoration": "none" }}
                        href={"/app/form-submissions?id=" + bot.lead_form}
                        target="_blank"
                      >
                        Leads &#x2197;
                      </a>
                    </small>
                  );
              })()}
              <img src={lead} />
              <div className="data">
                <font>Total leads</font>
                <h4>{data.total_leads}</h4>
              </div>
            </div>
          </div>
        </div>
        <div
          id="total-conversations"
          className="quick-insight col-sm-6 col-md-4 col-lg-3"
        >
          <div className="card">
            <div className="card-content">
              <img src={chat} />
              {is_member == "true" ? (
                <div
                style={{ color: "black" }}
                params={{ testvalue: "hello" }}
                className="data"
              >
                <font>Total conversations</font>
                <h4>{data.total_conversations}</h4>
              </div>
              ) : (
                <Link
                style={{ color: "black" }}
                to={"/app/conversations?id=" + botID}
                params={{ testvalue: "hello" }}
                className="data"
              >
                <font>Total conversations</font>
                <h4>{data.total_conversations}</h4>
              </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="quick-insight col-sm-6 col-md-4 col-lg-3">
          <div className="card">
            <div className="card-content">
              <img src={sessionIcon} />
              <div className="data">
                <font>Sessions</font>
                <h4>{data.sessions}</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="quick-insight col-sm-6 col-md-4 col-lg-3">
          <div className="card">
            <div className="card-content">
              <img src={questionIcon} />
              <div className="data">
                <font>Avg. queries per user</font>
                <h4>{Math.floor(avg_query)}</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="quick-insight col-sm-6 col-md-4 col-lg-3">
          <div className="card">
            <div className="card-content">
              <img src={clockIcon} />
              <div className="data">
                <font>Avg. session length</font>
                <h4>
                  {secondsToHoursMinutesOrSeconds(Math.floor(avg_duration))}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeSection1;
