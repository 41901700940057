import React, { useEffect, useState } from "react";
import bot from "../images/bot.png";
import { Link } from "react-router-dom";

export function HomeMenu() {
  const [botID, setBotID] = useState("");
  useEffect(() => {
    const leftNavLinks = document.getElementsByClassName("left-nav-link");
    for (let i = 0; i < leftNavLinks.length; i++) {
      leftNavLinks[i].classList.remove("active");
    }
    document
      .getElementsByClassName("dashboard-link")[0]
      .classList.add("active");
    const search = window.location.search;
    const params = new URLSearchParams(search);
    setBotID(params.get("id"));
  });
  return (
    <div className="home-menu" id="home-menu">
      <ul>
        <li>
          <Link
            className="insight-link menu-link"
            to={"/app/insights?id=" + botID}
          >
            Insights
          </Link>
        </li>
        <li>
          <Link
            className="bot-info-link menu-link"
            to={"/app/bot-info/?id=" + botID}
          >
            Bot info & customization
          </Link>
        </li>
        <li>
          <Link
            className="bot-intent-link menu-link"
            to={"/app/intent/?id=" + botID}
          >
            Bot intents
          </Link>
        </li>
        <li>
          <Link
            className="test-bot-link menu-link"
            to={"/app/test-bot/?id=" + botID}
          >
            Test/Deploy bot
          </Link>
        </li>
        <li>
          <Link
            className="livechat-link menu-link"
            to={"/app/livechat/?id=" + botID}
          >
            Live chat
          </Link>
        </li>
      </ul>
      <div
        className="bot-selector"
        style={{
          padding: "5px",
          "border-radius": "50px",
          background: "#f5e8c5",
        }}
      >
        <img src={bot} />
        <h6 id="chatbot-name"></h6>
      </div>
    </div>
  );
}

export default HomeMenu;
