import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "../css/NewBot.css";
import Variables from "../variables";
import axios from "axios";
import $ from "jquery";

function DeleteKB({ show, handleClose, ele }) {
  async function delete_kb() {
    let id = $(ele).attr("uid");
    Variables.loading_btn("#delete-kb-btn", "Deleting");
    let req_data = Variables.create_req_data({
      ele: "Knowledge_base",
      id: id,
    });
    if (id != "") {
      await axios
        .post(Variables.serverUrl("delete/"), req_data)
        .then(async (res) => {
          console.log(res);
          if (res.data.status > 400) {
            if (res.data.relogin) {
              localStorage.removeItem("sharurai_access_key");
              localStorage.removeItem("sharurai_refresh_key");
              window.location.href = "/login";
            }
          } else if (res.status >= 200) {
            $("#" + req_data.id).remove();
            Variables.unloading_btn("#delete-kb-btn");
            handleClose();
          }
        });
    }
  }
  return (
    <>
      <Modal
        id="delete-kb"
        show={show}
        onHide={handleClose}
        backdrop="static"
        fullscreen="md-down"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b>Note- </b>
          <font>
            After deleting the knowledge base, none of your bots will be
            able to access the data of the knowledge base.
          </font>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            id="delete-kb-btn"
            className="theme-btn"
            variant="primary"
            onClick={delete_kb}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteKB;
