import { useEffect, useLayoutEffect, useState } from "react";
import "./css/Plans.css";
import logo from "./images/sharurai-logo-2.png";
import $ from "jquery";
import Variables from "./variables";
import axios from "axios";
import { Link } from "react-router-dom";

function Plans() {
  let basicMonthlyPrice = 0;
  let basicYearlyPrice = 0;
  let advanceMonthlyPrice = 0;
  let advanceYearlyPrice = 0;
  let premiumMonthlyPrice = 0;
  let premiumYearlyPrice = 0;
  let noWaterMarkMonthlyPrice = 0;
  let noWaterMarkYearlyPrice = 0;
  let basicChatC = 0;
  let advanceChatC = 0;
  let premiumChatC = 0;
  let basicChatbotC = 0;
  let advanceChatbotC = 0;
  let premiumChatbotC = 0;
  let basicWordsC = 0;
  let advanceWordsC = 0;
  let premiumWordsC = 0;
  let advanceIntentR = 0;
  let premiumIntentR = 0;
  const [noWaterMarkPrice, setNoWaterMarkPrice] = useState(0);
  const [basicPrice, setBasicPrice] = useState();
  const [advancePrice, setAdvancePrice] = useState();
  const [premiumPrice, setPremiumPrice] = useState();
  const [messagePrice, setMessagePrice] = useState();
  let messageP = 0;
  const [basicChatCredit, setBasicChatCredit] = useState();
  const [basicChatbotCredit, setBasicChatbotCredit] = useState();
  const [basicWordsCredit, setBasicWordsCredit] = useState();
  const [advanceChatCredit, setAdvanceChatCredit] = useState();
  const [advanceChatbotCredit, setAdvanceChatbotCredit] = useState();
  const [advanceIntentRate, setAdvanceIntentRate] = useState();
  const [advanceWordsCredit, setAdvanceWordsCredit] = useState();
  const [premiumChatCredit, setPremiumChatCredit] = useState();
  const [premiumChatbotCredit, setPremiumChatbotCredit] = useState();
  const [premiumWordsCredit, setPremiumWordsCredit] = useState();
  const [premiumIntentRate, setPremiumIntentRate] = useState();
  const [duration, setDuration] = useState("monthly");
  const [currentPlan, setCurrentPlan] = useState("");
  const [currentDuration, setCurrentDuration] = useState("");
  const [currentPlanLevel, setCurrentPlanLevel] = useState();
  const [isCustomer, setIsCustomer] = useState(false);
  const [gross, setGross] = useState(0);
  const [error, setError] = useState("");
  const [codeFields, setCodeFields] = useState([1]);
  useEffect(() => {
    $("button").prop("disabled", false);
    $("button").removeClass("btn-success");
    $("button").addClass("btn-dark");
    $("button").html("Select plan");
    let btn = $("#" + currentPlan + "-plan-btn");
    btn.prop("disabled", true);
    btn.html("Already opted");
    btn.removeClass("btn-dark");
    btn.addClass("btn-success");
  }, [currentDuration, currentPlan, duration]);
  var total_gross = 0;
  let plan_price = 0;
  let additional_message = 0;
  let no_watermark_price = 0;
  useLayoutEffect(() => {
    async function checkAuth() {
      let status = await Variables.checkAuthentication();
    }
    checkAuth.call();
    (async () => {
      const req_data = Variables.create_req_data({});
      await axios
        .post(Variables.serverUrl("get-plans/"), req_data)
        .then((res) => {
          if (res.data.is_watermark) {
            $("#no-watermark-btn").replaceWith(
              "<b style='color:#2daa00;'>You have already opted for this.</b>"
            );
          }
          setCurrentDuration(res.data.current_duration);
          setCurrentPlan(res.data.current_plan);
          setCurrentPlanLevel(res.data.level);
          setIsCustomer(res.data.is_customer);
          res.data.plans.forEach((element) => {
            if (element.type == "basic") {
              setBasicPrice(element.price);
              basicChatC = element.chat_credit;
              basicChatbotC = element.chatbot_credit;
              basicMonthlyPrice = element.price;
              basicYearlyPrice = element.yearly_price;
              basicWordsC = element.words_monthly;
              setBasicChatCredit(basicChatC);
              setBasicChatbotCredit(basicChatbotC);
              setBasicWordsCredit(basicWordsC);
            } else if (element.type == "advance") {
              advanceChatC = element.chat_credit;
              advanceChatbotC = element.chatbot_credit;
              advanceIntentR = element.intent_rate;
              basicWordsC = element.words_monthly;
              setAdvancePrice(element.price);
              advanceMonthlyPrice = element.price;
              advanceYearlyPrice = element.yearly_price;
              advanceWordsC = element.words_monthly;
              setAdvanceChatCredit(advanceChatC);
              setAdvanceChatbotCredit(advanceChatbotC);
              setAdvanceIntentRate(advanceIntentR);
              setAdvanceWordsCredit(advanceWordsC);
            } else if (element.type == "premium") {
              premiumChatC = element.chat_credit;
              premiumChatbotC = element.chatbot_credit;
              premiumIntentR = element.intent_rate;
              setPremiumPrice(element.price);
              premiumMonthlyPrice = element.price;
              premiumYearlyPrice = element.yearly_price;
              premiumWordsC = element.words_monthly;
              setPremiumChatCredit(premiumChatC);
              setPremiumChatbotCredit(premiumChatbotC);
              setPremiumIntentRate(premiumIntentR);
              setPremiumWordsCredit(premiumWordsC);
            }
          });
          let rate = JSON.parse(res.data.rate)[0].fields;
          setMessagePrice(rate.message);
          messageP = rate.message;
          setNoWaterMarkPrice(rate.watermark_monthly);
          noWaterMarkMonthlyPrice = rate.watermark_monthly;
          noWaterMarkYearlyPrice = rate.watermark_yearly;
        });
    })();
  }, []);
  function checkout(data) {
    $("button").prop("disabled", true);
    let req_data = Variables.create_req_data({
      data: JSON.stringify(data),
    });
    if (data.plan !== undefined && isCustomer) {
      axios
        .post(Variables.serverUrl("upgrade/"), req_data)
        .then((res) => {
          if (res.data.msg !== undefined) {
            setError(res.data.msg);
          }
        })
        .catch((e) => {
          $("button").prop("disabled", false);
          if (e.response.data.msg !== undefined) {
            setError(e.response.data.msg);
          }
        });
    } else {
      axios.post(Variables.serverUrl("checkout/"), req_data).then((res) => {
        window.location = res.data.session_url;
      });
    }
  }
  const [waitMsg,setWaitMsg]=useState(false)
  function submitAppsumoCode(e) {
    e.preventDefault();
    var code = $(e.target)
      .find("input")
      .map(function () {
        if ($(this).val()) {
          return $(this).val();
        }
      })
      .get();
    if (code != "" && code != undefined) {
      $("button").prop("disabled", true);
      Variables.loading_btn(this, "Validating");
      let req_data = Variables.create_req_data({
        data: JSON.stringify({
          plan: "appsumo",
          duration: "lifetime",
          code: code,
        }),
      });
      setWaitMsg(true)
      axios
        .post(Variables.serverUrl("checkout/"), req_data)
        .then((res) => {
          setWaitMsg(false)
          if (res.data.status == "success") {
            window.location = "/login";
          } else {
            Variables.unloading_btn(this);
            $("#appsumo-msg").show();
            $("#appsumo-msg").html(res.data.msg);
            $(e.target).find("button").prop("disabled", false);
          }
        })
        .catch((e) => {
          $(e.target).find("button").prop("disabled", false);
        });
    }
  }
  useEffect(() => {
    $("#lifetime-plan-btn").on("click", function () {
      $("button").prop("disabled", true);
      let req_data = Variables.create_req_data({
        data: JSON.stringify({ plan: "lifetime", duration: "lifetime" }),
      });
      axios.post(Variables.serverUrl("checkout/"), req_data).then((res) => {
        window.location = res.data.session_url;
      });
    });
    $("#free-plan-btn").on("click", function () {
      $("button").prop("disabled", true);
      let req_data = Variables.create_req_data({
        data: JSON.stringify({ plan: "free", duration: "lifetime" }),
      });
      axios.post(Variables.serverUrl("checkout/"), req_data).then((res) => {
        window.location = res.data.session_url;
      });
    });
    $("#duration > div").on("click", function () {
      total_gross = total_gross - plan_price - no_watermark_price;
      setGross(total_gross);
      plan_price = 0;
      $("#basic-btn, #premium-btn").html("Select plan");
      $("#basic-btn, #premium-btn").addClass("btn-dark");
      $("#basic-btn, #premium-btn").removeClass("btn-outline-dark");
      $("#basic-btn, #premium-btn").attr("selected", false);
      no_watermark_price = 0;
      $("#no-watermark-btn").html("Add");
      $("#no-watermark-btn").addClass("btn-dark");
      $("#no-watermark-btn").removeClass("btn-outline-dark");
      $("#no-watermark-btn").attr("selected", false);
      $("#duration > div").removeClass("active");
      $(this).addClass("active");
      let duration = $(this).attr("value");
      switch (duration) {
        case "monthly":
          $(".duration").html("/month");
          setDuration("monthly");
          setBasicPrice(basicMonthlyPrice);
          setAdvancePrice(advanceMonthlyPrice);
          setPremiumPrice(premiumMonthlyPrice);
          setNoWaterMarkPrice(noWaterMarkMonthlyPrice);
          break;
        case "yearly":
          setDuration("yearly");
          setBasicPrice(basicYearlyPrice);
          setAdvancePrice(advanceYearlyPrice);
          setPremiumPrice(premiumYearlyPrice);
          setNoWaterMarkPrice(noWaterMarkYearlyPrice);
          $(".duration").html("/year");
          break;
      }
      setBasicChatCredit(basicChatC);
      setBasicChatbotCredit(basicChatbotC);
      setAdvanceChatCredit(advanceChatC);
      setAdvanceChatbotCredit(advanceChatbotC);
      setAdvanceIntentRate(advanceIntentR);
      setPremiumIntentRate(premiumIntentR);
      setPremiumChatCredit(premiumChatC);
      setPremiumChatbotCredit(premiumChatbotC);
    });
  }, []);
  return (
    <div id="plans">
      {error ? (
        <>
          <div
            id="error"
            style={{
              position: "fixed",
              bottom: "10px",
              right: "10px",
              background: "#ffd7d7",
              padding: ".5rem",
              borderRadius: "10px",
              border: "1px solid #c8c8c8",
              zIndex: "1100",
            }}
          >
            <h5>Message:</h5> {error}
          </div>
        </>
      ) : (
        <></>
      )}

      <img style={{ height: "40px" }} src={logo} className="logo" />
      <div className="container">
        <div className="top">
          <Link to="/app/bots" style={{ textDecoration: "none" }}>
            <b>&#x2190; Back to dashboard</b>
          </Link>
          <br />
          <h4>Choose a plan</h4>
          <div id="duration">
            <div className="monthly active" value="monthly">
              Monthly
            </div>
          </div>
        </div>
        <br />
        {true ? (
          <>
            <div id="lifetime-plans" level="0">
              <br />
              {waitMsg ? (
                <>
                  <center>
                    <div
                      class="alert alert-info"
                      style={{ width: "fit-content" }}
                      role="alert"
                    >
                      <h5>Please hold tight. This may take some time...</h5>
                    </div>
                  </center>
                  <br />
                </>
              ) : (
                <></>
              )}

              <br />
              <div className="row" style={{ justifyContent: "center" }}>
                <div className="col-md-6 col-lg-4 col-sm-12 plan">
                  <div className="container">
                    <div className="card">
                      <div className="card-title">
                        <h5>Free</h5>
                        <b className="price free-price">$0</b>
                      </div>
                      <div className="card-body">
                        <ul className="features">
                          <li>&#x2714; 100 Message credits</li>
                          <li>&#x2714; 1 Chatbot</li>
                          <li>&#x2714; 5,000 tokens</li>
                          <li>&#x2714; 1 intent</li>
                          <li>&#x2714; 1 email account</li>
                        </ul>
                      </div>
                      <div className="card-footer">
                        <button
                          plan-type="free"
                          selected={false}
                          price={0}
                          className="btn btn-dark"
                          id="free-plan-btn"
                        >
                          Select plan
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {currentPlan == "unpaid" || currentPlan.includes("appsumo") ? (
                  <>
                    <div className="col-md-6 col-lg-4 col-sm-12 plan">
                      <form className="container" onSubmit={submitAppsumoCode}>
                        <div className="card" id="appsumo-card">
                          <div className="card-title" id="appsumo">
                            <h5>Redeem your code</h5>
                            {codeFields.map((item, index) => {
                              return (
                                <input
                                  id="appsumo-input"
                                  type="text"
                                  className="form-control"
                                  placeholder="code"
                                />
                              );
                            })}
                            <br />
                            <div
                              className="btn btn-sm round-btn btn-light"
                              style={{ width: "100%" }}
                              onClick={() =>
                                setCodeFields((data) => [...data, 1])
                              }
                            >
                              + Add code
                            </div>
                            <br />
                          </div>
                          <div className="card-footer">
                            <font
                              id="appsumo-msg"
                              style={{
                                color: "#de0303",
                                fontSize: ".9rem",
                                display: "none",
                              }}
                            ></font>
                            <button className="btn btn-dark" type="submit">
                              Redeem
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <br />
              <br />
              <h5 style={{ textAlign: "center" }}>Subscription plans</h5>
              <br />
              <br />
            </div>
          </>
        ) : (
          <></>
        )}

        <div className="row" id="all-plans">
          {currentPlanLevel <= 1 ? (
            <>
              <div className="col-md-4 col-lg-4 col-sm-12 plan">
                <div className="container">
                  <div className="card">
                    <div className="card-title">
                      <h5>Basic</h5>
                      <b className="price basic-price">
                        ${Number(basicPrice).toLocaleString()}
                      </b>
                      <font className="duration">/month</font>
                    </div>
                    <div className="card-body">
                      <ul className="features">
                        <li>
                          &#x2714; {Number(basicChatCredit).toLocaleString()}{" "}
                          Messages monthly
                        </li>
                        <li>&#x2714; {basicChatbotCredit} Chatbots monthly</li>
                        <li>
                          &#x2714; {Number(basicWordsCredit) / 1000000} Million
                          words monthly
                        </li>
                        <li>&#x2714; 1 intent</li>
                        <li>&#x2714; Advance analytics</li>
                        <li>&#x2714; Notion integration</li>
                        <li>&#x2714; Mailchimp integration</li>
                      </ul>
                    </div>
                    <div className="card-footer">
                      <button
                        id={`basic-plan-btn`}
                        plan-type="basic"
                        selected={false}
                        onClick={(e) =>
                          checkout({
                            plan: "basic",
                            duration: duration,
                          })
                        }
                        className="btn btn-dark plan-btn"
                      >
                        Select plan
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {currentPlanLevel <= 2 ? (
            <>
              <div className="col-md-4 col-lg-4 col-sm-12 plan" level="2">
                <div className="container">
                  <div
                    className="card"
                    style={{
                      background: "linear-gradient(45deg, #d0c96a, #fffff2)",
                    }}
                  >
                    <div className="card-title">
                      <h5>Advance</h5>
                      <b className="price premium-price">
                        ${Number(advancePrice).toLocaleString()}
                      </b>
                      <font className="duration">/month</font>
                    </div>
                    <div className="card-body">
                      <ul className="features">
                        <li>
                          &#x2714; {Number(advanceChatCredit).toLocaleString()}{" "}
                          Messages monthly
                        </li>
                        <li>
                          &#x2714; {advanceChatbotCredit} chatbots monthly
                        </li>
                        <li>&#x2714; {advanceIntentRate} intents/chatbot</li>
                        <li>
                          &#x2714; {Number(advanceWordsCredit) / 1000000}{" "}
                          Million words monthly
                        </li>
                        <li>&#x2714; Advance analytics</li>
                        <li>&#x2714; Notion integration</li>
                        <li>&#x2714; Mailchimp integration</li>
                        <li>&#x2714; Whatsapp integration coming soon...</li>
                        <li>&#x2714; Messenger integration coming soon...</li>
                        <li>&#x2714; Slack integration coming soon...</li>
                        <li>&#x2714; Shopify integration coming soon...</li>
                        <li>&#x2714; Wordpress integration coming soon...</li>
                      </ul>
                    </div>
                    <div className="card-footer">
                      <button
                        id={`advance-plan-btn`}
                        plan-type="advance"
                        selected={false}
                        price={advancePrice}
                        className="btn btn-dark plan-btn"
                        onClick={(e) => {
                          checkout({
                            plan: "advance",
                            duration: duration,
                          });
                        }}
                      >
                        Select plan
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {currentPlanLevel <= 3 ? (
            <>
              <div className="col-md-4 col-lg-4 col-sm-12 plan" level="3">
                <div className="container">
                  <div className="card">
                    <div className="card-title">
                      <h5>Premium</h5>
                      <b className="price premium-price">
                        ${Number(premiumPrice).toLocaleString()}
                      </b>
                      <font className="duration">/month</font>
                    </div>
                    <div className="card-body">
                      <ul className="features">
                        <li>
                          &#x2714; {Number(premiumChatCredit).toLocaleString()}{" "}
                          Messages monthly
                        </li>
                        <li>
                          &#x2714; {premiumChatbotCredit} Chatbots monthly
                        </li>
                        <li>&#x2714; {premiumIntentRate} intents/chatbot</li>
                        <li>
                          &#x2714; {Number(premiumWordsCredit) / 1000000}{" "}
                          Million words monthly
                        </li>
                        <li>&#x2714; Advance analytics</li>
                        <li>&#x2714; Notion integration</li>
                        <li>&#x2714; Mailchimp integration</li>
                        <li>&#x2714; Whatsapp integration coming soon...</li>
                        <li>&#x2714; Messenger integration coming soon...</li>
                        <li>&#x2714; Slack integration coming soon...</li>
                        <li>&#x2714; Shopify integration coming soon...</li>
                        <li>&#x2714; Wordpress integration coming soon...</li>
                      </ul>
                    </div>
                    <div className="card-footer">
                      <button
                        id={`premium-plan-btn`}
                        plan-type="premium"
                        selected={false}
                        price={premiumPrice}
                        className="btn btn-dark plan-btn"
                        onClick={(e) =>
                          checkout({
                            plan: "premium",
                            duration: duration,
                          })
                        }
                      >
                        Select plan
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <br />
        <br />
        <div id="gross">
          <div className="container row">
            <div className="col-md-6 col-sm-12 col-lg-6">
              <div className="card" style={{ maxWidth: "100%" }}>
                <div className="card-title">
                  <h5>Additional token credits</h5>
                </div>
                <div className="card-body">
                  <br />
                  <p>In case you need more tokens</p>
                  <b
                    style={{ "font-size": "1.2rem" }}
                    className="message-price"
                  >
                    $10/
                  </b>
                  <font style={{ "font-size": ".9rem" }}>200K tokens</font>
                  <input
                    id="additional-tokens"
                    className="form-control"
                    type="number"
                    step={200000}
                    defaultValue={0}
                    min={0}
                  />
                </div>
                <div className="card-footer">
                  <button
                    price={noWaterMarkPrice}
                    selected={false}
                    id="no-watermark-btn"
                    className="btn btn-dark"
                    onClick={(e) => {
                      if (Number($("#additional-tokens").val())) {
                        checkout({
                          tokens: Number($("#additional-tokens").val()),
                        });
                      }
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12 plan">
              <div className="container">
                <div className="card">
                  <div className="card-title">
                    <h5>Additional message credits</h5>
                  </div>
                  <div className="card-body">
                    <font>In case you need more message credits.</font>
                    <br />
                    <br />
                    <b
                      style={{ "font-size": "1.2rem" }}
                      className="message-price"
                    >
                      $10/
                    </b>
                    <font style={{ "font-size": ".9rem" }}>20,000 message credits</font>
                    <input
                      id="additional-message"
                      className="form-control"
                      type="number"
                      step={20000}
                      defaultValue={0}
                      min={0}
                    />
                    <br />
                    <button
                      plan-type="premium"
                      selected={false}
                      price={premiumPrice}
                      className="btn btn-dark plan-btn"
                      onClick={(e) => {
                        if (Number($("#additional-message").val())) {
                          checkout({
                            messages: Number($("#additional-message").val()),
                          });
                        }
                      }}
                    >
                      Buy credit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}

export default Plans;
