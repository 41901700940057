import axios from "axios";
import { useEffect, useState } from "react";
import Variables from "../variables";
import $ from "jquery";
import deleteIcon from "../images/delete-dark.svg";
import pinIcon from "../images/pin.svg";
import userIcon from "../images/user.svg";

function getTimeDifference(utcTimestamp) {
  const utcDate = new Date(utcTimestamp);
  const currentDate = new Date();

  const timeDifference = currentDate.getTime() - utcDate.getTime();
  const secondsDifference = Math.abs(Math.floor(timeDifference / 1000));

  if (secondsDifference < 60) {
    return secondsDifference + (secondsDifference === 1 ? " second" : " seconds");
  }

  const minutesDifference = Math.floor(secondsDifference / 60);
  if (minutesDifference < 60) {
    return minutesDifference + (minutesDifference === 1 ? " minute" : " minutes");
  }

  const hoursDifference = Math.floor(minutesDifference / 60);
  if (hoursDifference < 24) {
    return hoursDifference + (hoursDifference === 1 ? " hour" : " hours");
  }

  const daysDifference = Math.floor(hoursDifference / 24);
  if (daysDifference < 30) {
    return daysDifference + (daysDifference === 1 ? " day" : " days");
  }

  const monthsDifference = Math.floor(daysDifference / 30);
  if (monthsDifference < 12) {
    return monthsDifference + (monthsDifference === 1 ? " month" : " months");
  }

  const yearsDifference = Math.floor(monthsDifference / 12);
  return yearsDifference + (yearsDifference === 1 ? " year" : " years");
}



function Section1({ setTheConversation }) {
  let botID = "";
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const extracted_id = params.get("id");
  const [selectedConvs,setSelectedConvs]=useState([])
  const [btnDisabled,setBtnDisabled]=useState("disabled")

  useEffect(()=>{
    if(selectedConvs.length){
      setBtnDisabled("")
    }else{
      setBtnDisabled("disabled")
    }
  },[selectedConvs])

  botID = extracted_id;
  const [conversations, setConversations] = useState("");
  useEffect(() => {
    let req_data = Variables.create_req_data({
      id: botID,
    });
    axios.post(Variables.serverUrl("conversations/"), req_data).then((res) => {
      setConversations(res.data.conversations);
    });
  }, []);
  const show_messages = (e, uid) => {
    $("#conversations-list ul > li").removeClass("active");
    $(e.target).addClass("active");
    setTheConversation(conversations.filter((item) => item.uid === uid)[0]);
    $(".conversation").removeClass("active");
    $(e.target).parent(".conversation").addClass("active");
  };
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedConvs(prevConvs => [...prevConvs, value]);
    } else {
      setSelectedConvs(prevConvs => prevConvs.filter(conv => conv !== value));
    }
  };
  const deleteConversations=(e)=>{
    Variables.loading_btn(e.target,"Deleting")
    if(selectedConvs){
      axios.post(Variables.serverUrl("delete-conversations/"),Variables.create_req_data({bot_id:botID,convs:selectedConvs})).then((res)=>{
        if(res.status==200){
          const updateConversations = conversations.filter(item => !selectedConvs.includes(item.uid));
          setConversations(updateConversations);
          Variables.unloading_btn(e.target)
          setSelectedConvs([])
        }
      }).catch((res)=>{
        Variables.unloading_btn(e.target)
      })
    }
  }
  return (
    <div className="left col-lg-3 col-md-3 col-sm-3">
      <div className="title">
        <br />
        <h5>All conversations</h5>
        <br/>
        <button className={`btn btn-danger btn-sm theme-btn `+btnDisabled} onClick={deleteConversations}><img height={15} src={deleteIcon} /> Delete</button>
        <br /><br />
      </div>
      <div id="conversations-list">
        {Object.keys(conversations).length ? (
          <>
            <ul className="container">
              {Object.keys(conversations).map((obj, i) => (
                <li
                  style={{ position: "relative" }}
                  className="conversation flex-horizon"
                >
                  <div className="conversation-actions">
                    <input className="form-check-input" type="checkbox" value={conversations[i].uid} checked={selectedConvs.includes(conversations[i].uid)} onChange={handleCheckboxChange} />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                  <img src={userIcon} style={{ height: "40px" }} />
                  &nbsp;&nbsp;
                  <div
                    className="target"
                    onClick={(e) => show_messages(e, conversations[i].uid)}
                    style={{
                      visibility: 0,
                      position: "absolute",
                      top: 0,
                      left: "40px",
                      right: 0,
                      bottom: 0,
                      width: "80%",
                    }}
                  ></div>
                  <div className="container">
                    <div className="flex-horizon limit-text">
                      <b className="limit-text" style={{ fontSize: ".9rem" }}>
                        {conversations[obj].name ? (
                          <>{conversations[obj].name}</>
                        ) : (
                          <>Unknown</>
                        )}
                      </b><br/>
                      <small></small>
                    </div>
                    <small className="form-text" style={{ fontSize: ".8rem" }}>
                      {conversations[obj].email ? (
                        <>{conversations[obj].email}</>
                      ) : (
                        <></>
                      )}
                    </small>
                    <small style={{
                      fontSize:".7rem"
                    }}>Last seen - {getTimeDifference(conversations[i].date_updated)} ago</small>
                  </div>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <>
            <i className="form-text">No conversations yet</i>
          </>
        )}
      </div>
    </div>
  );
}

export default Section1;
