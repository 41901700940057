import excel from "../images/excel.svg";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import Variables from "../variables";
import axios from "axios";

function Section1({id,formName}) {
  const [disableExportBtn, setDisableExportBtn] = useState(false);
  function export_data(e) {
    setDisableExportBtn(true);
    let req_data = Variables.create_req_data({
      id: id,
      action: "export",
    });
    axios.post(Variables.serverUrl("submission/"), req_data).then((res) => {
      const blob = new Blob([res.data], { type: "text/csv" });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", formName+".csv");

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      setDisableExportBtn(false);
    }).catch((e)=>{
        setDisableExportBtn(false)
    })
  }
  return (
    <div className="top">
      <a id="downloadAnchorElem" style={{ display: "none" }}></a>
      <span>
        <h4 id="form-name" className="title-color">
          Form name
        </h4>
        <small>
          Total submissions (<font id="total-submissions-count">0</font>)
        </small>
      </span>
      <span>
        <button
          id="export-btn"
          className="btn theme-btn"
          onClick={export_data}
          disabled={disableExportBtn}
        >
          <img height={20} src={excel} /> Export as CSV &#8599;
        </button>
      </span>
    </div>
  );
}

export default Section1;
