import { useEffect, useState } from "react";
import Section1 from "./Profile/Section1";
import Section3 from "./Profile/Section3";
import "./css/Profile.css";
import Header from "./Header";
import Leftnav from "./Leftnav";
import Variables from "./variables";
import axios from "axios";
import $ from "jquery";
import Billings from "./Profile/Billings";

function Profile() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [plan, setPlan] = useState("");
  const [country, setCountry] = useState("Unknown");
  const [mailchimp, setMailchimp] = useState("");
  const [profile, setProfile] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [api_key, setApi_key] = useState("");
  const [openai_api_key, setOpenai_Api_key] = useState("");
  const [isPaidCustomer, setIsPaidCustomer] = useState(false);
  const [paymentMethods,setPaymentMethods]=useState([])
  const [unpaidInvoices,setUnpaidInvoices]=useState([])
  const [usage,setUsage]=useState([])
  const [credits, setCredits]=useState([])
  const [data, setData]=useState([])
  const [cancelled,setCancelled]=useState(false)
  useEffect(() => {
    async function checkAuth() {
      let status = await Variables.checkAuthentication();
      let req_data = Variables.create_req_data({});
      await axios
        .post(Variables.serverUrl("settings/"), req_data)
        .then((res) => {
          let profile = res.data.profile;
          setData(res.data)
          setName(res.data.name);
          setPaymentMethods(res.data.payment_methods)
          setOrganisation(profile.organisation);
          if(profile.country){
            setCountry(Object.values(JSON.parse(profile.Country)));
          }
          setEmail(res.data.email);
          setIsPaidCustomer(res.data.is_paid_customer);
          setUnpaidInvoices(res.data.unpaid_invoices)
          setPlan(profile.plan);
          setCancelled(profile.cancel_requested)
          setApi_key(profile.api_key);
          setUsage(res.data.usage)
          setCredits(res.data.credits)
          setMailchimp(profile.mail_chimp_key);
          setOpenai_Api_key(profile.openai_key);
          setProfile(profile);
          if (profile.mail_chimp_key) {
            $("#integrations .mailchimp button").html("Revoke");
            $("#integrations .mailchimp button").attr("action", "revoke");
          }
          if (profile.notion_key) {
            $("#integrations .notion button").html("Revoke");
            $("#integrations .notion button").attr("action", "revoke");
          }
        });
    }
    checkAuth.call();
    const links = document.getElementsByClassName("left-nav-link");
    for (let i = 0; i < links.length; i++) {
      links[i].classList.remove("active");
    }
    document.getElementsByClassName("profile-link")[0].classList.add("active");
  }, []);
  return (
    <>
      <div className="App">
        <Leftnav />
        <div className="App-body">
          <header className="sticky-top">
            <Header />
          </header>
          <div id="profile">
            <h1>Profile</h1>
            <br />
            <Section1
              data={Object.assign({
                name: name,
                email: email,
                country: country,
                plan: plan,
                organisation: organisation,
                profile: profile,
                usage:usage,
                credits:credits,
                cancelled:cancelled
              },data)}
            />
            <hr />
            <div className="row">
              <Section3
                data={{
                  api_key: api_key,
                  openai_key: openai_api_key,
                }}
              />
            </div>
            <hr />
            {}
            {isPaidCustomer && !cancelled ? (
              <>
                <Billings methods={paymentMethods} setMethods={setPaymentMethods} unpaidInvoices={unpaidInvoices}/>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
