import $ from "jquery";
import axios from "axios";
import countries from "./data/countries.json";

const backendServer = "https://xbotserver.sharurai.com";
// const backendServer = "http://127.0.0.1:8000";

const Variables = {
  server: backendServer,
  serverUrl: function (path) {
    return backendServer + "/" + path;
  },
  webSocketURL:"wss://1wvu40dnsi.execute-api.us-east-2.amazonaws.com/production/",
  loading_btn: function (ele, txt) {
    if ($(ele).find(".spinner-border").length < 1) {
      $(ele).prop("disabled", true);
      let btn_txt = $(ele).html();
      $(ele).attr("btn-text", btn_txt);
      $(ele).html(`
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span class="sr-only">${txt}...</span>
        `);
    }
  },
  unloading_btn: function (ele) {
    if ($(ele).find(".spinner-border").length > 0) {
      $(ele).empty();
      let btn_txt = $(ele).attr("btn-text");
      $(ele).removeAttr("btn-text");
      $(ele).html(btn_txt);
      $(ele).prop("disabled", false);
    }
  },
  checkAuthentication: async function () {
    let authenticated = false;
    let req_data = {
      access_key: localStorage.getItem("sharurai_access_key"),
      refresh_key: localStorage.getItem("sharurai_refresh_key"),
    };
    let member_login = localStorage.getItem("sharurai_member_login");
    req_data["member_login"] = member_login;

    await axios
      .post(Variables.serverUrl("authenticate/"), req_data)
      .then(async (res) => {
        if (res.data.status > 400 || res.status > 499) {
          authenticated = false;
          if (res.data.relogin) {
            localStorage.removeItem("sharurai_access_key");
            localStorage.removeItem("sharurai_refresh_key");
            localStorage.removeItem("sharurai_member_login");
            window.location.href = "/login";
          }
        } else if (res.data.status > 200 && res.data.status < 400) {
          authenticated = true;
        }
        if (res.data.profile[0].plan == "unpaid") {
          $("#banner, .choose-plan-banner").show();
        }
        if (
          res.data.profile[0].plan != "unpaid" &&
          res.data.profile[0].chat_credit < 500
        ) {
          $("#banner, .message-alert-banner").show();
        }
        if (new Date(res.data.profile[0].valid_till) < new Date().getDate()) {
          $("#banner,.plan-expired-banner").show();
        }
      })
      .catch((err) => {
        localStorage.removeItem("sharurai_access_key");
        localStorage.removeItem("sharurai_refresh_key");
        localStorage.removeItem("sharurai_member_login");
        window.location.href = "/login";
      });
    return authenticated;
  },
  create_req_data: function (ele) {
    ele["access_key"] = localStorage.getItem("sharurai_access_key");
    ele["refresh_key"] = localStorage.getItem("sharurai_refresh_key");
    let member_login = localStorage.getItem("sharurai_member_login");
    ele["member_login"] = member_login;
    return ele;
  },
  getBotTypeName: (data) => {
    let type;
    switch (data) {
      case "wh":
        type = "Whatsapp";
        break;
      case "web":
        type = "Web";
        break;
      case "sl":
        type = "Slack";
        break;
      case "sf":
        type = "Shopify";
        break;
      case "api":
        type = "API";
        break;
      case "ms":
        type = "Messenger";
        break;
      default:
        type = "";
    }
    return type;
  },
  toCountryName: (code) => {
    let name;
    countries.forEach((obj) => {
      if (obj.code == code) {
        name = obj.name;
      }
    });
    if (name == "" || name == undefined) {
      name = "Unknown";
    }
    return name;
  },
  toLanguageName: (code) => {
    let languages = {
      HN: "Hindi",
      EN: "English",
      CH: "Chinese",
      FR: "French",
      GR: "German",
      PR: "Portuguese",
      IT: "Italian",
      RS: "Russian",
      AR: "Arabic",
    };
    return languages[code];
  },
  leadsAnalytics: (id) => {
    function formatDate(date) {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [day, monthNames[parseInt(month) - 1]].join(" ");
    }
    const currentDate = new Date();
    const fromDate = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000);
    let req_data = Variables.create_req_data({
      id: id,
      action: "analytics",
      from_date: fromDate,
    });
    var data = [];
    return axios
      .post(Variables.serverUrl("submission/"), req_data)
      .then(async (res) => {
        let d = res.data.data;
        let yesterday = 0;
        let today = 0;
        for (var key in d) {
          let today_date = new Date(
            new Date().toDateString()
          ).toLocaleDateString();
          let yesterday_date = new Date(
            new Date(
              new Date().getTime() - 1 * 24 * 60 * 60 * 1000
            ).toDateString()
          ).toLocaleDateString();
          if (today_date == new Date(key).toLocaleDateString()) {
            today = d[key];
          }
          if (yesterday_date == new Date(key).toLocaleDateString()) {
            yesterday = d[key];
          }
          data.push({
            name: formatDate(key),
            Leads: d[key],
          });
        }
        return { yesterday: yesterday, today: today, data: data };
      });
  },
  change_icon: async function (file, remove, data) {
    let res;
    let files = new FormData();
    files.append("access_key", localStorage.getItem("sharurai_access_key"));
    files.append("refresh_key", localStorage.getItem("sharurai_refresh_key"));
    files.append("upload", file);
    files.append("remove", remove);
    files.append("img_url", data.url);
    files.append("file_type", file.type.split(".").pop());
    files.append("bot_id", data.bot_id);

    await $.ajax({
      type: "post",
      url: Variables.serverUrl("update-icon/"),
      processData: false,
      contentType: false,
      data: files,
      success: function (response) {
        res = response;
      },
      error: function (err) {
        // console.log(err);
      },
    });
    return res;
  },
  upload_document: async function (file) {
    let res;
    let files = new FormData();
    files.append("access_key", localStorage.getItem("sharurai_access_key"));
    files.append("refresh_key", localStorage.getItem("sharurai_refresh_key"));
    files.append("upload", file);
    let file_type=file.type.split(".").pop()
    if(file_type.includes("/")){
      let t=file_type.split('/')
      file_type=t[t.length-1]
    }
    files.append("file_type", "."+file_type);

    await $.ajax({
      type: "post",
      url: Variables.serverUrl("upload-doc/"),
      processData: false,
      contentType: false,
      data: files,
      success: function (response) {
        res = response;
      },
      error: function (err) {
        // console.log(err);
      },
    });
    return res;
  },
  remove_document: async function (url) {
    let res;
    let files = new FormData();
    files.append("access_key", localStorage.getItem("sharurai_access_key"));
    files.append("refresh_key", localStorage.getItem("sharurai_refresh_key"));
    files.append("url", url);

    await $.ajax({
      type: "post",
      url: Variables.serverUrl("remove-doc/"),
      processData: false,
      contentType: false,
      data: files,
      success: function (response) {
        res = response;
      },
      error: function (err) {
        // console.log(err);
      },
    });
    return res;
  },
  validateURL: function (string) {
    if (/(http(s?)):\/\//i.test(string)) {
      return true;
    } else {
      return false;
    }
  },
  updateUrlsInHtml: function (htmlText) {
    const urlRegex = /(\bhttps?:\/\/\S+\b)/gi;
    const updatedHtml = htmlText.replace(
      urlRegex,
      '<a href="$1" target="_blank">$1</a>'
    );
    return updatedHtml;
  },
  formatDate: (dateString) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month}, ${year}`;
  },
};

export default Variables;
