import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "../css/NewBot.css";
import Variables from "../variables";
import $ from "jquery";
import axios from "axios";

function NewBot({ show, handleClose }) {
  async function create_new_bot() {
    Variables.loading_btn("#create-new-bot-btn", "Creating");
    let bot_name = $("#new-bot-name").val();
    let description = $("#new-bot-description").val();
    let bot_type = $("#new-bot-type").val();
    let req_data = Variables.create_req_data({
      name: bot_name,
      description: description,
      bot_type: bot_type,
    });
    if (bot_name != "" && description != "" && bot_type != "") {
      await axios
        .post(Variables.serverUrl("create-new-bot/"), req_data)
        .then(async (res) => {
          console.log(res);
          if (res.data.status > 400) {
            if (res.data.relogin) {
              localStorage.removeItem("sharurai_access_key");
              localStorage.removeItem("sharurai_refresh_key");
              window.location.href = "/login";
            }
          } else if(res.data.status=='err'){
            $("#create-new-bot-btn").hide()
            $(".modal-body").html(`<b>${res.data.msg}</b>`)
          } else if (res.data.status >= 200) {
            window.location.href = "/app/insights?id=" + res.data.bot_id;
          }
        });
    }
  }
  return (
    <>
      <Modal
        id="new-bot"
        show={show}
        onHide={handleClose}
        backdrop="static"
        fullscreen="md-down"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create a new bot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>Name your bot</label>
          <input id="new-bot-name" className="form-control" maxLength={20} />
          <small style={{ color: "grey" }}>Max. length is 20 letters</small>
          <br />
          <label>Description</label>
          <textarea
            class="form-control"
            id="new-bot-description"
            maxLength={120}
          ></textarea>
          <small style={{ color: "grey" }}>Max. length is 80 letters</small>
          <br />
          <label>Channel</label>
          <select className="form-control" id="new-bot-type">
            <option value="web">Web</option>
          </select>
          <small style={{ color: "grey" }}>Choose a platform for your chatbot</small>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            id="create-new-bot-btn"
            className="theme-btn"
            variant="primary"
            onClick={create_new_bot}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NewBot;
