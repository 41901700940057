import { useEffect, useState } from "react";
import IntentModal from "../modals/IntentModal";
import Variables from "../variables";
import axios from "axios";
import $ from "jquery";
import pencil from "../images/pencil.svg";
import EditBtn from "../modals/EditBtn";

function Section1({ botID, forms, setRequestedJsonData, setRaiseTicket, setTicketData }) {
  const [showIntentModal, setShowIntentModal] = useState(false);
  const [intentModalActionType, setIntentModalActionType] = useState("");
  const [intentID, setIntentID] = useState("");
  const [intents, setIntents] = useState([]);
  const handleIntentModalClose = () => setShowIntentModal(false);
  const [intent, setIntent] = useState([]);

  const [btnEle, setBtnEle] = useState("");
  const [showEditBtn, setEditBtnShow] = useState(false);
  const handleEditBtnClose = () => setEditBtnShow(false);
  const handleEditBtnShow = function (e) {
    setBtnEle(e.target);
    setEditBtnShow(true);
  };
  const [editingElement, setEditingElement] = useState("");

  const handleIntentModalShow = function (e) {
    setIntentModalActionType("Create");
    setShowIntentModal(true);
  };

  const handleIntentDeleteModalShow = function (e) {
    setIntentModalActionType("Delete");
    setIntentID($(e.target).attr("uid"));
    setShowIntentModal(true);
  };

  function editIntent(e) {
    if(!$(e.target).parent('li').hasClass("active")) {
      editIntentFunc(e);
    }
  }

  function editIntentFunc(e) {
    $("#intent-settings,#intent-test").show();
    $("#all-intents > ul > li").removeClass("active");
    $(e.target).parent('li').addClass("active");
    let intent_id = $(e.target).attr("id");
    (async () => {
      let req_data = Variables.create_req_data({
        action: "get",
        id: intent_id,
      });
      await axios.post(Variables.serverUrl("intent/"), req_data).then((res) => {
        let intent = JSON.parse(res.data.intent)[0];
        let action = JSON.parse(intent.fields.action);
        if(action.ticket!=undefined){
          setRaiseTicket(true)
          setTicketData(action)
        }else{
          setRaiseTicket(false)
        }
        let response = JSON.parse(intent.fields.response);
        if(action.requested_json_body!==undefined){
          setRequestedJsonData(action.requested_json_body)
        }else{
          setRequestedJsonData([])
        }
        setIntent(intent);
        $("#intent-name").val(intent.fields.name);
        $("#intent-description-area").html(intent.fields.description);
        $("#intent-code").val(intent.fields.intent);
        $("#intent-description-save-btn").attr("uid", intent.pk);
        $("#intent-examples-save-btn,#intent-test-btn").attr("uid", intent.pk);
        $(`#intent-action`).val(action.action_type).change()
        $(`#endpoint-method`).val(action.endpoint_method).change()
        $("#intent-response").val('')
          if (action.action_type == "webhook") {
            $("#ask-for-info .container,#endpoint").show();
            $("#intent-response").hide();
            $("#endpoint-url").val(action.endpoint_url)
            if (action.action_switch) {
              $("#ask-for-switch").prop("checked", true);
              $("#ask-for-switch-text").val(action.ask_for_text);
              $("#ask-for-info .container").show();
              $("#preset-form").val(action.preset_form)
            } else {
              $("#ask-for-switch").prop("checked", false);
              $("#ask-for-info .container").hide()
            }
          } else {
            $("#ask-for-info,#endpoint").hide();
            $("#intent-response").show();
            $("#ask-for-switch").prop("checked", false);
            $("#ask-for-info .container").hide();
            $("#intent-response").val(response.response);
          }
          $("#response-preset-btns li").remove();
          if (response.buttons!=undefined && response.buttons.length) {
            response.buttons.forEach((obj) => {
              let element = $(`
                <li style="align-items:center;">
                    <div class="theme-btn limit-text preset-btn">${obj.name}</div>
                    &nbsp; &nbsp;
                    <img class="edit-preset-btns" src="${pencil}" do="Edit" name="${obj.name}" action-type="${obj.action}" data="${obj.data}" style="height:18px;" />
                    <font class="remove-btn">x</font>
                  </li>
              `);
              $(element)
                .find(".remove-btn")
                .on("click", function () {
                  $(element).remove();
                });

              $(element)
                .find(".edit-preset-btns")
                .on("click", function () {
                  handleEditBtnShow({ target: $(element).find("img") });
                });

              $("#preset-btns, #response-preset-btns").prepend(element);
            });
          }

          if (response.cards!=undefined && response.cards.length) {
            response.cards.forEach((obj) => {
              let element = $(`
              <li class="card ui-state-default">
              <div class="overlay" img-url="${obj['img-url']}" title="${obj.title}" description="${obj.description}" button-name='${obj['button-name']}' button-action-type="${obj['button-action-type']}" button-data="${obj['button-data']}">
                <h4>Edit</h4>
                <b class="remove-card">
                  x
                </b>
              </div>
              <div class="top">
                <div class="container card-img" style="background:url('${obj['img-url']}')">
                  <img src="${obj['img-url']}" />
                </div>
              </div>
              <div class="card-body">
                <b class="card-title">
                  ${obj.title}
                </b>
                <br />
                <p class="card-description">
                  ${obj.description}
                </p>
                <button class="card-btn btn btn-sm btn-warning">
                  ${obj['button-name']}
                </button>
              </div>
            </li>
              `)
              $(element)
                .find(".overlay")
                .on("click", function () {
                  let target;
                  $("#cards li").removeClass("active");
                  $(this).closest("li").addClass("active");
                  setEditingElement(this)
                  let img_url = $(this).attr("img-url");
                  let card_title = $(this).attr("title");
                  let card_description = $(this).attr("description");
                  let card_btn_text = $(this).attr("button-name");
                  let card_btn_action = $(this).attr("button-action-type");
                  let card_btn_data = $(this).attr("button-data");
                  $("#card-editor").show();
                  $("#card-title-editor").val(card_title);
                  $("#card-description-editor").val(card_description);
                  $("#card-button-name-editor").val(card_btn_text);
                  $("#card-btn-action").val(card_btn_action).change();
                  $("#card-editor .card-btn-action-res:visible").val(card_btn_data);
                })
              $(element)
                .find(".remove-card")
                .on("click", function () {
                  $(this)
                    .closest("li")
                    .fadeOut(300, async function () {
                      let url=$(this).find('.overlay').attr('img-url')
                      if(Variables.validateURL(url)){
                        await Variables.remove_document(url)
                      }
                      $(this).remove();
                    });
                    $("#card-editor").hide()
                })

              $("#sortable-cards").prepend(element);
            });
          }

          $("#all-examples").html("");
          try{
            JSON.parse(intent.fields.example).forEach((obj, i) => {
              let ele = $(`<div class="example">
            <input
              class="limit-text form-control desc"
              value="${obj.desc}"
            />:
            <input
              class="form-control limit-text code"
              style='width: fit-content; max-width: 50px !important;'
              value="${obj.code}"
            />
            <b
              class="remove-example" style='font-size: 1.1rem; cursor: pointer;'
            >
              &nbsp;x
            </b>
          </div>`);
              $(ele)
                .find(".remove-example")
                .on("click", function () {
                  $(this).parent(".example").remove();
                });
              $("#all-examples").prepend(ele);
            })
          }catch(e){
            // console.log(e)
          }
        $("#examples").prepend();
      });
    })();
  }
  useEffect(() => {
    (async () => {
      let req_data = Variables.create_req_data({
        action: "get_all",
        bot_id: botID,
      });
      await axios.post(Variables.serverUrl("intent/"), req_data).then((res) => {
        setIntents(res.data.intent);
      });
    })();
  }, []);
  return (
    <div className="col-md-4 col-sm-12 col-lg-3 left" style={{borderRight:"1px solid #e2e2e2"}}>
      <button
        className="btn btn-sm btn-dark round-btn"
        onClick={handleIntentModalShow}
      >
        + Add Intent
      </button>&nbsp;&nbsp;<button className="btn round-btn btn-sm btn-light" onClick={()=>window.open('https://sharurai.com/intent-webhook', '_blank')}>🛈 Help</button>
      <br />
      <br />
      <div id="all-intents" style={{ "max-height": "550px" }}>
        <ul>
          {(() => {
            if (intents.length) {
              return (
                <>
                  {intents.map((obj) => (
                    <li>
                      <font className="intent" id={obj.uid} onClick={editIntent}>{obj.name} </font>
                      <font
                        className="delete-intent"
                        uid={obj.uid}
                        onClick={handleIntentDeleteModalShow}
                      >
                        &#x292B;
                      </font>
                    </li>
                  ))}
                </>
              );
            }else{
              return (<div height="100%" style={{marginTop:"8rem",padding:".5rem",color:"gray",fontStyle:"italic",background:"#fcf8ec"}}>No intent was found. Please create one to continue.</div>)
            }
          })()}
        </ul>
      </div>
      <IntentModal
        show={showIntentModal}
        handleClose={handleIntentModalClose}
        modalActionType={intentModalActionType}
        handleShow={handleIntentModalShow}
        id={intentID}
      />
      <EditBtn
        show={showEditBtn}
        handleClose={handleEditBtnClose}
        ele={btnEle}
        handleShow={handleEditBtnShow}
        forms={forms}
      />
    </div>
  );
}

export default Section1;
