import { Link } from "react-router-dom";
import "./css/banner.css";

let is_member = localStorage.getItem("sharurai_member_login");

function Banner() {
  if (is_member == "false") {
    return (
      <div id="banner">
        <div className="banner choose-plan-banner">
          <div className="container row">
            <div className="col-md-8 col-lg-8 col-sm-12">
              <b>
                You have not chosen any plan yet. Please choose a plan to start
                using our services.
              </b>
            </div>
            <div className="col-md-4 col-lg-4 col-sm-12">
              <Link to="/plans" className="btn btn-warning btn-sm round">
                Choose plan
              </Link>
            </div>
          </div>
        </div>
        <div className="banner message-alert-banner alert">
          <div className="container row">
            <div className="col-md-9 col-lg-9 col-sm-12">
              <font>
                <b style={{ color: "red" }}>&#x26A0;</b> You are running out of
                message/chat or token credits. Get additional message/token credits to continue
                interacting with your customers.
              </font>
            </div>
            <div className="col-md-3 col-lg-3 col-sm-12" style={{"justify-content": "space-around"}}>
              <Link to="/plans" className="btn btn-success btn-sm round">
                Get credit
              </Link>
              <Link to="/app/profile" className="btn btn-dark btn-sm round">
                🛈 Check usage
              </Link>
            </div>
          </div>
        </div>
        <div className="banner plan-expired-banner alert">
          <div className="container row">
            <div className="col-md-10 col-lg-10 col-sm-12">
              <font>
                <b style={{ color: "red" }}>&#x26A0;</b> Your plan has expired.
                Please recharge again to start using Xbot's services.
              </font>
            </div>
            <div className="col-md-2 col-lg-2 col-sm-12">
              <Link to="/plans" className="btn btn-success btn-sm round">
                Choose plan
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    <></>;
  }
}

export default Banner;
