import { useEffect, useLayoutEffect, useRef, useState } from "react";
import React from "react";
import $, { contains, ready } from "jquery";
import Variables from "./variables";
import Banner from "./banner";
import HomeMenu from "./home/HomeMenu";
import Leftnav from "./Leftnav";
import Header from "./Header";
import user from "./images/user.svg";
import "./css/livechat.css";
import send from "./images/send-white.svg";
import attachment from "./images/attachment.png";
import formImg from "./images/form.png";
import doc from "./images/document.png";
import SelectForm from "./modals/SelectForm";
import CreateCards from "./modals/CreateCards";
import email from "./images/email-dark.png";
import ticketIcon from "./images/ticket.png";
import axios from "axios";
import { Link } from "react-router-dom";
import { OverlayTrigger, Popover } from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";
import ComposeMail from "./toasts/ComposeMail";
import CreateTicket from "./modals/CreateTicket";

let member_login = localStorage.getItem("sharurai_member_login");
if (member_login == "true") {
  member_login = true;
} else {
  member_login = false;
}

function getTimeAgo(timestamp) {
  const utcDate = new Date(timestamp);
  const localDate = new Date(
    utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
  );

  const currentTime = new Date();

  const timeDifference = currentTime - localDate;

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  const options = { year: "numeric", month: "short", day: "numeric" };
  const formattedDate = localDate.toLocaleDateString(undefined, options);

  if (seconds < 60) {
    return `${seconds} seconds ago`;
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (hours < 24) {
    return `${hours} hours ago`;
  } else if (localDate.toDateString() === currentTime.toDateString()) {
    return "Today";
  } else {
    return formattedDate;
  }
}

function convertLinksToHTML(text) {
  const linkRegex = /(\bhttps?:\/\/[^\s]+)/gi;

  const htmlText = text.replace(
    linkRegex,
    '<a href="$1" style="color:black;" target="_blank">$1</a>'
  );

  return htmlText;
}

const timestamp = "2024-03-08 17:48:40";
const timeAgo = getTimeAgo(timestamp);

function select_files() {
  $("#select-files").trigger("click");
}

function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; // The maximum is inclusive and the minimum is inclusive
}

function getTimeDifference(utcTimestamp) {
  const utcDate = new Date(utcTimestamp);

  const localDate = new Date();

  const localToUtcOffset = localDate.getTimezoneOffset() * 60000;
  const localToUtcDate = new Date(localDate.getTime() + localToUtcOffset);

  const timeDifference = localToUtcDate - utcDate;

  const minutesDifference = Math.floor(timeDifference / (1000 * 60));

  const hours = Math.floor(minutesDifference / 60);
  const minutes = minutesDifference % 60;

  let result = "";
  if (hours > 0) {
    result += hours + " hour(s) ";
  }
  if (minutes > 0) {
    result += minutes + " minute(s)";
  }

  return result.trim() || "0 minutes";
}

function formatBytes(bytes) {
  if (bytes < 1024) {
    return bytes + " Bytes";
  } else if (bytes < 1048576) {
    // 1024 * 1024
    return (bytes / 1024).toFixed(2) + " KB";
  } else if (bytes < 1073741824) {
    // 1024 * 1024 * 1024
    return (bytes / 1048576).toFixed(2) + " MB";
  } else {
    return (bytes / 1073741824).toFixed(2) + " GB";
  }
}

export default function () {
  let botID = "";
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const extracted_id = params.get("id");
  botID = extracted_id;
  const total_live_users = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [activeConnectionId, setActiveConnectionId] = useState("");
  const [showComposeEmail, setShowComposeEmail] = useState(false);

  const [showFrom, setShowFrom] = useState(false);
  const handleFromClose = () => setShowFrom(false);
  const handleFormShow = () => setShowFrom(true);
  const [showCards, setShowCards] = useState(false);
  const handleCardsClose = () => setShowCards(false);
  const handleCardsShow = () => setShowCards(true);
  const [disableChatInput, setDisableChatInput] = useState(true);
  const [chat, setChat] = useState(false);
  const [profileId, setProfileId] = useState("");
  const [readyToConnect, setReadyToConnect] = useState(false);
  const [socketUrl, setSocketUrl] = useState("");
  const [ws, setWs] = useState(null);
  const [liveChatNoAccess, setLiveChatNoAccess] = useState(false);
  const [creditExhausted, setCreditExhausted] = useState(false);
  const [ticketShow, setTicketShow] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [liveChat, setLiveChat] = useState(false);
  const [inputMessage, setInputMessage] = useState("");
  const [showChatBtn, setShowChatBtn] = useState(true);
  const [connectedUser, setConnectedUser] = useState({});
  const connectedUserRef = useRef({});

  useEffect(() => {
    connectedUserRef.current = connectedUser;
  }, [connectedUser]);

  function filesSelected(e) {
    const input = document.getElementById("select-files");
    const newFiles = Array.from(input.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  }
  function removeDoc(event) {
    const listItem = event.target.closest("li");
    const list = listItem.parentNode;
    const index = Array.prototype.indexOf.call(list.children, listItem);
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  }
  async function upload_send_files() {
    //This section will be edited later
    let type = "bot";
    let file_pkg = "";
    selectedFiles.forEach(async (ele, i) => {
      let size = formatBytes(ele.size);
      let file=await Variables.upload_document(ele)
      ws.send(
        JSON.stringify({
          action: "message",
          message_type: "human",
          send_to: connectedUser,
          query: {
            name: ele.name,
            type: ele.type,
            size: size,
            url: file.url,
          },
          query_type: "file",
          type: "admin",
        })
      );
      // file_pkg += `
      // <div class="file" style="display:flex;flex-direction:column;">
      //   <a href="http://google.com" style="color:black;" target="_blank">File: ${ele.name}</a>
      //   <i style="font-size:.8rem;margin-bottom:10px;">${ele.type} <font>${size}</font><br/></i>
      // </div>
      // `;
    });
    // $("#livechat-panel .chat-panel .messages").append(
    //   `<li class="${type}-message message"><div style="max-width: 70%;"><b style="font-size: 0.8rem; color: grey;">ffda slkj (<img src=${attachment} height="10px"/><font>Attachment</font>)</b><div class="text" style="padding:10px;">${file_pkg}</div></div></li>`
    // );
    setSelectedFiles([]);
  }
  async function sendForm(form) {
    if (Object.keys(form).length) {
      ws.send(
        JSON.stringify({
          action: "message",
          message_type: "human",
          send_to: connectedUser,
          query: {
            title: form.title,
            uid: form.uid,
          },
          query_type: "form",
          type: "admin",
        })
      );
    }
  }
  function sendTextMessage() {
    if (connectUser && inputMessage) {
      ws.send(
        JSON.stringify({
          action: "message",
          message_type: "human",
          send_to: connectedUser,
          query: inputMessage,
          query_type: "text",
          type: "admin",
        })
      );
      setInputMessage("");
    }
  }
  useEffect(() => {
    let form = $("#message-form").find("input,button");
    if (selectedFiles.length) {
      $("#files-panel").show();
      form.prop("disabled", false);
    } else {
      $("#files-panel").hide();
    }
  }, [selectedFiles]);

  const [memberID,setMemberID]=useState("")

  useLayoutEffect(() => {
    async function checkAuth() {
      let status = await Variables.checkAuthentication();
    }
    checkAuth.call();
    const links = document.getElementsByClassName("menu-link");
    for (let i = 0; i < links.length; i++) {
      links[i].classList.remove("active");
    }
    $(".livechat-link").addClass("active");
    (async () => {
      let req_data = Variables.create_req_data({
        id: botID,
      });
      await axios
        .post(Variables.serverUrl("live-chat/"), req_data)
        .then((res) => {
          if (res.status == 200) {
            setChat(true);
            setProfileId(res.data.profile_id);
            setReadyToConnect(true);
            if (res.data.no_access !== undefined) {
              setLiveChatNoAccess(true);
            }
            if (res.data.no_credit !== undefined) {
              setCreditExhausted(true);
            }
          } else {
            setChat("not_allowed");
          }
        });
    })();
  }, []);

  function createTicket(ticketID) {
    ws.send(
      JSON.stringify({
        action: "message",
        message_type: "human",
        send_to: connectedUser,
        query: `We have successfully raised a ticket for your inquiry. Your ticket ID is [${ticketID}]. Our team will look into it promptly, and we'll keep you updated on the progress.`,
        query_type: "text",
        type: "admin",
      })
    );
    setTicketShow(false);
  }

  useEffect(() => {
    setSocketUrl(
      Variables.webSocketURL + "?bot_id=" + botID + "&profile_id=" + profileId
    );
    if(botID && profileId){
      setReadyToConnect(true);
    }
    
  }, [profileId]);

  const [activeUsers, setActiveUsers] = useState({});

  function refreshActiveUsers() {
    let users = Object.assign({}, activeUsers);
    Object.keys(users).forEach((key) => {
      let tmp_msg_lst = [];
      try {
        if (typeof users[key].messages !== "object") {
          users[key].messages = JSON.parse(users[key].messages);
        }
        let messages = users[key].messages;
        messages.forEach((item) => {
          if (item.message_type == "group") {
            Object.keys(item.message).forEach((k) => {
              if (k == "buttons") {
                let x = { ...item };
                x.message = item.message[k];
                x.message_type = k;
                tmp_msg_lst.push(x);
              }
              if (k == "cards") {
                let x = { ...item };
                x.message = item.message[k];
                x.message_type = k;
                tmp_msg_lst.push(x);
              }
              if (k == "text") {
                let x = { ...item };
                x.message = item.message["response"];
                x.message_type = k;
                tmp_msg_lst.push(x);
              }
            });
          } else {
            tmp_msg_lst.push(item);
          }
        });
        users[key].messages = tmp_msg_lst;
      } catch (e) {
        //pass
      }
    });
  }
  const activeUsersRef = useRef({});
  useEffect(() => {
    refreshActiveUsers();
    activeUsersRef.current = activeUsers;
  }, [activeUsers]);

  useEffect(() => {
    let is_valid_member=true
    if(liveChatNoAccess && member_login){
      is_valid_member=false
    }
    if (readyToConnect && is_valid_member) {
      // sendMessage, lastMessage, readyState  = useWebSocket(socketUrl);
      const webSocket = new WebSocket(socketUrl);
      // Set up event listeners
      let token = localStorage.getItem("sharurai_access_key");
      webSocket.onopen = () => {
        webSocket.send(
          JSON.stringify({
            action: "message",
            message_type: "system",
            system_event: "init",
            user_type: "admin",
            token: token,
            is_member: member_login,
            member_id:memberID || ""
          })
        );
        setLiveChat(true);
        const interval = setInterval(() => {
          setActiveUsers((prevState) => ({
            ...prevState,
            ["time"]: getRandomInt(1, 100),
          }));
        }, 1000);
      };

      webSocket.onmessage = (event) => {
        try {
          let msg = JSON.parse(event.data);
          if (msg.active_users !== undefined) {
            msg.active_users["time"] = 0;
            let users = msg.active_users;
            setActiveUsers(users);
          }
          if (msg.new_user_connected !== undefined) {
            let key = msg.connection_id;
            let val = msg.data;
            try {
              val.messages = JSON.parse(val.messages);
            } catch (e) {
              //pass
            }
            setActiveUsers((prevData) => ({ [key]: val, ...prevData }));
          }
          if (msg.a_user_disconnected !== undefined) {
            let id = msg.connection_id;
            if (connectedUserRef.current == id) {
              setLiveChat(false);
            } else {
              setActiveConnectionId("");
            }
            setActiveUsers((prevState) => {
              const { [id]: _, ...newState } = prevState;
              return newState;
            });
          }
          if (msg.user_connected !== undefined) {
            setLiveChat(true);
            setConnectedUser(msg.connection_id);
            setDisableChatInput(false);
          }

          if (msg.user_info !== undefined) {
            let id = msg.connection_id;
            let users = activeUsersRef.current;
            let d = JSON.parse(msg.data);
            users[id]["name"] = d.name || "";
            users[id]["email"] = d.email || "";
            setActiveUsers(users);
          }

          if (msg.message_type !== undefined) {
            let id = msg.connection_id;
            let users = activeUsersRef.current;
            users[id]["messages"].push(msg);
            setActiveUsers(users);
          }
        } catch (e) {
          // pass
        }
      };
      webSocket.onclose = () => {
        setLiveChat(false);
        setDisableChatInput(true);
        // console.log("WebSocket connection closed.");
      };

      webSocket.onerror = (error) => {
        // console.error("WebSocket error:", error);
      };

      setWs(webSocket);

      return () => {
        webSocket.close();
      };
    }
  }, [socketUrl, readyToConnect]);

  const handleUserSelection = (data) => {
    $("#live-users li").removeClass("active");
    setActiveConnectionId(data.connection_id);
    setDisableChatInput(true);
    let user = activeUsers[data.connection_id];
    user["connection_id"] = data.connection_id;
    setCurrentUser(user);
    $(data.e.currentTarget).addClass("active");
  };

  async function send_message(e) {
    e.preventDefault();
    if (selectedFiles.length) {
      await upload_send_files();
    }
    if (inputMessage) {
    }
  }

  function connectUser() {
    let id = currentUser.connection_id;
    ws.send(
      JSON.stringify({
        action: "message",
        message_type: "system",
        system_event: "switch_user",
        connection_id: id,
      })
    );
  }

  return (
    <>
      <div className="App">
        <Leftnav />
        <div className="App-body">
          <header className="sticky-top">
            <Header />
            <Banner />
          </header>
          <div className="home-container sticky-top">
            <HomeMenu />
            {chat == true ? (
              <>
                <br />
                {member_login && liveChatNoAccess ? (
                  <div className="alert alert-warning"><b>You do not have the live chat access. Please ask your admin for the access.</b></div>
                ) : (
                  <>
                    <div
                      id="livechat-panel"
                      className="flex-horizon"
                      style={{ padding: "5px 0", alignItems: "unset" }}
                    >
                      <input
                        id="select-files"
                        type="file"
                        z
                        multiple="multiple"
                        style={{ display: "none" }}
                        onChange={filesSelected}
                      />
                      <div
                        className="conv-list"
                        style={{
                          maxWidth: 350,
                          borderRight: "1px solid #d8d8d8",
                        }}
                      >
                        <div className="container" style={{ padding: "0" }}>
                          <p>
                            {Object.keys(activeUsers).length ? (
                              <>
                                <b>{Object.keys(activeUsers).length - 1}</b>
                              </>
                            ) : (
                              <>
                                <b>{Object.keys(activeUsers).length}</b>
                              </>
                            )}{" "}
                            live user(s)
                          </p>
                          <ul id="live-users">
                            {Object.entries(activeUsers).map(([key, value]) => (
                              <>
                                {key == "time" ? (
                                  <></>
                                ) : (
                                  <>
                                    <li
                                      onClick={(e) => {
                                        handleUserSelection({
                                          e: e,
                                          connection_id: key,
                                        });
                                        if (
                                          connectedUser.connection_id !==
                                            undefined &&
                                          key == connectedUser.connection_id
                                        ) {
                                          setShowChatBtn(false);
                                        } else {
                                          setShowChatBtn(true);
                                        }
                                      }}
                                    >
                                      <div className="name">
                                        <img src={user} />
                                        {value.name ? (
                                          <>
                                            <font>{value.name}</font>
                                          </>
                                        ) : (
                                          <>Unknown</>
                                        )}
                                      </div>
                                      <div className="description">
                                        <div className="first-msg">
                                          <font className="limit-text">
                                            {typeof value.messages ==
                                            "object" ? (
                                              <>
                                                {value.messages.length ? (
                                                  <>
                                                    {value.messages[
                                                      value.messages.length - 1
                                                    ].message_type == "text" ? (
                                                      <>
                                                        {
                                                          value.messages[
                                                            value.messages
                                                              .length - 1
                                                          ].message
                                                        }
                                                      </>
                                                    ) : (
                                                      <></>
                                                    )}
                                                    {value.messages[
                                                      value.messages.length - 1
                                                    ].message_type == "form" ? (
                                                      <>Form</>
                                                    ) : (
                                                      <></>
                                                    )}
                                                    {value.messages[
                                                      value.messages.length - 1
                                                    ].message_type ==
                                                    "buttons" ? (
                                                      <>Buttons</>
                                                    ) : (
                                                      <></>
                                                    )}
                                                    {value.messages[
                                                      value.messages.length - 1
                                                    ].message_type ==
                                                    "cards" ? (
                                                      <>Cards</>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>No messages yet.</>
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {JSON.parse(value.messages)
                                                  .length ? (
                                                  <>
                                                    {JSON.parse(value.messages)[
                                                      JSON.parse(value.messages)
                                                        .length - 1
                                                    ].message_type == "text" ? (
                                                      <>
                                                        {
                                                          JSON.parse(
                                                            value.messages
                                                          )[
                                                            JSON.parse(
                                                              value.messages
                                                            ).length - 1
                                                          ].message
                                                        }
                                                      </>
                                                    ) : (
                                                      <></>
                                                    )}
                                                    {JSON.parse(value.messages)[
                                                      JSON.parse(value.messages)
                                                        .length - 1
                                                    ].message_type == "form" ? (
                                                      <>Form</>
                                                    ) : (
                                                      <></>
                                                    )}
                                                    {JSON.parse(value.messages)[
                                                      JSON.parse(value.messages)
                                                        .length - 1
                                                    ].message_type ==
                                                    "buttons" ? (
                                                      <>Buttons</>
                                                    ) : (
                                                      <></>
                                                    )}
                                                    {JSON.parse(value.messages)[
                                                      JSON.parse(value.messages)
                                                        .length - 1
                                                    ].message_type ==
                                                    "cards" ? (
                                                      <>Cards</>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>No messages yet.</>
                                                )}
                                              </>
                                            )}
                                          </font>
                                        </div>
                                        <div className="bottom">
                                          <div className="left">
                                            <b
                                              style={{
                                                color: "#ac8a00",
                                                fontSize: ".8rem",
                                              }}
                                            >
                                              {getTimeDifference(
                                                value.started_at
                                              )}{" "}
                                              ago
                                            </b>
                                          </div>
                                          <div className="left">
                                            {/* {value.bookmarked ? (
                                          <>
                                            <img
                                              src={pin}
                                              height={18}
                                              style={{
                                                marginRight: 10,
                                                opacity: 1,
                                              }}
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <img
                                              src={pin}
                                              height={18}
                                              style={{
                                                marginRight: 10,
                                                opacity: 0.4,
                                              }}
                                            />
                                          </>
                                        )} */}

                                            <b className="msg-notification">
                                              {typeof value.messages ==
                                              "object" ? (
                                                <>{value.messages.length}</>
                                              ) : (
                                                <>
                                                  {
                                                    JSON.parse(value.messages)
                                                      .length
                                                  }
                                                </>
                                              )}
                                            </b>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </>
                                )}
                              </>
                            ))}
                          </ul>
                        </div>
                      </div>

                      {liveChatNoAccess || creditExhausted ? (
                        <>
                          {creditExhausted ? (
                            <>
                              <h4
                                style={{
                                  margin: "auto",
                                  padding: "1rem",
                                  background: "#fff7e7",
                                }}
                              >
                                You have exhausted your chat credits. Please get
                                some extra credits to continue.
                              </h4>
                            </>
                          ) : (
                            <>
                              <div
                                id="upgrade-card"
                                className="card"
                                style={{
                                  maxWidth: "350px",
                                  width: "100% !important",
                                  padding: "1rem",
                                  height: "fit-content",
                                  margin: "auto",
                                }}
                              >
                                <div
                                  className="card-header"
                                  style={{
                                    border: "none",
                                    background: "#ffffff00",
                                  }}
                                >
                                  <h3>🔒 Upgrade Now for Live Chat Access!</h3>
                                </div>
                                <div
                                  className="card-body"
                                  style={{ width: "100%" }}
                                >
                                  <small>
                                    🤝 Take control of the conversation and
                                    engage directly with your users alongside
                                    our powerful bot.
                                    <br />
                                    🚀 Elevate your experience and unlock Live
                                    Chat functionality by upgrading your plan
                                    today!
                                    <br />
                                    🔑 Don't miss out on this essential tool for
                                    enhancing customer support and engagement.
                                    Upgrade now to enjoy all the benefits!
                                  </small>
                                  <br />
                                  <br />
                                  <Link
                                    to="/plans"
                                    className="btn btn-light round-btn"
                                  >
                                    🚀 Upgrade now
                                  </Link>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {Object.keys(activeUsers).length > 1 &&
                          activeConnectionId ? (
                            <>
                              <div className="chat-panel">
                                <div className="top">
                                  <div
                                    className="container flex-horizon"
                                    style={{ justifyContent: "space-between" }}
                                  >
                                    <div className="left">
                                      <b>
                                        {currentUser.name ? (
                                          <>{currentUser.name}</>
                                        ) : (
                                          <>Unknown</>
                                        )}
                                      </b>
                                      &nbsp;&nbsp;
                                      {activeUsers &&
                                      activeUsers.hasOwnProperty(
                                        currentUser.connection_id
                                      ) ? (
                                        <>
                                          <span className="live-status"></span>
                                          &nbsp;
                                          <font style={{ fontSize: ".8rem" }}>
                                            online
                                          </font>
                                        </>
                                      ) : (
                                        <>
                                          <span
                                            className="live-status"
                                            style={{ backgroundColor: "grey" }}
                                          ></span>
                                          &nbsp;
                                          <font style={{ fontSize: ".8rem" }}>
                                            Disconnected
                                          </font>
                                        </>
                                      )}
                                    </div>
                                    <div className="right">
                                      <div className="container">
                                        <a
                                          href="#"
                                          style={{
                                            fontSize: ".8rem",
                                            color: "grey",
                                            fontWeight: "bold",
                                            display: "none",
                                          }}
                                        >
                                          Ticket raised{" "}
                                        </a>
                                        &nbsp;&nbsp;&nbsp;
                                        <OverlayTrigger
                                          trigger={["hover", "focus"]}
                                          placement="left"
                                          overlay={
                                            <Popover id="popover-trigger-click-root-close">
                                              <div className="w-full max-w-screen-md mx-auto px-4 py-2">
                                                <div className="flex flex-col space-y-2">
                                                  <div className="flex justify-between items-center border-b border-gray-200 flex-horizon user-info">
                                                    <div className="font-semibold text-gray-700">
                                                      Email
                                                    </div>
                                                    <div className="value font-semibold text-gray-700">
                                                      {currentUser.email ? (
                                                        <>{currentUser.email}</>
                                                      ) : (
                                                        <>Unknown</>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="flex justify-between items-center border-b border-gray-200 flex-horizon user-info">
                                                    <div className="font-semibold text-gray-700">
                                                      Country
                                                    </div>
                                                    <div className="value flex-horizon font-semibold text-gray-700">
                                                      {currentUser.country ? (
                                                        <>
                                                          <ReactCountryFlag
                                                            style={{
                                                              marginRight:
                                                                ".3rem",
                                                            }}
                                                            countryCode={
                                                              currentUser.countryCode
                                                            }
                                                            svg
                                                          />
                                                          {currentUser.country}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {currentUser.geo_data ? (
                                                            <>
                                                              <ReactCountryFlag
                                                                style={{
                                                                  marginRight:
                                                                    ".3rem",
                                                                }}
                                                                countryCode={
                                                                  currentUser
                                                                    .geo_data
                                                                    .countryCode
                                                                }
                                                                svg
                                                              />
                                                              {
                                                                currentUser
                                                                  .geo_data
                                                                  .country
                                                              }
                                                            </>
                                                          ) : (
                                                            <>Unknown</>
                                                          )}
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="flex justify-between items-center border-b border-gray-200 flex-horizon user-info">
                                                    <div className="font-semibold text-gray-700">
                                                      City
                                                    </div>
                                                    <div className="value font-semibold text-gray-700">
                                                      {currentUser.city ? (
                                                        <>{currentUser.city}</>
                                                      ) : (
                                                        <>
                                                          {currentUser.geo_data ? (
                                                            <>
                                                              {
                                                                currentUser
                                                                  .geo_data.city
                                                              }
                                                            </>
                                                          ) : (
                                                            <>Unknown</>
                                                          )}
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="flex justify-between items-center border-b border-gray-200 flex-horizon user-info">
                                                    <div className="font-semibold text-gray-700">
                                                      Timezone
                                                    </div>
                                                    <div className="value font-semibold text-gray-700">
                                                      {currentUser.timezone ? (
                                                        <>
                                                          {currentUser.timezone}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {currentUser.geo_data ? (
                                                            <>
                                                              {
                                                                currentUser
                                                                  .geo_data
                                                                  .timezone
                                                              }
                                                            </>
                                                          ) : (
                                                            <>Unknown</>
                                                          )}
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Popover>
                                          }
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            style={{
                                              height: "1.1rem",
                                              marginRight: "10px",
                                              cursor: "pointer",
                                            }}
                                            className="w-6 h-6 text-blue-500 hover:text-blue-700 transition-colors duration-300"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                                            />
                                          </svg>
                                        </OverlayTrigger>
                                        <img
                                          src={ticketIcon}
                                          height={17}
                                          title="Ticket"
                                          className="icon"
                                          style={{ opacity: 0.6 }}
                                          onClick={() => setTicketShow(true)}
                                        />
                                        &nbsp;&nbsp;&nbsp;
                                        <img
                                          src={email}
                                          height={17}
                                          title="Email"
                                          className="icon"
                                          style={{ opacity: 0.6 }}
                                          onClick={() =>
                                            setShowComposeEmail(true)
                                          }
                                        />
                                        &nbsp;&nbsp;&nbsp;
                                        {/* <img
                                      src={pin}
                                      height={17}
                                      title="Pin"
                                      className="icon"
                                      style={{ opacity: 0.6 }}
                                    /> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="middle">
                                  <div className="container">
                                    <ul className="messages">
                                      {currentUser.messages.map(
                                        (message, index) => {
                                          return (
                                            <>
                                              {message.message_type ==
                                              "text" ? (
                                                <>
                                                  <li
                                                    className={
                                                      message.sent_by !==
                                                        "user" &&
                                                      message.sent_by !== "bot"
                                                        ? "bot" +
                                                          "-message message"
                                                        : message.sent_by +
                                                          "-message message"
                                                    }
                                                  >
                                                    <div
                                                      style={{
                                                        maxWidth: "70%",
                                                      }}
                                                    >
                                                      <b
                                                        style={{
                                                          fontSize: ".8rem",
                                                          color: "grey",
                                                        }}
                                                      >
                                                        {message.sent_by}
                                                      </b>
                                                      <div className="text">
                                                        <font
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              convertLinksToHTML(
                                                                message.message
                                                              ),
                                                          }}
                                                        ></font>
                                                      </div>
                                                      <small
                                                        className="form-text"
                                                        style={{
                                                          fontSize: ".7rem",
                                                          margin: "auto 1rem",
                                                        }}
                                                      >
                                                        <i>
                                                          {getTimeAgo(
                                                            message.time
                                                          )}
                                                        </i>
                                                      </small>
                                                    </div>
                                                  </li>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              {message.message_type ==
                                              "cards" ? (
                                                <>
                                                  <li
                                                    className={
                                                      message.sent_by !==
                                                        "user" &&
                                                      message.sent_by !== "bot"
                                                        ? "bot" +
                                                          "-message message"
                                                        : message.sent_by +
                                                          "-message message"
                                                    }
                                                  >
                                                    <div
                                                      style={{
                                                        maxWidth: "70%",
                                                      }}
                                                    >
                                                      <b
                                                        style={{
                                                          fontSize: ".8rem",
                                                          color: "grey",
                                                        }}
                                                      >
                                                        {message.sent_by}
                                                      </b>
                                                      <ul className="cards">
                                                        {message.message.map(
                                                          (card) => (
                                                            <>
                                                              <li className="card">
                                                                <img
                                                                  src={
                                                                    card[
                                                                      "img-url"
                                                                    ]
                                                                  }
                                                                />
                                                                <h6>
                                                                  {card.title}
                                                                </h6>
                                                                <p>
                                                                  {
                                                                    card.description
                                                                  }
                                                                </p>
                                                                <button className="btn btn-sm theme-btn">
                                                                  {
                                                                    card[
                                                                      "button-name"
                                                                    ]
                                                                  }
                                                                </button>
                                                              </li>
                                                            </>
                                                          )
                                                        )}
                                                      </ul>
                                                      <small
                                                        className="form-text"
                                                        style={{
                                                          fontSize: ".7rem",
                                                          margin: "auto 1rem",
                                                        }}
                                                      >
                                                        <i>
                                                          {getTimeAgo(
                                                            message.time
                                                          )}
                                                        </i>
                                                      </small>
                                                    </div>
                                                  </li>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              {message.message_type ==
                                              "buttons" ? (
                                                <>
                                                  <li
                                                    className={
                                                      message.sent_by !==
                                                        "user" &&
                                                      message.sent_by !== "bot"
                                                        ? "bot" +
                                                          "-message message"
                                                        : message.sent_by +
                                                          "-message message"
                                                    }
                                                  >
                                                    <div
                                                      style={{
                                                        maxWidth: "70%",
                                                      }}
                                                    >
                                                      <b
                                                        style={{
                                                          fontSize: ".8rem",
                                                          color: "grey",
                                                        }}
                                                      >
                                                        {message.sent_by}
                                                      </b>
                                                      <ul className="list-style">
                                                        {message.message.map(
                                                          (button) => (
                                                            <>
                                                              <li className="button">
                                                                <button className="btn btn-primary round-btn btn-sm">
                                                                  {button.name}
                                                                </button>
                                                              </li>
                                                            </>
                                                          )
                                                        )}
                                                      </ul>
                                                      <small
                                                        className="form-text"
                                                        style={{
                                                          fontSize: ".7rem",
                                                          margin: "auto 1rem",
                                                        }}
                                                      >
                                                        <i>
                                                          {getTimeAgo(
                                                            message.time
                                                          )}
                                                        </i>
                                                      </small>
                                                    </div>
                                                  </li>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              {message.message_type ==
                                              "form" ? (
                                                <>
                                                  <li
                                                    className={
                                                      message.sent_by !==
                                                        "user" &&
                                                      message.sent_by !== "bot"
                                                        ? "bot" +
                                                          "-message message"
                                                        : message.sent_by +
                                                          "-message message"
                                                    }
                                                  >
                                                    <div
                                                      style={{
                                                        maxWidth: "70%",
                                                      }}
                                                    >
                                                      <b
                                                        style={{
                                                          fontSize: "0.8rem",
                                                          color: "grey;",
                                                        }}
                                                      >
                                                        {message.sent_by} (
                                                        <img
                                                          src={formImg}
                                                          height={10}
                                                        />
                                                        <font>Form</font>)
                                                      </b>
                                                      <div
                                                        className="text"
                                                        style={{
                                                          padding: "10px",
                                                        }}
                                                      >
                                                        <b>Form: </b>
                                                        <a
                                                          href={
                                                            "/app/form-builder?id=" +
                                                            message.message.uid
                                                          }
                                                          target="_blank"
                                                        >
                                                          {
                                                            message.message
                                                              .title
                                                          }
                                                        </a>
                                                      </div>
                                                      <small
                                                        className="form-text"
                                                        style={{
                                                          fontSize: ".7rem",
                                                          margin: "auto 1rem",
                                                        }}
                                                      >
                                                        <i>
                                                          {getTimeAgo(
                                                            message.time
                                                          )}
                                                        </i>
                                                      </small>
                                                    </div>
                                                  </li>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              {message.message_type ==
                                              "file" ? (
                                                <>
                                                  <li
                                                    className={
                                                      message.sent_by !==
                                                        "user" &&
                                                      message.sent_by !== "bot"
                                                        ? "bot" +
                                                          "-message message"
                                                        : message.sent_by +
                                                          "-message message"
                                                    }
                                                  >
                                                    <div
                                                      style={{
                                                        maxWidth: "70%",
                                                      }}
                                                    >
                                                      <b
                                                        style={{
                                                          fontSize: ".8rem",
                                                          color: "grey",
                                                        }}
                                                      >
                                                        {message.sent_by} (
                                                        <img
                                                          src={attachment}
                                                          height="10px"
                                                        />
                                                        <font>Attachment</font>)
                                                      </b>
                                                      <div
                                                        className="text"
                                                        style={{
                                                          display: "flex",
                                                          "flex-direction":
                                                            "column",
                                                          alignItems: "end",
                                                        }}
                                                      >
                                                        <a
                                                          href="http://google.com"
                                                          style={{
                                                            color: "black",
                                                          }}
                                                          target="_blank"
                                                        >
                                                          File: $
                                                          {message.message.name}
                                                        </a>
                                                        <i
                                                          style={{
                                                            "font-size":
                                                              ".7rem",
                                                            "margin-bottom":
                                                              "10px",
                                                            color: "grey",
                                                            fontWeight: "bold",
                                                          }}
                                                        >
                                                          {message.message.type}{" "}
                                                          <font>
                                                            $
                                                            {
                                                              message.message
                                                                .size
                                                            }
                                                          </font>
                                                          <br />
                                                        </i>
                                                      </div>
                                                      <small
                                                        className="form-text"
                                                        style={{
                                                          fontSize: ".7rem",
                                                          margin: "auto 1rem",
                                                        }}
                                                      >
                                                        <i>
                                                          {getTimeAgo(
                                                            message.time
                                                          )}
                                                        </i>
                                                      </small>
                                                    </div>
                                                  </li>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
                                </div>
                                <div
                                  id="files-panel"
                                  style={{ display: "none" }}
                                >
                                  <ul className="files">
                                    {selectedFiles.map((file, index) => (
                                      <li className="file" title={file.name}>
                                        <p
                                          className="remove-doc"
                                          onClick={removeDoc}
                                        >
                                          x
                                        </p>
                                        <div
                                          className="top"
                                          style={{
                                            backgroundImage: `url(${doc})`,
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                          }}
                                        ></div>
                                        <p className="limit-text">
                                          {file.name}
                                        </p>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                                <div className="bottom">
                                  <div className="container">
                                    {currentUser.connection_id ==
                                    connectedUser ? (
                                      <>
                                        <form
                                          id="message-form"
                                          onSubmit={send_message}
                                          className="top flex-horizon"
                                        >
                                          {liveChat ? (
                                            <>
                                              <input
                                                id="message-input"
                                                placeholder="Type your message here"
                                                defaultValue={inputMessage}
                                                onInput={(e) =>
                                                  setInputMessage(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <input
                                                id="message-input"
                                                placeholder="Type your message here"
                                                disabled
                                              />
                                            </>
                                          )}
                                          &nbsp;
                                          <button
                                            id="send-msg"
                                            className="btn btn-primary btn-theme"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              fontSize: ".9rem",
                                            }}
                                            disabled={
                                              inputMessage ||
                                              selectedFiles.length
                                                ? false
                                                : true
                                            }
                                            onClick={sendTextMessage}
                                          >
                                            <img
                                              src={send}
                                              style={{ height: "18px" }}
                                            />
                                            <font>&nbsp;Send</font>
                                          </button>
                                        </form>
                                      </>
                                    ) : (
                                      <>
                                        <div
                                          style={{
                                            width: "100%",
                                            padding: ".5rem",
                                            textAlign: "center",
                                          }}
                                        >
                                          <button
                                            className="btn btn-success btn-sm round-btn"
                                            onClick={connectUser}
                                          >
                                            Chat now
                                          </button>
                                        </div>
                                      </>
                                    )}

                                    <div className="bottom">
                                      <div className="container">
                                        <div className="left">
                                          <i
                                            style={{
                                              color: "grey",
                                              fontSize: ".8rem",
                                            }}
                                          >
                                            Powered by <b>SharurAI</b>
                                          </i>
                                        </div>
                                        {disableChatInput ? (
                                          <></>
                                        ) : (
                                          <>
                                            <div className="right flex-horizon">
                                              <img
                                                className="icon"
                                                style={{ padding: "3px" }}
                                                title="Forms"
                                                src={formImg}
                                                onClick={handleFormShow}
                                              />
                                              <img
                                                className="icon"
                                                style={{ padding: "3px" }}
                                                title="Attachments"
                                                src={attachment}
                                                onClick={select_files}
                                              />
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <h4
                                style={{
                                  margin: "auto",
                                  padding: "1rem",
                                  background: "#fff7e7",
                                }}
                              >
                                Please select a live user to chat.
                              </h4>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
            {chat == "not_allowed" ? (
              <>
                <br />
                <br />
                <div
                  className="message-alert-banner alert"
                  style={{ display: "block !important" }}
                >
                  <div className="container">
                    <b>
                      You don't have chat access. Please ask your group admin
                      for the required permission to chat.
                    </b>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <SelectForm
        show={showFrom}
        handleClose={handleFromClose}
        sendFunction={sendForm}
      />
      <CreateCards show={showCards} handleClose={handleCardsClose} />
      {currentUser && currentUser.email && isValidEmail(currentUser.email) ? (
        <>
          <ComposeMail
            show={showComposeEmail}
            setShow={setShowComposeEmail}
            data={{ toEmail: currentUser.email }}
          />
        </>
      ) : (
        <>
          <ComposeMail show={showComposeEmail} setShow={setShowComposeEmail} />
        </>
      )}
      <CreateTicket
        show={ticketShow}
        conv_id={currentUser.uid}
        handleClose={() => setTicketShow(false)}
        func={(id) => createTicket(id)}
      />
    </>
  );
}
