import userIcon from "../images/user.svg";
import mailIcon from "../images/email-dark.png";
import ComposeMail from "../toasts/ComposeMail";
import { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import userInfoCardIcon from "../images/user-info-card.svg";
import Copilot from "../Copilot";

function secondsToHoursMinutesOrSeconds(seconds) {
  if (seconds >= 3600) {
    const hours = Math.floor(seconds / 3600);
    return `${hours} hour${hours > 1 ? "s" : ""}`;
  } else if (seconds >= 60) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes} minute${minutes > 1 ? "s" : ""}`;
  } else {
    return `${seconds} second${seconds !== 1 ? "s" : ""}`;
  }
}

function Section3({ conversation }) {
  const [showComposeMail, setShowComposeMail] = useState(false);

  const handleEmailCompose = () => {
    setShowComposeMail(true);
  };
  useEffect(() => {}, [showComposeMail]);

  return (
    <>
      <div
        className="right col-lg-4 col-md-4 col-sm-4"
        
      >
        {Object.keys(conversation).length ? (
          <>
            <div id="conversation-info">
              <div className="container flex-horizon">
                <div className="top" style={{ textAlign: "center" }}>
                  <h5>Conversation info</h5>
                  <br />
                  <img src={userIcon} height={60} />
                </div>
                <br />
                <br />
                <div className="middle">
                  <ul style={{ fontSize: ".9rem" }}>
                    <li>
                      <p>Name-</p>
                      <b>
                        {conversation.name ? (
                          <>{conversation.name}</>
                        ) : (
                          <>Unknown</>
                        )}
                      </b>
                    </li>
                    <li>
                      <p>Email-</p>
                      <b>
                        {conversation.email ? (
                          <>{conversation.email}</>
                        ) : (
                          <>Unknown</>
                        )}
                      </b>
                    </li>
                    <li>
                      <p>Country-</p>
                      <b>
                        <ReactCountryFlag
                          style={{
                            marginRight: ".3rem",
                          }}
                          countryCode={conversation.country}
                          svg
                        />
                        {conversation.country}
                      </b>
                    </li>
                    <li>
                      <p>City-</p>
                      <b>
                        {conversation.other_info ? (
                          <>
                            {JSON.parse(conversation.other_info) &&
                            JSON.parse(conversation.other_info).city ? (
                              <>{JSON.parse(conversation.other_info).city}</>
                            ) : (
                              <>Unknown</>
                            )}
                          </>
                        ) : (
                          <>Unknown</>
                        )}
                      </b>
                    </li>
                    <li>
                      <p>Tokens used-</p>
                      <b>{conversation.tokens_used}</b>
                    </li>
                    <li>
                      <p>Messages-</p>
                      <b>{conversation.total_messages}</b>
                    </li>
                    <li>
                      <p>Sessions-</p>
                      <b>{conversation.sessions}</b>
                    </li>
                    <li>
                      <p>Time spent-</p>
                      <b>
                        {secondsToHoursMinutesOrSeconds(conversation.duration)}
                      </b>
                    </li>
                  </ul>
                  <div
                    className="flex-horizon"
                    style={{ justifyContent: "center" }}
                  >
                    <div
                      className="btn theme-btn btn-primary"
                      onClick={handleEmailCompose}
                    >
                      <img height={20} src={mailIcon} /> Send email
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div
            className="flex-horizon"
            style={{
              justifyContent: "center",
              alignItems: "center",
              height: "500px",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <img
              style={{
                opacity: ".5",
              }}
              height={180}
              wist
              src={userInfoCardIcon}
            />
            <b style={{ fontSize: "1.2rem", opacity: ".5" }}>No information</b>
          </div>
        )}
        <ComposeMail show={showComposeMail} setShow={setShowComposeMail} />
      </div>
      {/* <Copilot/> */}
    </>
  );
}

export default Section3;
