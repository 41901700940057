import React, { useLayoutEffect } from "react";
import HomeMenu from "./HomeMenu";
import BotInfoCustomization from "../BotInfoCustomization";
import "../css/Home.css";
import Header from "../Header";
import Leftnav from "../Leftnav";
import Banner from "../banner";
import Variables from "../variables";

function Bot_info() {
  useLayoutEffect(() => {
    async function checkAuth() {
      let status = await Variables.checkAuthentication()
      console.log('Authentication message is ', status)
    }
    checkAuth.call()
  })
  return (
    <>
      <div className="App">
        <Leftnav />
        <div className="App-body">
          <header className="sticky-top">
            <Header />
          </header>
          <Banner />
          <div className="home-container sticky-top">
            <div className="page-loader">
              
            </div>
            <HomeMenu />
            <BotInfoCustomization />
          </div>
        </div>
      </div>
    </>
  );
}

export default Bot_info;
