import $ from "jquery";
import Variables from "../variables";
import EmailAccounts from "./EmailAccounts";
import Emails from "./Emails";

function Section2({ data }) {
  const handleMenuClick = (e, data) => {
    $(".email-menus").find("li").removeClass("active");
    $(e.target).addClass("active");
    if (data.type == "email-accounts") {
      let req_data = Variables.create_req_data({});
      // axios.post()
    } else if (data.type == "emails") {
      let req_data = Variables.create_req_data({});
      // axios.post()
    }
  };
  return (
    <div className="right col-lg-10 col-md-10 col-sm-10">
      <div id="email-data" style={{width:"100%"}}>
        {data.type == "email-accounts" ? (
          <>
            <EmailAccounts data={data} />
          </>
        ) : (
          <><Emails data={data} /></>
        )}
      </div>
    </div>
  );
}

export default Section2;
