import "./css/BotTraining.css";
import Section1 from "./training/Section1.js";
import Section2 from "./training/Section2.js";
import { useEffect, useLayoutEffect, useState } from "react";
import Variables from "./variables";
import $ from "jquery";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import notion from "./images/notion.png";

function convertBytes(bytes) {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

  if (bytes == 0) return "0 Byte";

  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const size = parseFloat((bytes / Math.pow(1024, i)).toFixed(2));

  return size + " " + sizes[i];
}

function BotTraining() {
  let kbID = "";
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const extracted_id = params.get("id");
  kbID = extracted_id;
  const [name, setName] = useState("");
  var previousData = "";
  var total_processing = 0;
  var processed = 0;
  var urls = [];
  let urls_done = false;

  async function upload_file() {
    let obj = "";
    let Switch = true;
    $(".slide").each((i, item) => {
      if ($(item).attr("uploaded") == "false" && Switch) {
        obj = item;
        Switch = false;
      }
      if ($(item).attr("type") == "url" && urls_done == false) {
        urls.push({ type: "url", url: $(item).attr("url") });
      } else if ($(item).attr("type") == "sitemap" && urls_done == false) {
        urls.push({ type: "sitemap", url: $(item).attr("url") });
      }
    });
    urls_done = true;
    if (obj != "" && $(obj).attr("type") == "file") {
      let name = $(obj).find(".content .limit-text").html();
      $("#processing-file").html("Processing '" + name + "'");
      let file_type = "";
      if (
        $(obj).find(".download").attr("file-type") ==
        "vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        file_type = "docx";
      } else if (
        $(obj).find(".download").attr("file-type") ==
        "vnd.openxmlformats-officedocument.presentationml.presentation"
      ) {
        file_type = "pptx";
      } else if ($(obj).find(".download").attr("file-type") == "pdf") {
        file_type = "pdf";
      } else if (
        $(obj).find(".download").attr("file-type") ==
        "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        file_type = "xlsx";
      } else if ($(obj).find(".download").attr("file-type") == "csv") {
        file_type = "csv";
      } else if ($(obj).find(".download").attr("file-type") == "plain") {
        file_type = "txt";
      }
      try{
        await fetch($(obj).find(".download").attr("href"))
                .then((res) => res.blob())
                .then(async (blob) => {
                  let files = new FormData();
                  files.append(
                    "access_key",
                    localStorage.getItem("sharurai_access_key")
                  );
                  files.append(
                    "refresh_key",
                    localStorage.getItem("sharurai_refresh_key")
                  );
                  files.append("upload", blob);
                  files.append("file_type", file_type);
                  files.append("obj_type", "file");
                  files.append("id", kbID);
                  files.append("name", name);
                  await $.ajax({
                    type: "post",
                    url: Variables.serverUrl("add-to-kb/"),
                    processData: false,
                    contentType: false,
                    data: files,
                    success: async function (response) {
                      processed += 1;
                      $(obj).attr("uploaded", "true");
                      let progress =
                        Math.floor((processed / total_processing) * 100) + "%";
                      $("#progress").find(".progress-bar").html(progress);
                      $("#progress").find(".progress-bar").css({
                        width: progress,
                      });
                      let res = response;
                      if (processed == total_processing) {
                        setTimeout(() => {
                          window.location.reload();
                        }, 3000);
                      } else {
                        await upload_file();
                      }
                    },
                    error: function (err) {
                      //pass
                    },
                  });
                
                });
      }catch(e){
        //pass
      }
      
    } else {
      upload_urls();
    }
  }

  async function upload_urls() {
    if (urls.length) {
      let req_data = Variables.create_req_data({
        id: kbID,
        obj_type: "url",
        url_list: JSON.stringify(urls),
      });
      $("#processing-file").html("Processing your URLs.");
      await $.ajax({
        type: "post",
        url: Variables.serverUrl("add-to-kb/"),
        data: req_data,
        success: async function (response) {
          processed += urls.length;
          let progress = Math.floor((processed / total_processing) * 100) + "%";
          $("#progress").find(".progress-bar").html(progress);
          $("#progress").find(".progress-bar").css({
            width: progress,
          });
          if (processed == total_processing) {
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        },
      });
    } else {
      $("#processing-file").html("Processing your Notion databases.");
      let notion = [];
      $(".notion-db input").each((i, obj) => {
        notion.push($(obj).attr("value"));
      });
      let req_data = Variables.create_req_data({
        id: kbID,
        obj_type: "notion",
        db_list: JSON.stringify(notion),
      });
      $("#processing-file").html("Processing your URLs.");
      await $.ajax({
        type: "post",
        url: Variables.serverUrl("add-to-kb/"),
        data: req_data,
        success: async function (response) {
          processed += $("#notion .notion-db input:checked").length;
          let progress = Math.floor((processed / total_processing) * 100) + "%";
          $("#progress").find(".progress-bar").html(progress);
          $("#progress").find(".progress-bar").css({
            width: progress,
          });
          if (processed == total_processing) {
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        },
      });
    }
  }

  const [notionData, setNotionData] = useState([]);

  useLayoutEffect(() => {
    (async () => {
      let req_data = Variables.create_req_data({ id: kbID });
      await axios
        .post(Variables.serverUrl("get-knowledge-base/"), req_data)
        .then((res) => {
          setNotionData(res.data.notion_data);
          let data = JSON.parse(res.data.data);
          previousData = JSON.parse(data[0].fields.data);
          if (!res.data.is_notion) {
            $("#notion").hide();
          } else {
            $("#notion").show();
          }
          try {
            let files = previousData;
            files.forEach((element) => {
              if (element.type == "file") {
                let file_slide = $(
                  `<li class="previous-data flex-horizon" title="${
                    element.name
                  }"><div class="content limit-text" style="width:90%;"><a style="color:black;display:block;width:90%;" href="${
                    element.url
                  }" class="limit-text">${
                    element.name
                  }</a><small class="form-text">&nbsp;${convertBytes(
                    element.size
                  )}</small></div><p class='added'>Added</p><p style="background-color:#e54949;cursor:pointer;bottom:0;top:unset;" class="remove added">Remove</p></li>`
                );
                $(file_slide)
                  .find(".remove")
                  .on("click", () => {
                    window.location.href = element.url;
                  });
                $("#files-list").prepend(file_slide);
              }
            });
          } catch (e) {
            // pass
          }
          try {
            let files = previousData;
            files.forEach((element) => {
              let file_slide = $(
                `<li class="previous-data flex-horizon" title="${
                  element.name
                }"><div class="content limit-text" style="width:90%;"><a style="color:black;display:block;width:90%;" href="${
                  element.url
                }" class="limit-text">${
                  element.name
                }</a><small class="form-text">&nbsp;${convertBytes(
                  element.size
                )}</small></div><p class='added'>Added</p><p style="background-color:#e54949;cursor:pointer;bottom:0;top:unset;" class="remove added">Remove</p></li>`
              );
              $(file_slide)
                .find(".remove")
                .on("click", (e) => {
                  $(e.target).hide();
                  axios
                    .post(
                      Variables.serverUrl("remove-from-kb/"),
                      Variables.create_req_data({
                        uid: kbID,
                        class: element.class,
                      })
                    )
                    .then((res) => {
                      if (res.status == 200) {
                        $(e.target).closest("li").remove();
                      }
                    })
                    .catch((e) => {
                      $(e.target).show();
                    });
                });
              if (element.type == "url") {
                $("#url-list").prepend(file_slide);
              }else{
                $("#files-list").prepend(file_slide);
              }
            });
          } catch (e) {
            //pass
          }
          setName(data[0].fields.name);
        });
    })();
  }, []);
  useEffect(() => {
    $("#create-kb").on("click", async () => {
      if ($(".slide").length || $("#notion .notion-db input:checked").length) {
        $("button").prop("disabled", true);
        Variables.loading_btn("#create-kb", "Building");
        $("#training-progress").show();
        total_processing =
          $(".slide").length + $("#notion .notion-db input:checked").length;
        await upload_file();
      }
    });
    async function checkAuth() {
      let status = await Variables.checkAuthentication();
    }
    checkAuth.call();
    const links = document.getElementsByClassName("left-nav-link");
    for (let i = 0; i < links.length; i++) {
      links[i].classList.remove("active");
    }
    document
      .getElementsByClassName("knowledge-base-link")[0]
      .classList.add("active");
  }, []);
  return (
    <div className="row" id="bot-training">
      <div className="top">
        <div className="left">
          <h4 className="title-color">{name}</h4>
          <small>
            Fine tune your chatbot so that it can better interact with users by
            increasing its knowledge base.
          </small>
        </div>
        <div className="right">
          <button id="create-kb" className="btn btn-success">
            Build
          </button>
        </div>
      </div>
      {/* <div id="notion" style={{display:'none'}} >
        <Dropdown>
          <Dropdown.Toggle
            variant="white"
            style={{ border: "1px solid grey" }}
            id="dropdown-basic"
          >
            <img src={notion} style={{ width: "25px" }} />
            &nbsp;&nbsp;<b style={{ fontSize: ".9rem" }}>Notion databases</b>
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ padding: "10px" }}>
            {notionData.map((obj) => (
              <div
                className="form-check notion-db"
                style={{ cursor: "pointer !important" }}
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  value={obj.id}
                  id={obj.id}
                />
                <label class="form-check-label" for={obj.id}>
                  {obj.name}
                </label>
              </div>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div> */}
      <div id="training-progress" style={{ display: "none" }}>
        <p>
          Please hold on! Do not refresh the page. We are building your
          knowledge base. It will take a while...
        </p>
        <div id="progress" className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: "5%" }}
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            5%
          </div>
        </div>
        <br />
        <font id="processing-file" style={{ "font-size": ".9rem" }}></font>
      </div>
      <Section1 />
      <Section2 />
    </div>
  );
}

export default BotTraining;
