import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "../css/EditBtn.css";
import Variables from "../variables";
import $ from "jquery";
import { useEffect, useState } from "react";
import pencil from "../images/pencil.svg";

function EditBtn({ show, handleClose, ele, handleShow, forms }) {
  const [name, setName] = useState("");
  const modalActionType = $(ele).attr("do");
  const [actionType, setActionType] = useState("");
  const [message, setMessage] = useState("");
  useEffect(() => {
    setName($(ele).attr("name"));
    setActionType($(ele).attr("action-type"));
    setMessage($(ele).attr("data"));
    $(`#button-action-type option[value='${actionType}']`).attr("selected","selected");
    $(".action-type").hide();
    $("#action-type-" + $("#button-action-type").val()).show();
    $("#button-action-type").on("change", function () {
      let type = $(this).val();
      $(".action-type").hide();
      switch (type) {
        case "message":
          $("#action-type-message").show();
          break;
        case "link":
          $("#action-type-link").show();
          break;
        case "form":
          if (forms.length < 1) {
            $("#action-type-form").html(`
            <font>You have no forms yet&nbsp;</font>
            <a href="/app/forms" target="_blank">+ Create a new form</a>
            `);
          }
          $("#action-type-form").show();
          break;
        default:
          //pass
          break;
      }
    });
  });

  function save_btn() {
    let button_title = $("#button-title").val();
    let button_action_type = $("#button-action-type").val();
    let action_message = $("#action-types data:visible").val();
    console.log(button_action_type)
    if (button_action_type == "message") {
      action_message = $("#action-type-message textarea").val();
    } else if (button_action_type == "link") {
      action_message = $("#action-type-link input").val();
      try {
        new URL(action_message);
        $("#action-type-link input").css({
          border: ".5px solid green",
        });
      } catch (e) {
        $("#action-type-link input").css({
          border: ".5px solid red",
        });
        action_message = '';
      }
    } else if (button_action_type == "form") {
      action_message = $("#action-type-form select").find(":selected").val();
    }
    if (
      button_title != "" &&
      button_action_type != "" &&
      action_message != ""
    ) {
      $("#edit-btn input,#edit-btn select, #edit-btn textarea").css({
        border: ".5px solid green",
      });
      if (modalActionType != "Add") {
        console.log($(ele).attr("data", action_message))
        Variables.loading_btn("#create-new-bot-btn", "Editing");
        console.log(action_message);
        $(ele).attr("data", action_message);
        $(ele).parent("li").find(".preset-btn").html(button_title);
        $(ele).attr("name", button_title);
        $(ele).attr("action-type", button_action_type);
      } else {
        let element = $(`
        <li style="align-items:center;">
              <div class="theme-btn limit-text preset-btn">${button_title}</div>
              &nbsp; &nbsp;
              <img class="edit-preset-btns" src="${pencil}" do="Edit" name="${button_title}" action-type="${button_action_type}" data="${action_message}" style="height:18px;" />
              <font class="remove-btn">x</font>
            </li>
        `);
        $(element)
          .find(".remove-btn")
          .on("click", function () {
            $(element).remove();
          });
        $(element)
          .find(".edit-preset-btns")
          .on("click", function () {
            handleShow({ target: $(element).find("img") });
          });
        $("#preset-btns, #response-preset-btns").prepend(element);
      }
      handleClose();
    }
  }
  return (
    <>
      <Modal
        id="edit-btn"
        show={show}
        onHide={handleClose}
        backdrop="static"
        fullscreen="md-down"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalActionType} preset button</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label>Name your button</label>
            <input
              id="button-title"
              defaultValue={name}
              className="form-control"
              maxLength={20}
            />
            <small style={{ color: "grey" }}>Max. length is 20 letters</small>
            <br />
            <label>Action type</label>
            <select
              className="form-control"
              id="button-action-type"
            >
              <option value="link">Link</option>
              <option value="message">Message</option>
              <option value="form">Form</option>
            </select>
            <br />
            <div id="action-types">
              <div id="action-type-message" className="action-type">
                <label>Message</label>
                <textarea
                  defaultValue={message}
                  className="form-control data"
                  placeholder="Type your message"
                ></textarea>
                <small style={{ color: "grey" }}>
                  Max. length is 80 letters
                </small>
              </div>
              <div id="action-type-link" className="action-type">
                <label>Link</label>
                <input
                  type="url"
                  defaultValue={message}
                  className="form-control url-data"
                  placeholder="https://example.com"
                />
                <small style={{ color: "grey" }}>
                  Max. length is 80 letters
                </small>
              </div>
              <div id="action-type-form" className="action-type">
                <label>Select a form</label>
                <select className="form-control">
                  {forms.map((obj) => {
                    if (message == obj.uid) {
                      return (
                        <option value={obj.uid} selected>
                          {obj.title}
                        </option>
                      );
                    } else {
                      return <option value={obj.uid}>{obj.title}</option>;
                    }
                  })}
                </select>
                <small style={{ color: "grey" }}>
                  Max. length is 80 letters
                </small>
              </div>
            </div>
            <br />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            id="create-new-bot-btn"
            className="theme-btn"
            variant="primary"
            onClick={save_btn}
          >
            {modalActionType} button
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditBtn;
