import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "../css/NewBot.css";
import Variables from "../variables";
import axios from "axios";
import { useEffect, useState } from "react";
import $ from "jquery";

function SelectMails({ show, handleClose, func }) {
  const [emails, setEmails] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const selectedFunction = (e) => {
    Variables.loading_btn(e.target, "Assigning");
    func(e.target, selectedEmails, handleClose);
    Variables.unloading_btn(e.target);
  };
  const handleCheckboxChange = (e) => {
    const val = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked) {
      let email = $(e.target).attr("email_id");
      let type = $(e.target).attr("email_type") || ""
      setSelectedEmails((prevSelectedEmails) => [
        ...prevSelectedEmails,
        { uid: val, email: email, type: type },
      ]);
    } else {
      setSelectedEmails((prevSelectedEmails) =>
        prevSelectedEmails.filter((item) => item.uid !== val)
      );
    }
  };
  useEffect(() => {
    axios
      .post(
        Variables.serverUrl("email-accounts/"),
        Variables.create_req_data({ action: "get-all" })
      )
      .then((res) => {
        if (res.status == 200) {
          setEmails(res.data.emails);
        }
      })
      .catch((err) => {
        return null;
      });
  }, []);
  return (
    <>
      <Modal
        id="select-emails"
        show={show}
        onHide={handleClose}
        backdrop="static"
        fullscreen="md-down"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select emails</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="ul-style">
            {emails.length ? (
              <>
                {emails.map((item, index) => (
                  <li
                    className="flex-horizon"
                    style={{ alignItems: "center", padding: ".5rem" }}
                  >
                    <input
                      id={item.uid}
                      type="checkbox"
                      className="form-check-input"
                      value={item.uid}
                      email_type={item.type}
                      email_id={item.email}
                      onChange={handleCheckboxChange}
                    />
                    &nbsp;
                    <label for={item.uid}>
                      {item.email} <b>({item.type})</b>
                    </label>
                  </li>
                ))}
              </>
            ) : (
              <>
                <p>No email account is associated.</p>
              </>
            )}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose();
              setSelectedEmails([]);
            }}
          >
            Close
          </Button>
          <Button
            id="select-emails-btn"
            className="theme-btn"
            variant="primary"
            onClick={selectedFunction}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SelectMails;
