import { useEffect, useLayoutEffect, useState } from "react";
import Variables from "./variables";
import Header from "./Header";
import Leftnav from "./Leftnav";
import Banner from "./banner";
import "./css/Email.css";
import Section1 from "./Email/Section1";
import Section2 from "./Email/Section2";

function Email() {
  const [data, setData] = useState({ type: "email-accounts" });
  useEffect(() => {
    const leftNavLinks = document.getElementsByClassName("left-nav-link");
    for (let i = 0; i < leftNavLinks.length; i++) {
      leftNavLinks[i].classList.remove("active");
    }
    document.getElementsByClassName("email-link")[0].classList.add("active");
  }, []);

  useLayoutEffect(() => {
    async function checkAuth() {
      let status = await Variables.checkAuthentication();
    }
    checkAuth.call();
  }, []);
  return (
    <>
      <div className="App">
        <Leftnav />
        <div className="App-body">
          <header className="sticky-top">
            <Header />
            <Banner />
          </header>
          <div id="email" className="row" style={{ padding: "15px" }}>
            <Section1 setData={setData} />
            <Section2 data={data} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Email;
