import React, { useEffect, useState } from "react";
import { Toast } from "react-bootstrap";
import "../css/toast.css";
import mailIcon from "../images/email-dark.png";
import magicIcon from "../images/magic.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import Variables from "../variables";

function ComposeMail({ show, setShow, data }) {
  const toggleShow = () => setShow(!show);
  const [fromEmail, setFromEmail] = useState("");
  const [toEmail, setToEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [allFromEmails, setAllFromEmails] = useState("");
  const [btnDisabled, setBtnDisabled] = useState("disabled");
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [recommender, setRecommender] = useState(false);
  const [kb, setKb] = useState([]);
  const [selectedKb, setSelectedKb] = useState("");
  const [recommendedInput, setRecommendedInput] = useState("");
  const [recommendedText, setRecommendedText] = useState("");
  const [generateBtn, setGenerateBtn] = useState("disabled");
  const [tokenUsage, setTokenUsage] = useState(0);

  useEffect(() => {
    if (selectedKb && recommendedInput) {
      setGenerateBtn("");
    } else {
      setGenerateBtn("disabled");
    }
  }, [selectedKb, recommendedInput]);

  const sendMail = (e) => {
    Variables.loading_btn(e.target, "Sending");
    let req_data = Variables.create_req_data({
      from: fromEmail,
      to: toEmail,
      subject: subject,
      body: body,
    });
    axios
      .post(Variables.serverUrl("compose-mail/"), req_data)
      .then((res) => {
        Variables.unloading_btn(e.target);
        if (res.status == 200) {
          setError("");
          setSuccessMsg("Email sent successfully.");
          setToEmail("");
          setSubject("");
          setBody("");
        }
      })
      .catch((e) => {
        Variables.unloading_btn(e.target);
        setError(e.response.data.msg);
        setSuccessMsg("");
      });
  };

  const generateResponse = (e) => {
    Variables.loading_btn(e.target, "Generating");
    axios
      .post(
        Variables.serverUrl("generate-response/"),
        Variables.create_req_data({
          query: recommendedInput,
          kb: selectedKb,
        })
      )
      .then((res) => {
        setRecommendedText(res.data.response);
        setTokenUsage(res.data.token_usage);
        Variables.unloading_btn(e.target)
      })
      .catch((e) => {
        Variables.unloading_btn(e.target);
      });
  };

  useEffect(() => {
    if (fromEmail && toEmail && subject && body) {
      setBtnDisabled("");
    } else {
      setBtnDisabled("disabled");
    }
  }, [fromEmail, toEmail, subject, body]);

  useEffect(() => {
    if (data !== undefined && data.fromEmail !== undefined) {
      if (data.fromEmail.length) {
        setAllFromEmails(data.fromEmail);
      }
    } else {
      let req_data = Variables.create_req_data({ action: "get-all" });
      axios
        .post(Variables.serverUrl("email-accounts/"), req_data)
        .then((res) => {
          if (res.status == 200) {
            setAllFromEmails(res.data.emails);
          }
        });
    }
    if (data !== undefined && data.toEmail !== undefined) {
      setToEmail(data.toEmail);
    }
    if (data !== undefined && data.subject !== undefined) {
      setSubject(data.subject);
    }
  }, [data]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
    ],
  };
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "direction",
    "code-block",
  ];
  useEffect(() => {
    if (!kb.length) {
      axios
        .post(
          Variables.serverUrl("knowledge-base/"),
          Variables.create_req_data({ action: "get" })
        )
        .then((res) => {
          if (res.status) {
            if (res.data.data) {
              setKb(res.data.data);
              setSelectedKb(res.data.data[0].uid);
            }
          }
        })
        .catch((e) => {
          setRecommender(false);
        });
    }
  }, []);
  return (
    <>
      <div id="compose-mail" style={{ display: "flex", flexDirection: "row", alignItems:"flex-end"  }}>
        {recommender ? (
          <>
            <div id="email-recommendation" className="card">
              <div className="card-head" style={{ padding: ".5rem" }}>
                <strong>Generate answer</strong>
              </div>
              <div className="card-body">
                <div className="container">
                  <small>Select a Knowledge Base</small>
                  <br />
                  <select
                    className="form-select"
                    defaultValue={selectedKb}
                    onChange={(e) => selectedKb(e.target.value)}
                  >
                    {kb.map((item, index) => {
                      return <option value={item.uid}>{item.name}</option>;
                    })}
                  </select>
                  <br />
                  <small>Input</small>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    defaultValue={recommendedInput}
                    onInput={(e) => setRecommendedInput(e.target.value)}
                  />
                  <br />
                  {tokenUsage ? (
                    <>
                      <small style={{ color: "green" }}>{tokenUsage} tokens used</small><br/>
                    </>
                  ) : (
                    <></>
                  )}

                  <small>Recommended answer</small>
                  <textarea
                    className="form-control"
                    value={recommendedText}
                  ></textarea>
                </div>
              </div>
              <div className="card-footer flex-horizon">
                <button
                  className="btn btn-danger btn-sm round-btn"
                  onClick={() => setRecommender(false)}
                >
                  Close
                </button>
                &nbsp;
                <button
                  className={
                    "btn btn-success theme-btn btn-sm round-btn " + generateBtn
                  }
                  onClick={generateResponse}
                >
                  Generate
                </button>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        <Toast show={show} onClose={toggleShow}>
          <Toast.Header>
            <strong className="me-auto title flex-horizon">
              <img src={mailIcon} height={20} />
              &nbsp;&nbsp;Compose a mail
            </strong>
            <img
              src={magicIcon}
              height={20}
              title="Use AI to generate Email."
              style={{ cursor: "pointer" }}
              onClick={() => setRecommender(true)}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
          </Toast.Header>
          <Toast.Body>
            <div className="container">
              <div className="top">
                {error ? (
                  <>
                    <b
                      style={{
                        color: "#d33838",
                        padding: ".3rem",
                        borderRadius: "5px",
                        background: "#ffe5e5",
                      }}
                      className="alert-danger"
                    >
                      {error}
                    </b>
                    <br />
                  </>
                ) : (
                  <></>
                )}
                {successMsg ? (
                  <>
                    <b
                      style={{
                        color: "#27962e",
                        background: "#e5ffe5",
                        padding: ".3rem",
                        borderRadius: "5px",
                      }}
                      className="alert-danger"
                    >
                      {successMsg}
                    </b>
                    <br />
                  </>
                ) : (
                  <></>
                )}
                <div className="flex-horizon field">
                  <small
                    className="form-text"
                    style={{ color: "rgb(107 107 107 / 75%)" }}
                  >
                    From:{" "}
                  </small>
                  <select
                    className="form-select"
                    style={{
                      border: "none",
                      fontWeight: "bolder",
                      background: "#ffffff00",
                    }}
                    onChange={(e) => setFromEmail(e.target.value)}
                  >
                    {allFromEmails.length ? (
                      <>
                        <option value="">Select one</option>
                        {allFromEmails.map((item, index) => (
                          <option value={item.email}>
                            {item.email} ({item.type})
                          </option>
                        ))}
                      </>
                    ) : (
                      <>
                        <option value="">You have no associated email</option>
                      </>
                    )}
                  </select>
                </div>
                <div className="flex-horizon field">
                  <small
                    className="form-text"
                    style={{ color: "rgb(107 107 107 / 75%)" }}
                  >
                    To:{" "}
                  </small>
                  <input
                    type="email"
                    value={toEmail}
                    onChange={(e) => setToEmail(e.target.value)}
                  />
                </div>
                <div className="flex-horizon field">
                  <small
                    className="form-text"
                    style={{ color: "rgb(107 107 107 / 75%)" }}
                  >
                    Subject:{" "}
                  </small>
                  <input
                    type="text"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </div>
              </div>
              <div className="middle mail-body">
                <ReactQuill
                  theme="snow"
                  value={body}
                  onChange={setBody}
                  modules={modules}
                  formats={formats}
                />
              </div>
              <div
                className="bottom flex-horizon"
                style={{ justifyContent: "space-between", marginTop: "1rem" }}
              >
                <small></small>
                <button
                  onClick={sendMail}
                  className="btn btn-success round-btn"
                  disabled={btnDisabled}
                >
                  Send &#x2192;
                </button>
              </div>
            </div>
          </Toast.Body>
        </Toast>
      </div>
    </>
  );
}

export default ComposeMail;
