import { useEffect, useLayoutEffect, useState } from "react";
import "./css/FormBuilder.css";
import input from "./images/input.svg";
import select from "./images/select.svg";
import list from "./images/list.svg";
import checkbox from "./images/checkbox.svg";
import textarea from "./images/textarea.svg";
import date from "./images/date.svg";
import upload from "./images/upload.svg";
import mailchimp from "./images/mailchimp.png";
import $ from "jquery";
import "jquery-ui/ui/widgets/draggable";
import "jquery-ui/ui/widgets/sortable";
import "jquery-ui/ui/widgets/droppable";
import Header from "./Header";
import Leftnav from "./Leftnav";
import Variables from "./variables";
import axios, { all } from "axios";

function FormBuilder() {
  const [submitBtnLabel, setSubmitBtnLabel] = useState("Submit");
  let formID = "";
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const extracted_id = params.get("id");
  formID = extracted_id;
  const [audiences,setAudiences]=useState([])
  const [selected_audience,setSelected_audience]=useState('')

  function authorizeMailchimp() {
    let req_data = Variables.create_req_data({});
    axios
      .post(Variables.serverUrl("authorize-mailchimp/"), req_data)
      .then((res) => {
        window.open(res.data.url, "Google", "width=500,height=500").focus();
      });
  }

  function addRadioOption() {
    $(this).parents(".form-radio-field").find(".container").append(`
        <span class="option flex-horizon">
            <input class="form-check-label form-control" value="Option" for="flexRadioDefault1"/>
            <font class="remove-option-field">X</font>
        </span>
        `);
    $(".remove-option-field").unbind();
    $(".remove-option-field").on("click", (e) => {
      if ($(e.target).parents(".form-field").find("span").length > 1) {
        $(e.target).parents("span").remove();
      }
    });
  }

  function addCheckboxOption() {
    $(this).parents(".form-checkbox-field").find(".container").append(`
        <span class="option flex-horizon">
            <input class="form-check-label form-control" value="Option" for="flexRadioDefault1"/>
            <font class="remove-option-field">X</font>
        </span>
        `);
    $(".remove-option-field").unbind();
    $(".remove-option-field").on("click", (e) => {
      if ($(e.target).parents(".form-field").find("span").length > 1) {
        $(e.target).parents("span").remove();
      }
    });
  }

  function addSelectOption() {
    $(this).parents(".form-select-field").find(".container").append(`
        <span class="option flex-horizon">
            <input class="form-check-label form-control" value="Option" for="flexRadioDefault1"/>
            <font class="remove-option-field">X</font>
        </span>
        `);
    $(".remove-option-field").unbind();
    $(".remove-option-field").on("click", (e) => {
      if ($(e.target).parents(".form-field").find("span").length > 1) {
        $(e.target).parents("span").remove();
      }
    });
  }

  function submitBtnLabelChange(e) {
    const val = e.target.value;
    setSubmitBtnLabel(val);
    if (val == "") {
      $(e.target).css({
        border: "1px solid red",
      });
    } else {
      $(e.target).css({
        border: "1px solid grey",
      });
    }
  }

  function removeFormFieldEvent() {
    $(".remove-form-field").unbind();
    $(".remove-form-field").on("click", (e) => {
      $(e.target).parents(".form-field").remove();
    });
    $(".remove-option-field").unbind();
    $(".remove-option-field").on("click", (e) => {
      if ($(e.target).parents("span").find("input").length > 1) {
        $(e.target).parents("span").remove();
      }
    });
  }

  function dropped_element(evt, ui) {
    let ele = $(ui.draggable);
    let eleType = ele.attr("type");
    switch (eleType) {
      case "input":
        ele.replaceWith(`
                    <div ele-type="input" class="form-field form-input-field">
                      <div style="display:flex;align-items:center;">
                        <img src=${input} />
                        <input class="input form-control" value="Input name" /><input class="form-control field-name" placeholder="name" />
                        <font class="remove-form-field">X</font>
                      </div>
                      <br/>
                      <select class="form-control input-type">
                        <option value='text'>Text</option>
                        <option value='email'>Email</option>
                        <option value='number'>Number</option>
                      </select>
                    </div>
                `);
        removeFormFieldEvent();
      case "textarea":
        ele.replaceWith(`
                    <div ele-type="textarea" class="form-field form-input-field" style="display:flex;align-items:center;">
                        <img src=${textarea} />
                        <input class="form-control" value="Textarea Label" />
                        <input class="form-control field-name" placeholder="name" />
                        <font class="remove-form-field">X</font>
                    </div>
                `);
        removeFormFieldEvent();
      case "radio":
        ele.replaceWith(`
                    <div ele-type="radio" class="form-field form-options-field form-radio-field">
                      <div style="display:flex;align-items:center;">
                        <img src=${select} /><input class="radio form-control" value="Label 1" /><input class="form-control field-name" placeholder="name" />
                        <font class="remove-form-field">X</font>
                      </div>
                        <div class="container">
                            <br/>
                            <span class="option flex-horizon">
                                <input class="form-check-label form-control" for="flexRadioDefault1" value="Option 1">
                                <font class="remove-option-field">X</font>
                            </span>
                        </div>
                        <small onclick="${addRadioOption()}">+ Add new option</small>
                    </div>
                `);
        $(".form-radio-field small").unbind();
        $(".form-radio-field small").on("click", addRadioOption);
        removeFormFieldEvent();
      case "checkbox":
        ele.replaceWith(`
                    <div ele-type="checkbox" class="form-field form-options-field form-checkbox-field">
                    <div style="display:flex;align-items:center;">
                      <img src=${checkbox} /><input class="checkbox form-control" value="Label 1" /><input class="form-control field-name" placeholder="name" />
                      <font class="remove-form-field">X</font>
                    </div>
                        <div class="container">
                        <br/>
                            <span class="option flex-horizon">
                                <input class="form-check-label form-control" for="flexRadioDefault1" value="Option 1" />
                                <font class="remove-option-field">X</font>
                            </span>
                        </div>
                        <small onclick="${addCheckboxOption()}">+ Add new option</small>
                    </div>
                `);
        $(".form-checkbox-field small").unbind();
        $(".form-checkbox-field small").on("click", addCheckboxOption);
        removeFormFieldEvent();
      case "select":
        ele.replaceWith(`
                    <div ele-type="select" class="form-field form-options-field form-select-field">
                    <div style="display:flex;align-items:center;">
                      <img src=${list} /><input class="select form-control" value="Label 1" /><input class="form-control field-name" placeholder="name" />
                      <font class="remove-form-field">X</font>
                    </div>
                        <div class="container">
                        <br/>
                            <span class="option flex-horizon">
                                <input class="form-check-label form-control" for="flexRadioDefault1" value="Option 1"/>
                                <font class="remove-option-field">X</font>
                            </span>
                        </div>
                        <small onclick="${addSelectOption()}">+ Add new option</small>
                    </div>
                `);
        $(".form-select-field small").unbind();
        $(".form-select-field small").on("click", addSelectOption);
        removeFormFieldEvent();
      case "date":
        ele.replaceWith(`
                    <div ele-type="date" class="form-field form-input-field">
                    <div style="display:flex;align-items:center;">
                      <img src=${date} /><input class="date form-control" value="Date label" /><input class="form-control field-name" placeholder="name" />
                      <font class="remove-form-field">X</font>
                    </div>
                    </div>
                `);
        removeFormFieldEvent();
      case "file":
        ele.replaceWith(`
                    <div ele-type="file" class="form-field form-input-field">
                    <div style="display:flex;align-items:center;">
                      <img src=${upload} /><input class="file form-control" value="Upload label" />
                      <font class="remove-form-field">X</font>
                    </div>
                    </div>
                `);
        removeFormFieldEvent();
    }
  }
  const [formData, setFormData] = useState([]);
  useLayoutEffect(() => {
    async function checkAuth() {
      let status = await Variables.checkAuthentication();
      let req_data = Variables.create_req_data({
        id: formID,
      });
      await axios.post(Variables.serverUrl("form/"), req_data).then((res) => {
        if (!res.data.mailchimp) {
          $("#mailchimp").show();
        } else {
          $("#mailchimp-audience").show()
          setSelected_audience(res.data.selected_audience)
          setAudiences(res.data.mailchimp_audience)
          console.log(res.data.mailchimp_audience)
          $("#audience-lists").val(res.data.selected_audience).attr("selected", "selected");
        }
        setFormData(JSON.parse(res.data.form)[0].fields);
        let data = JSON.parse(JSON.parse(res.data.form)[0].fields.data);
        data.forEach((obj, i) => {
          switch (Object.keys(obj)[0]) {
            case "input":
              let all_options;
              if (obj.input.input_type == "email") {
                all_options = `<option value='text'>Text</option>
                  <option value='email' selected>Email</option>
                  <option value='number'>Number</option>`;
              } else if (obj.input.input_type == "text") {
                all_options = `<option value='text' selected>Text</option>
                  <option value='email'>Email</option>
                  <option value='number'>Number</option>`;
              } else if (obj.input.input_type == "number") {
                all_options = `<option value='text'>Text</option>
                  <option value='email'>Email</option>
                  <option value='number' selected>Number</option>`;
              }
              $("#form-builder-sortable").append(`
                <div ele-type="input" class="form-field form-input-field">
                <div style="display:flex;align-items:center;">
                  <img src=${input} />
                  <input class="input form-control" value="${obj.input.label}" /><input class="form-control field-name" placeholder="name" value="${obj.input.name}" />
                  <font class="remove-form-field">X</font>
                </div>
                <br/>
                <select class="form-control input-type">
                  ${all_options}
                </select>
              </div>
                `);
              break;
            case "textarea":
              $("#form-builder-sortable").append(`
                <div ele-type="textarea" class="form-field form-input-field" style="display:flex;align-items:center;">
                    <img src=${textarea} />
                    <input class="form-control" value="${obj.textarea.label}" /><input class="form-control field-name" placeholder="name" value="${obj.textarea.name}" />
                    <font class="remove-form-field">X</font>
                </div>
                `);
              break;
            case "radio":
              let options_eles = "";
              for (let i = 0; i < obj.radio.options.length; i++) {
                options_eles += `<span class="option flex-horizon">
                    <input class="form-check-label form-control" for="flexRadioDefault1" value="${obj.radio.options[i]}">
                    <font class="remove-option-field">X</font>
                  </span>`;
              }
              $("#form-builder-sortable").append(`
                <div ele-type="radio" class="form-field form-options-field form-radio-field">
                      <div style="display:flex;align-items:center;">
                        <img src=${select} /><input class="radio form-control" value="${obj.radio.label}" />
                        <input class="form-control field-name" placeholder="name" value="${obj.radio.name}" />
                        <font class="remove-form-field">X</font>
                      </div>
                        <div class="container">
                            <br/>
                            ${options_eles}
                        </div>
                    </div>
                `);
              break;
            case "checkbox":
              let checkbox_options = "";
              for (let i = 0; i < obj.checkbox.options.length; i++) {
                checkbox_options += `<span class="option flex-horizon">
                    <input class="form-check-label form-control" for="flexRadioDefault1" value="${obj.checkbox.options[i]}">
                    <font class="remove-option-field">X</font>
                  </span>`;
              }
              $("#form-builder-sortable").append(`
                <div ele-type="checkbox" class="form-field form-options-field form-checkbox-field">
                    <div style="display:flex;align-items:center;">
                      <img src=${checkbox} /><input class="checkbox form-control" value="${obj.checkbox.label}" /><input class="form-control field-name" placeholder="name" value="${obj.checkbox.name}" />
                      <font class="remove-form-field">X</font>
                    </div>
                        <div class="container">
                        <br/>
                            ${checkbox_options}
                        </div>
                    </div>
                `);
              break;
            case "select":
              let select_options = "";
              for (let i = 0; i < obj.select.options.length; i++) {
                select_options += `<span class="option flex-horizon">
                    <input class="form-check-label form-control" for="flexRadioDefault1" value="${obj.select.options[i]}">
                    <font class="remove-option-field">X</font>
                  </span>`;
              }
              $("#form-builder-sortable").append(`
                <div ele-type="select" class="form-field form-options-field form-select-field">
                    <div style="display:flex;align-items:center;">
                      <img src=${list} /><input class="select form-control" value="${obj.select.label}" /><input class="form-control field-name" placeholder="name" value="${obj.select.name}" />
                      <font class="remove-form-field">X</font>
                    </div>
                        <div class="container">
                        <br/>
                            ${select_options}
                        </div>
                    </div>
                `);
              break;
            case "date":
              $("#form-builder-sortable").append(`
                  <div ele-type="date" class="form-field form-input-field">
                    <div style="display:flex;align-items:center;">
                      <img src=${date} /><input class="date form-control" value="${obj.date.label}" /><input class="form-control field-name" placeholder="name" value="${obj.date.name}" />
                      <font class="remove-form-field">X</font>
                    </div>
                  </div>
                `);
              break;
            case "file":
              $("#form-builder-sortable").append(`
                <div ele-type="file" class="form-field form-input-field">
                  <div style="display:flex;align-items:center;">
                    <img src=${upload} /><input class="file form-control" value="${obj.file.label}" /><input class="form-control field-name" placeholder="name" value="${obj.file.name}" />
                    <font class="remove-form-field">X</font>
                  </div>
                </div>
                `);
              break;
          }
        });
        removeFormFieldEvent();
      });
    }
    checkAuth.call();
  }, []);

  useEffect(() => {
    $("#form").on("submit", async function (e) {
      e.preventDefault();
      Variables.loading_btn("#form-save-btn", "Saving");
      let name = $("#form-name").val();
      let message = $("#show-message").val();
      let btn_txt = $("#submit-btn-txt").val();
      let audience=$("#audience-lists").find(":selected").val()
      let data = [];
      $("#form-builder-sortable .form-field").each((i, obj) => {
        let field = {};
        if ($(obj).attr("ele-type") == "input") {
          field["input"] = {
            label: $(obj).find("input:first-of-type").val(),
            input_type: $(obj).find(".input-type").val(),
            required: "",
            name: $(obj).find(".field-name").val(),
          };
          data.push(field);
        } else if ($(obj).attr("ele-type") == "textarea") {
          field["textarea"] = {
            label: $(obj).find("input:first-of-type").val(),
            required: "",
            name: $(obj).find(".field-name").val(),
          };
          data.push(field);
        } else if ($(obj).attr("ele-type") == "radio") {
          let options_lst = [];
          let options = $(obj).find(".option");
          options.each((i, element) => {
            options_lst.push($(element).find("input").val());
          });
          field["radio"] = {
            label: $(obj).find(".radio").val(),
            required: "",
            options: options_lst,
            name: $(obj).find(".field-name").val(),
          };
          data.push(field);
        } else if ($(obj).attr("ele-type") == "select") {
          let options_lst = [];
          let options = $(obj).find(".option");
          options.each((i, element) => {
            options_lst.push($(element).find("input").val());
          });
          field["select"] = {
            label: $(obj).find(".select").val(),
            required: "",
            options: options_lst,
            name: $(obj).find(".field-name").val(),
          };
          data.push(field);
        } else if ($(obj).attr("ele-type") == "checkbox") {
          let options_lst = [];
          let options = $(obj).find(".option");
          options.each((i, element) => {
            options_lst.push($(element).find("input").val());
          });
          field["checkbox"] = {
            label: $(obj).find(".checkbox").val(),
            required: "",
            options: options_lst,
            name: $(obj).find(".field-name").val(),
          };
          data.push(field);
        } else if ($(obj).attr("ele-type") == "date") {
          field["date"] = {
            label: $(obj).find(".date").val(),
            required: "",
            name: $(obj).find(".field-name").val(),
          };
          data.push(field);
        } else if ($(obj).attr("ele-type") == "file") {
          field["file"] = {
            label: $(obj).find(".file").val(),
            required: "",
            name: $(obj).find(".field-name").val(),
          };
          data.push(field);
        }
      });
      let req_data = Variables.create_req_data({
        name: name,
        message: message,
        btn_txt: btn_txt,
        data: JSON.stringify(data),
        action: "update",
        id: formID,
        audience:audience
      });
      await axios
        .post(Variables.serverUrl("form-action/"), req_data)
        .then((res) => {
          Variables.unloading_btn("#form-save-btn");
        });
    });
    const leftNavLinks = document.getElementsByClassName("left-nav-link");
    for (let i = 0; i < leftNavLinks.length; i++) {
      leftNavLinks[i].classList.remove("active");
    }
    document.getElementsByClassName("forms-link")[0].classList.add("active");
    $(function () {
      $(".element-card").draggable({
        connectToSortable: "#form-builder-sortable",
        helper: "clone",
        revert: "invalid",
      });
      $("#form-builder-sortable").droppable({
        drop: dropped_element,
      });
      $("#form-builder-sortable").sortable({
        revert: true,
      });
    });
  }, []);
  return (
    <div className="App">
      <Leftnav />
      <div className="App-body">
        <header className="sticky-top">
          <Header />
        </header>
        <div id="form-builder" className="row">
          <form id="form" className="col-4 elements">
            <br />
            <div id="mailchimp" style={{ display: "none" }}>
              <div
                id="mailchimp-container"
                className="flex-horizon"
                onClick={authorizeMailchimp}
              >
                <img style={{ height: "40px" }} src={mailchimp} />
                <b>Connect to Mailchimp &#x2192;</b>
              </div>
              <p style={{ fontSize: ".7rem", lineHeight: "10px" }}>
                Mailchimp integration lets Xbot add leads into your Mailchimp's
                contacts or groups automatically as they arrive.
              </p>
            </div>
            <div id="mailchimp-audience" style={{ display: "none" }}>
              <select id="audience-lists">
                {audiences.map((obj) => {
                  if (selected_audience == obj.id) {
                    return (
                      <option value={obj.id} selected>
                        {obj.name}
                      </option>
                    )
                  } else {
                    return <option value={obj.id}>{obj.name}</option>;
                  }
                })}
                <option value="">None</option>
              </select>
              <br/>
              <div
                id="mailchimp-container"
                className="flex-horizon"
                style={{borderRadius:0}}
              >
                <img style={{ height: "25px" }} src={mailchimp} />
                <b>Choose a mailchimp audience to add your leads. &#x2192;</b>
              </div>
            </div>
            <br />
            <h5 className="title-color">Form builder</h5>
            <input
              id="form-name"
              type="text"
              className="form-control"
              placeholder="Name of the form"
              defaultValue={formData.title}
            />
            <br />
            <h6>Drag and drop the fields to the right to build your form</h6>
            <div className="element-cards row">
              <div
                type="input"
                className="col-6 element-card card ui-state-highlight"
              >
                <img src={input} />
                <label>Input</label>
              </div>
              <div type="textarea" className="col-6 element-card card">
                <img src={textarea} />
                <label>Textarea</label>
              </div>
              <div type="radio" className="col-6 element-card card">
                <img src={select} />
                <label>Radio</label>
              </div>
              <div type="checkbox" className="col-6 element-card card">
                <img src={checkbox} />
                <label>Checkbox</label>
              </div>
              <div type="select" className="col-6 element-card card">
                <img src={list} />
                <label>Select</label>
              </div>
              <div type="date" className="col-6 element-card card">
                <img src={date} />
                <label>Date</label>
              </div>
              <div type="file" className="col-6 element-card card">
                <img src={upload} />
                <label>File</label>
              </div>
            </div>
            <div className="form-end">
              <label>Message to show on form submit</label>
              <textarea
                id="show-message"
                defaultValue={formData.submit_message}
                className="form-control"
              ></textarea>
              <br />
              <label>Submit button label</label>
              <input
                id="submit-btn-txt"
                className="form-control"
                onInput={submitBtnLabelChange}
                type="text"
                placeholder="submit"
                maxLength={20}
                defaultValue={formData.submit_button}
              />
              <small style={{ "font-size": ".8rem", color: "grey" }}>
                Maximum 20 characters
              </small>
              <br />
              <br />
              <button
                id="form-save-btn"
                type="submit"
                className="btn theme-btn"
              >
                Save
              </button>
              <br />
              <br />
            </div>
          </form>
          <div className="col-8 work-space">
            <div id="form-builder-sortable"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormBuilder;
