import axios from "axios";
import { useEffect, useLayoutEffect, useState } from "react";
import Variables from "./variables";
import $ from "jquery";
import { HomeMenu } from "./home/HomeMenu";
import Header from "./Header";
import Leftnav from "./Leftnav";
import Banner from "./banner";
import Section1 from "./intent/Section1";
import Section2 from "./intent/Section2";
import Section3 from "./intent/Section3";
import "./css/intent.css";

function Intent() {
  let botID = "";
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const extracted_id = params.get("id");
  const [forms, setForms] = useState([]);
  botID = extracted_id;
  const [colHeight, setColHeight] = useState("");
  const [requestedJsonData,setRequestedJsonData]=useState([])
  const [raiseTicket,setRaiseTicket]=useState(false)
  const [ticketData,setTicketData]=useState({})
  const [groups, setGroups]=useState([])
  
  useEffect(() => {
    (async() => {
      let req_data = Variables.create_req_data({});
      await axios
        .post(Variables.serverUrl("all-forms/"), req_data)
        .then(async (res) => {
          setForms(res.data.forms);
        });
    })();
    let total_height = $(window).height();
    let height = total_height - $("#home-menu").outerHeight(true);
    setColHeight(height);
    const links = document.getElementsByClassName("menu-link");
    for (let i = 0; i < links.length; i++) {
      links[i].classList.remove("active");
    }
    $(".bot-intent-link").addClass("active");
    axios.post(Variables.serverUrl("groups/"),Variables.create_req_data({action:"get-all"})).then((res)=>{
      if(res.status==200){
        setGroups(res.data.groups)
      }
    })
  }, []);

  useLayoutEffect(() => {
    async function checkAuth() {
      let status = await Variables.checkAuthentication();
    }
    checkAuth.call();
  }, []);
  return (
    <>
      <div className="App">
        <Leftnav />
        <div className="App-body">
          <header className="sticky-top">
            <Header />
            <Banner />
          </header>
          <div className="home-container sticky-top">
            <HomeMenu />
            <div id="intent" className="row" style={{ padding: "15px" }}>
              <Section1 botID={botID} forms={forms} setRequestedJsonData={setRequestedJsonData} setRaiseTicket={setRaiseTicket} setTicketData={setTicketData} />
              <Section2 all_forms={forms} requestedJsonData={requestedJsonData} raiseTicketState={raiseTicket} actionData={ticketData} groups={groups} />
              <Section3 />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Intent;
