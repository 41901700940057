import tick from "./images/green-tick.png"
import { Link } from "react-router-dom";
import Variables from "./variables";
import { useEffect } from "react";

function PaymentSuccess(){
    useEffect(()=>{
        async function checkAuth() {
            let status = await Variables.checkAuthentication();
            console.log("Authentication message is ", status);
          }
          checkAuth.call();
    },[])
    return <>
        <div className="payment-status" id="success-payment">
        <div className="container">
            <img style={{height:"80px",marginBottom:'10px'}} src={tick}/>
            <h4>Payment Successful</h4>
            <Link className="btn round btn-dark" to="/app/bots">Go to dashboard &#x2192;</Link>
        </div>
    </div>
    </>
}

export default PaymentSuccess;