import Section1 from "./Conversations/Section1";
import Section2 from "./Conversations/Section2";
import Section3 from "./Conversations/Section3";
import Leftnav from "./Leftnav";
import Header from "./Header";
import "./css/conversations.css";
import { useEffect, useLayoutEffect, useState } from "react";
import Variables from "./variables";

function Conversations() {
  const [conversation,setConversation]=useState([])
  useLayoutEffect(() => {
    async function checkAuth() {
      let status = await Variables.checkAuthentication();
    }
    checkAuth.call();
  });
  useEffect(() => {
    const leftNavLinks = document.getElementsByClassName("left-nav-link");
    for (let i = 0; i < leftNavLinks.length; i++) {
      leftNavLinks[i].classList.remove("active");
    }
    document
      .getElementsByClassName("dashboard-link")[0]
      .classList.add("active");
  }, []);
  return (
    <div className="App">
      <Leftnav />
      <div className="App-body">
        <header className="sticky-top">
          <Header />
        </header>
        <div id="conversations">
          <div className="row container">
            <Section1 setTheConversation={setConversation} />
            <Section2 conversation={conversation} />
            <Section3 conversation={conversation} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Conversations;
