import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "../css/NewBot.css";
import Variables from "../variables";
import axios from "axios";

function DeleteMember({ show, handleClose, members, setMembers, uid }) {
  const deleteMember=(e)=>{
    const indexToDelete = members.findIndex((item) => item.uid === uid);
    let req_data = Variables.create_req_data({action: "delete",uid:uid});
    Variables.loading_btn("#delete-member-btn","Deleting")
    axios.post(Variables.serverUrl("members/"),req_data).then((res)=>{
      if(res.status==200){
        Variables.unloading_btn("#delete-member-btn")
        handleClose()
        if (indexToDelete !== -1) {
          members.splice(indexToDelete, 1);
          setMembers(members);
        }
      }
    })
  }
  return (
    <>
      <Modal
        id="delete-member"
        show={show}
        onHide={handleClose}
        backdrop="static"
        fullscreen="md-down"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b>Note- </b>
          <font>
            Delete the member.
          </font>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            id="delete-member-btn"
            className="theme-btn"
            variant="primary"
            onClick={deleteMember}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteMember;
