import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Variables from "../variables";
import $ from "jquery";
import axios from "axios";
import { useEffect, useState } from "react";

function SelectBots({ show, handleClose, selectBotFunction }) {
  const [bots, setBots] = useState([]);
  let selectedBots = [];
  useEffect(() => {
    let req_data = Variables.create_req_data({ action: "get" });
    axios.post(Variables.serverUrl("all-bots/"), req_data).then((res) => {
      setBots(res.data.bots);
    });
  }, []);
  async function sendBots() {
    $(".selectableBot:checked").each(function () {
      var uid = $(this).attr("uid");
      var name = $(this).attr("title");
      selectedBots.push({ uid: uid, name: name });
    });
    if (selectedBots) {
      await selectBotFunction({ bots: selectedBots });
      handleClose();
    }
  }
  return (
    <>
      <Modal
        id="select-bot"
        show={show}
        onHide={handleClose}
        backdrop="static"
        fullscreen="md-down"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select a bot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {bots.length > 0 ? (
            <ul id="all-bots">
              {bots.map((ele, i) => (
                <li key={i} className="limit-text">
                  <input
                    className="form-check-input selectableBot"
                    type="checkbox"
                    name="selectedForm"
                    uid={ele.uid}
                    title={ele.name}
                  />
                  &nbsp;&nbsp;
                  <a
                    className="selectableForm"
                    target="_blank"
                    href={"/app/insights/?id=" + ele.uid}
                  >
                    {ele.name}
                  </a>
                </li>
              ))}
            </ul>
          ) : (
            <p>No bot available</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button id="send-bot-btn" className="theme-btn" onClick={sendBots} variant="primary">
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SelectBots;
