import { useEffect, useState } from "react";
import camera from "../images/camera.svg";
import { ChromePicker } from "react-color";
import pencil from "../images/pencil.svg";
import $ from "jquery";
import Variables from "../variables";
import axios from "axios";
import { json, useNavigate } from "react-router";
import EditBtn from "../modals/EditBtn";

function CustomizationContent() {
  const [btnEle, setBtnEle] = useState("");
  const [showEditBtn, setEditBtnShow] = useState(false);
  const handleEditBtnClose = () => setEditBtnShow(false);
  const handleEditBtnShow = function (e) {
    setBtnEle(e.target);
    setEditBtnShow(true);
  };
  const [userIcon, setUserIcon] = useState(
    "https://cdn.sharurai.com/bot-icon/bot.svg"
  );
  const [colorPickerColor, setColorPickerColor] = useState("#37d67a");
  const navigate = new useNavigate();
  let botID = "";
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const extracted_id = params.get("id");
  botID = extracted_id;
  const [botName, setBotName] = useState("");
  const [botDescription, setBotDescription] = useState("");
  const [botLanguage, setBotLanguage] = useState("");
  const [themeColor, setThemeColor] = useState("");
  const [botTextColor, setBotTextColor] = useState("");
  const [botPopupText, setBotPopupText] = useState("");
  const [botTrigger, setTrigger] = useState("");
  const [botPresetForm, setBotPresetForm] = useState("");
  const [botGreetingMsg, setBotGreetingMsg] = useState("");
  const [botPresetButtons, setBotPresetButtons] = useState("");
  const [botPresetMsg, setBotPresetMsg] = useState("");
  const [botShowBtnInWholeConversation, setBotShowBtnInWholeConversation] =
    useState(false);
  const [botShowMsgInWholeConversation, setBotShowMsgInWholeConversation] =
    useState(false);
  const [botConfirmationDelay, setBotConfirmationDelay] = useState("");
  const [botNotificationDelay, setBotNotificationDelay] = useState("");
  const [askForFeedback, setaskForFeedback] = useState(false);
  const [forms, setForms] = useState([]);
  const [kb, setKb] = useState([]);
  const [temperature, setTemperature] = useState("");
  const [behavior, setBehavior] = useState("");
  const [botLeadForm, setBotLeadForm] = useState("");
  const [oldKb, setOldKb] = useState("");
  const [models, setModels] = useState([]);
  const [currentModel, setCurrentModel] = useState("");

  function change_bot_name(e) {
    let val = $(e.target).val();
    $("#preview-bot-name").text(val);
  }

  function getBotInfo(id) {
    let req_data = Variables.create_req_data({ bot_id: id });
    axios
      .post(Variables.serverUrl("get-bot-info/"), req_data)
      .then(async (res) => {
        let bot = JSON.parse(res.data.bot)[0].fields;
        if (res.data.status > 400) {
          if (res.data.relogin) {
            localStorage.removeItem("sharurai_access_key");
            localStorage.removeItem("sharurai_refresh_key");
            navigate("/login/");
          }
        } else if (res.data.status >= 200) {
          setCurrentModel(bot.model);
          setModels(JSON.parse(res.data.models));
          setUserIcon(bot.bot_icon);
          setBotName(bot.name);
          setBotDescription(bot.description);
          setBotLanguage(bot.language);
          setThemeColor(bot.theme_color);
          setBotTextColor(bot.text_color);
          setBotPopupText(bot.popup_text);
          setBotPresetButtons(bot.preset_btns);
          setTrigger(bot.triggers);
          setBotPresetForm(bot.preset_form);
          setBotGreetingMsg(bot.greeting_msg);
          setBotPresetMsg(bot.preset_message);
          setBotConfirmationDelay(bot.confirmation_delay);
          setBotNotificationDelay(bot.notification_delay);
          setaskForFeedback(bot.ask_for_feedback);
          setBotShowBtnInWholeConversation(bot.show_btns_in_whole_conversation);
          setBotShowMsgInWholeConversation(bot.show_msg_in_whole_conversation);
          setBehavior(bot.behavior);
          setBotLeadForm(bot.lead_form);
          setOldKb(bot.Knowledge_base);
          setTemperature(Number(bot.temperature));
          $("#test-chat-icon").css({
            background: `url('${bot.bot_icon}')`,
            "background-position": "center",
            "background-size": "cover",
            "background-repeat": "no-repeat",
          });
          $(`#preset-form`).val(bot.preset_form).attr("selected", "selected");
          $(`#lead-form`).val(bot.lead_form).attr("selected", "selected");
          $(`#knowledge-base`)
            .val(bot.Knowledge_base)
            .attr("selected", "selected");
          $("#" + bot.triggers).addClass("active");
          $("#preview-bot-description p").html(bot.description);
          $("#preview-bot-name").html(bot.name);
          $("#bot-language").val(bot.language).attr("selected", "selected");
          changedThemeColor({ hex: bot.theme_color });
          $("#bot-language").val(bot.language).attr("selected", "selected");
          $("#text-color").val(bot.text_color).attr("selected", "selected");
          JSON.parse(bot.preset_btns).map((button, i) => {
            let element = $(`
            <li>
              <div class="theme-btn limit-text preset-btn">${button.name}</div>
              &nbsp; &nbsp;
              <img
                class="edit-preset-btns"
                src="${pencil}"
                do="Edit"
                name="${button.name}"
                action-type="${button.action_type}"
                data="${button.data}"
              />
              <font class="remove-btn">x</font>
            </li>
            `);
            $(element).find("img").on("click", handleEditBtnShow);
            $(element)
              .find(".remove-btn")
              .on("click", function () {
                $(element).remove();
              });
            $("#preset-btns").prepend(element);
          });
        }
      });
  }
  useEffect(() => {
    //All forms
    (async () => {
      let req_data = Variables.create_req_data({});
      await axios
        .post(Variables.serverUrl("all-forms/"), req_data)
        .then(async (res) => {
          setForms(res.data.forms);
        });
      let req_data2 = Variables.create_req_data({ action: "get" });
      await axios
        .post(Variables.serverUrl("knowledge-base/"), req_data2)
        .then(async (res) => {
          setKb(res.data.data);
        });
    })();

    $("#bot-icon").on("click", function () {
      $("#OpenImgUpload").trigger("click");
    });
    $("#OpenImgUpload").on("change", async function () {
      $("#icon-change-msg").show();
      $("#OpenImgUpload").prop("disabled", true);
      $("#bot-icon").css({
        opacity: ".5",
      });
      let response = await Variables.change_icon($(this)[0].files[0], true, {
        url: $("#bot-icon").attr("src"),
        bot_id: botID,
      });
      $("#icon-change-msg").hide();
      $("#OpenImgUpload").prop("disabled", false);
      $("#bot-icon").css({
        opacity: "1",
      });
      setUserIcon(response.url);
      $("#test-chat-icon").css({
        background: `url('${response.url}')`,
        "background-position": "center",
        "background-size": "cover",
        "background-repeat": "no-repeat",
      });
    });

    getBotInfo(botID);
    $("#preset-btn-whole-conversation,#preset-msg-whole-conversation").on(
      "click",
      function () {
        if ($(this).is(":checked")) {
          $(this).removeProp("checked");
          $(this).attr("value", "true");
        } else {
          $(this).prop("checked", this.checked);
          $(this).attr("value", "false");
        }
      }
    );

    $("#bot-description").on("input", function () {
      let val = $(this).val();
      $("#preview-bot-description p").html(val);
    });
    $(".remove-btn").on("click", function () {
      $(this).parent("li").remove();
    });
    $("#basic-info-form").on("submit", async function (e) {
      e.preventDefault();
      Variables.loading_btn("#basic-info-save-btn", "Saving");
      let req_data = Variables.create_req_data({
        bot_id: botID,
        name: $("#bot-name").val(),
        language: $("#bot-language").val(),
        description: $("#bot-description").val(),
        action: "update",
      });
      await axios
        .post(Variables.serverUrl("bot-info/basic-info/"), req_data)
        .then(async (res) => {
          if (res.data.status > 400) {
            if (res.data.relogin) {
              localStorage.removeItem("sharurai_access_key");
              localStorage.removeItem("sharurai_refresh_key");
              navigate("/login/");
            }
          } else {
            Variables.unloading_btn("#basic-info-save-btn");
          }
        });
    });
    $("#look-feel-form").on("submit", async function (e) {
      e.preventDefault();
      Variables.loading_btn("#look-feel-save-btn", "Saving");
      let req_data = Variables.create_req_data({
        action: "appearance",
        theme_color: $("#theme-color").val(),
        text_color: $("#text-color").val(),
        bot_id: botID,
      });
      await axios
        .post(Variables.serverUrl("bot-info/appearance-style/"), req_data)
        .then(async (res) => {
          if (res.data.status > 400) {
            if (res.data.relogin) {
              localStorage.removeItem("sharurai_access_key");
              localStorage.removeItem("sharurai_refresh_key");
              navigate("/login/");
            }
          } else if (res.status >= 200) {
            Variables.unloading_btn("#look-feel-save-btn");
          }
        });
    });
    $("#triggers li").on("click", function () {
      $("#triggers li").removeClass("active");
      $(this).addClass("active");
    });
    $("#text-color").on("change", function () {
      let ele = this;
      $(
        "#chatbot-container .container > .top font,#chatbot-container .container > .top b,#chatbot-container .container > .top p"
      ).css({
        color: $(ele).val(),
      });
    });
    $("#advance-settings").on("submit", async function (e) {
      e.preventDefault();
      let preset_btns = [];
      $("#preset-btns li").each(function (i, obj) {
        let name = $(obj).find(".edit-preset-btns").attr("name");
        let action_type = $(obj).find(".edit-preset-btns").attr("action-type");
        let data = $(obj).find(".edit-preset-btns").attr("data");
        preset_btns.push({
          name: name,
          action_type: action_type,
          data: data,
        });
      });
      let req_data = Variables.create_req_data({
        action: "advance",
        bot_id: botID,
        popup_text: $("#popup-text").val(),
        temperature: $("#temperature").val(),
        behavior: $("#behavior").val(),
        triggers: $("#triggers").find(".active").attr("id"),
        preset_form: $("#preset-form option:selected").val() || "",
        lead_form: $("#lead-form option:selected").val() || "",
        greeting_message: $("#greeting-msg").val(),
        preset_btns: JSON.stringify(preset_btns),
        Knowledge_base: $("#knowledge-base").val(),
        preset_btn_in_whole_conversation: $(
          "#preset-btn-whole-conversation"
        ).attr("value"),
        preset_msg: $("#preset-msg").val(),
        preset_msg_in_whole_conversation: $(
          "#preset-msg-whole-conversation"
        ).attr("value"),
        model:$("#model").val(),
      });
      Variables.loading_btn("#advance-save-btn", "Submitting");
      await axios
        .post(Variables.serverUrl("bot-info/appearance-style/"), req_data)
        .then(async (res) => {
          Variables.unloading_btn("#advance-save-btn");
        });
    });
    $("#end-chat-form").on("submit", async function (e) {
      e.preventDefault();
      Variables.loading_btn("#end-chat-submit-btn", "Saving");
      let req_data = Variables.create_req_data({
        action: "end_chat",
        bot_id: botID,
        confirmation_delay: $("#confirmation-delay").val(),
        notification_delay: $("#notification-delay").val(),
      });
      await axios
        .post(Variables.serverUrl("bot-info/appearance-style/"), req_data)
        .then(async (res) => {
          Variables.unloading_btn("#end-chat-submit-btn");
        });
    });
  }, []);
  function changedThemeColor(ele) {
    setColorPickerColor(ele.hex);
    $("#chatbot-container .container > .top, #send-btn").css({
      background: ele.hex,
    });
    $("#theme-color").val(ele.hex);
  }
  return (
    <div className="CustomizationContent">
      <h5>Basic info</h5>
      <b id="icon-change-msg" style={{ display: "none", color: "#2cb22c" }}>
        Changing icon...
      </b>
      <div className="icon">
        <img id="bot-icon" src={userIcon} />
        <img className="camera" src={camera} />
        <font>
          The icon above will be used as the launcher icon for the chatbot
        </font>
        <input
          type="file"
          id="OpenImgUpload"
          accept="image/*"
          style={{ display: "none" }}
        />
      </div>
      <br />
      <form id="basic-info-form">
        <label>Name your bot</label>
        <input
          id="bot-name"
          className="form-control"
          maxLength={20}
          defaultValue={botName}
          onInput={change_bot_name}
          required
        />
        <small style={{ color: "grey" }}>Max. length is 20 letters</small>
        <br />
        <label>Preferred language</label>
        <select
          id="bot-language"
          className="language form-select"
          defaultSelected={botLanguage}
        >
          <option value="arabic">Arabic</option>
          <option value="bulgarian">Bulgarian</option>
          <option value="chinese">Chinese</option>
          <option value="czech">Czech</option>
          <option value="danish">Danish</option>
          <option value="dutch">Dutch</option>
          <option value="english" selected>
            English
          </option>
          <option value="estonian">Estonian</option>
          <option value="finnish">Finnish</option>
          <option value="french">French</option>
          <option value="german">German</option>
          <option value="greek">Greek</option>
          <option value="hebrew">Hebrew</option>
          <option value="hindi">Hindi</option>
          <option value="hungarian">Hungarian</option>
          <option value="indonesian">Indonesian</option>
          <option value="italian">Italian</option>
          <option value="japanese">Japanese</option>
          <option value="korean">Korean</option>
          <option value="latvian">Latvian</option>
          <option value="lithuanian">Lithuanian</option>
          <option value="norwegian">Norwegian</option>
          <option value="polish">Polish</option>
          <option value="portuguese">Portuguese</option>
          <option value="romanian">Romanian</option>
          <option value="russian">Russian</option>
          <option value="slovak">Slovak</option>
          <option value="slovenian">Slovenian</option>
          <option value="spanish">Spanish</option>
          <option value="swedish">Swedish</option>
          <option value="thai">Thai</option>
          <option value="turkish">Turkish</option>
          <option value="ukrainian">Ukrainian</option>
          <option value="vietnamese">Vietnamese</option>
          <option value="choose">Let user choose</option>
        </select>
        <label>Description</label>
        <textarea
          class="form-control"
          id="bot-description"
          defaultValue={botDescription}
          maxLength={120}
        ></textarea>
        <small style={{ color: "grey" }}>Max. length is 80 letters</small>
        <br />
        <br />
        <button id="basic-info-save-btn" className="btn theme-btn">
          Save changes
        </button>
        <br />
        <br />
        <br />
        <br />
      </form>
      <h5>Look and feel</h5>
      <form id="look-feel-form">
        <label class="form-check-label" for="flexRadioDefault1">
          Theme color
        </label>
        <ChromePicker color={colorPickerColor} onChange={changedThemeColor} />
        <input id="theme-color" style={{ display: "none" }} />
        <label class="form-check-label" for="flexRadioDefault1">
          Text color
        </label>
        &nbsp; &nbsp;
        <select id="text-color" className="form-select">
          <option defaultValue="#000">Black</option>
          <option defaultValue="#fff" selected>
            White
          </option>
        </select>
        <br />
        <br />
        <button id="look-feel-save-btn" className="btn theme-btn">
          Save changes
        </button>
      </form>
      <br />
      <br />
      <br />

      <h5>Advance settings</h5>
      <form id="advance-settings">
        <label>AI model</label>
        <br />
        <select id="model" className="form-select">
          {models.map((item, index) => (
            <>
              {currentModel == item.value ? (
                <>
                  <option value={item.value} selected>
                    {item.name}
                  </option>
                </>
              ) : (
                <>
                  <option value={item.value}>{item.name}</option>
                </>
              )}
            </>
          ))}
        </select>
        <label>Temperature</label>
        <br />
        <small style={{ color: "grey" }}>
          This parameter determines the creativity and diversity of the text
          generated by the AI model. The value ranges from 0 to 1.
        </small>
        <br />
        <input
          className="form-control"
          id="temperature"
          type="number"
          step=".1"
          max="1"
          min="0"
          defaultValue={temperature}
        />
        <label>Popup text</label>
        <br />
        <small style={{ color: "grey" }}>
          Text displayed with the chatbot icon to interact with users
        </small>
        <div>
          <input
            id="popup-text"
            defaultValue={botPopupText}
            className="form-control"
            type="text"
          />
        </div>
        <label>Behavior</label>
        <br />
        <small style={{ color: "grey" }}>
          This parameter determines the behavior of your chatbot.
        </small>
        <br />
        <textarea
          className="form-control"
          id="behavior"
          defaultValue={behavior}
        ></textarea>
        <label class="form-check-label" for="flexRadioDefault1">
          Knowledge base
        </label>
        <br />
        <small style={{ color: "grey" }}>
          Knowledge base is crucial for AI chatbots to provide accurate,
          relevant, and helpful responses to user queries, enhancing user
          experience.
        </small>
        <br />
        <select id="knowledge-base" className="form-select" width="100%">
          {kb.map((obj) => {
            if (oldKb == obj.uid) {
              return (
                <option value={obj.uid} selected>
                  {obj.name}
                </option>
              );
            } else {
              return <option value={obj.uid}>{obj.name}</option>;
            }
          })}
          <option value="">None</option>
        </select>
        <br />
        <label class="form-check-label" for="flexRadioDefault1">
          Lead form
        </label>
        <br />
        <select id="lead-form" className="form-select" width="100%">
          {forms.map((obj) => {
            if (botLeadForm == obj.uid) {
              return (
                <option value={obj.uid} selected>
                  {obj.title}
                </option>
              );
            } else {
              return <option value={obj.uid}>{obj.title}</option>;
            }
          })}
          <option value="">None</option>
        </select>
        <br />
        <label>Triggers</label>
        <br />
        <small style={{ color: "grey" }}>
          When should your chat panel popup
        </small>
        <ul id="triggers">
          <li id="click" className="trigger">
            <p>Click</p>
          </li>
          <li id="hover" className="trigger">
            <p>Hover</p>
          </li>
          <li id="FV" className="trigger">
            <p>First visit</p>
          </li>
          <li id="revisit" className="trigger">
            <p>Revisit</p>
          </li>
          <li id="CE" className="trigger">
            <p>Custom event</p>
          </li>
          <li id="PL" className="trigger">
            <p>On page load</p>
          </li>
        </ul>
        <label>Initiate chat options</label>
        <br />
        <small style={{ color: "grey" }}>
          Decide how would you like your chatbot to start a conversation
        </small>
        <div class="form-check">
          <label class="form-check-label" for="flexRadioDefault1">
            Preset form
          </label>
          <br />
          <select id="preset-form" className="form-select" width="100%">
            {forms.map((obj) => {
              if (botPresetForm == obj.uid) {
                return (
                  <option h="f" value={obj.uid} selected>
                    {obj.title}
                  </option>
                );
              } else {
                return (
                  <option h="f" value={obj.uid}>
                    {obj.title}
                  </option>
                );
              }
            })}
            <option defaultValue="">None</option>
          </select>
          <br />
          <br />
          <label>Greeting message</label>
          <textarea
            id="greeting-msg"
            defaultValue={botGreetingMsg}
            class="form-control"
            style={{ width: "100%" }}
          ></textarea>
          <br />
          <label>Preset buttons</label>
          <ul id="preset-btns" class="form-check preset-btns">
            <button
              onClick={handleEditBtnShow}
              id="add-button"
              class="add-preset-btn"
              do="Add"
            >
              + Add new button
            </button>
            <div class="form-check" style={{display:'none'}}>
              {(() => {
                if (botShowBtnInWholeConversation) {
                  return (
                    <input
                      id="preset-btn-whole-conversation"
                      class="form-check-input from-check"
                      type="checkbox"
                      name="flexRadioDefault"
                      defaultValue={botShowBtnInWholeConversation}
                      checked
                    />
                  );
                } else {
                  return (
                    <input
                      id="preset-btn-whole-conversation"
                      class="form-check-input from-check"
                      type="checkbox"
                      name="flexRadioDefault"
                      defaultValue={botShowBtnInWholeConversation}
                    />
                  );
                }
              })()}
              <small
                style={{ color: "grey" }}
                class="form-check-label"
                for="flexRadioDefault1"
              >
                Show in the whole conversation
              </small>
            </div>
            <EditBtn
              show={showEditBtn}
              handleClose={handleEditBtnClose}
              ele={btnEle}
              handleShow={handleEditBtnShow}
              forms={forms}
            />
          </ul>
          <br />
          <label>Preset Messages</label>
          <ul class="preset-btns">
            <li>
              <div className="limit-text">
                <textarea
                  id="preset-msg"
                  className="form-control"
                  placeholder="Your message"
                  defaultValue={botPresetMsg}
                ></textarea>
              </div>
            </li>
            {(() => {
              if (botShowMsgInWholeConversation) {
                return (
                  <input
                    id="preset-msg-whole-conversation"
                    class="form-check-input from-check"
                    type="checkbox"
                    name="flexRadioDefault"
                    style={{display:'none'}}
                    defaultValue={botShowMsgInWholeConversation}
                    checked
                  />
                );
              } else {
                return (
                  <input
                    id="preset-msg-whole-conversation"
                    class="form-check-input from-check"
                    type="checkbox"
                    name="flexRadioDefault"
                    style={{display:'none'}}
                    defaultValue={botShowMsgInWholeConversation}
                  />
                );
              }
            })()}
            <small
              style={{ color: "grey" }}
              class="form-check-label"
              for="flexRadioDefault1"
              style={{display:'none'}}
            >
              Show in the whole conversation
            </small>
          </ul>
        </div>
        <br />
        <button id="advance-save-btn" type="submit" className="btn theme-btn">
          Save changes
        </button>
      </form>
      <br />
      <form id="end-chat-form" style={{ display: "none" }}>
        <label>Confirmation delay</label>
        <br />
        <small style={{ color: "grey" }}>
          When a user should be considered abandoned or left the chat (in
          seconds). The time is counted form the user's last response.
        </small>
        <input
          id="confirmation-delay"
          type="number"
          className="form-control"
          placeholder="Time in seconds"
          defaultValue={botConfirmationDelay}
        />
        <br />
        <label>Notification delay</label>
        <br />
        <small style={{ color: "grey" }}>
          When a user should be notified before considering abandoned. The time
          is counted form the user's last response.
        </small>
        <input
          id="notification-delay"
          type="number"
          className="form-control"
          placeholder="Time in seconds"
          defaultValue={botNotificationDelay}
        />
        <br />
        <button
          id="end-chat-submit-btn"
          type="submit"
          className="btn theme-btn"
        >
          Save changes
        </button>
      </form>
      <br />
      <br />
    </div>
  );
}

export default CustomizationContent;
