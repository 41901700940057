import { useEffect, useState } from "react";
import sentMailIcon from "../images/email-sent.png";
import Email from "../offcanvases/email";
import ComposeMail from "../toasts/ComposeMail";
import axios from "axios";
import Variables from "../variables";
import $ from "jquery";

let is_member = localStorage.getItem("sharurai_member_login");

function Emails({ data }) {
  const [emails, setEmails] = useState([]);
  const [selectedMail, setSelectedMail] = useState({});
  const [selectedMails, setSelectedMails] = useState([]);
  const [showMail, setShowMail] = useState(false);
  const [deleteDisabled, setDeleteDisabled] = useState(true);
  const handleShowMail = (e) => {
    setShowMail(true);
    let uid = $(e.target).attr("id");
    setSelectedMail(emails.find((item) => item.uid === uid));
  };
  useEffect(() => {
    if (selectedMails.length) {
      setDeleteDisabled(false);
    } else {
      setDeleteDisabled(true);
    }
  }, [selectedMails]);
  const handleCloseMail = () => setShowMail(false);
  const [showComposeEmail, setShowComposeEmail] = useState(false);
  useEffect(() => {
    axios
      .post(
        Variables.serverUrl("emails/"),
        Variables.create_req_data({ action: "get-all" })
      )
      .then((res) => {
        if (res.status == 200) {
          setEmails(res.data.emails);
        }
      });
  }, []);
  const deleteMails = (e) => {
    Variables.loading_btn(e.target, "Deleting");
    axios
      .post(
        Variables.serverUrl("emails/"),
        Variables.create_req_data({ action: "delete", uids: selectedMails })
      )
      .then((res) => {
        if (res.status == 200) {
          Variables.unloading_btn(e.target);
          const filteredObjects = emails.filter(
            (obj) => !selectedMails.includes(obj.uid)
          );
          setEmails(filteredObjects);
          setSelectedMails([]);
          $("input[type='checkbox']").prop("checked", false);
        }
      });
  };
  return (
    <>
      <br />
      <div className="flex-horizon">
        <img src={sentMailIcon} height={25} />
        &nbsp;&nbsp;
        <h5>Sent mails</h5>
      </div>
      <br />
      <button
        className="btn btn-sm btn-dark theme-btn"
        onClick={() => setShowComposeEmail(true)}
      >
        + Compose mail
      </button>
      {is_member == "true" ? (
        <></>
      ) : (
        <>
          &nbsp;&nbsp;
          <button
            className="btn btn-sm btn-danger"
            disabled={deleteDisabled}
            onClick={deleteMails}
          >
            Delete
          </button>
        </>
      )}
      <br />
      <br />
      <table id="tickets-table" class="table table-hover table-borderless">
        <thead className="table-dark">
          <tr>
            {is_member == "true" ? (
              <></>
            ) : (
              <>
                <th></th>
              </>
            )}
            <th scope="col">From</th>
            <th scope="col">Subject</th>
            <th scope="col">To</th>
            <th scope="col">By</th>
            <th scope="col">Sent on</th>
          </tr>
        </thead>
        <tbody>
          {emails.length ? (
            <>
              {emails.map((item, index) => (
                <tr>
                  <td>
                    {is_member == "true" ? (
                      <></>
                    ) : (
                      <>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onClick={(e) => {
                            if (e.target.checked) {
                              setSelectedMails([...selectedMails, item.uid]);
                            } else {
                              setSelectedMails(
                                selectedMails.filter((uid) => uid !== item.uid)
                              );
                            }
                          }}
                        />
                      </>
                    )}
                  </td>
                  <td className="limit-text">
                    <a href="#" onClick={handleShowMail} id={item.uid}>
                      {item.email}
                    </a>
                  </td>
                  <td>{item.subject}</td>
                  <td className="limit-text">
                    <font href="#">{JSON.parse(item.other_info).to}</font>
                  </td>
                  <td>{JSON.parse(item.other_info).by}</td>
                  <td>{Variables.formatDate(item.date_created)}</td>
                </tr>
              ))}
            </>
          ) : (
            <>No emails composed yet.</>
          )}
        </tbody>
      </table>
      <Email
        show={showMail}
        handleClose={handleCloseMail}
        selectedMail={selectedMail}
      />
      <ComposeMail show={showComposeEmail} setShow={setShowComposeEmail} />
    </>
  );
}

export default Emails;
