import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Variables from "../variables";
import $, { contains } from "jquery";
import axios from "axios";
import { useEffect, useState } from "react";

function create_cards(){
    console.log("Clicked it .")
}

function CreateCards({ show, handleClose }) {
  useEffect(() => {
    // let req_data = Variables.create_req_data({ action: "get" });
    // axios.post(Variables.serverUrl("all-forms/"), req_data).then((res) => {
    //   setForms(res.data.forms);
    // });
  }, [])
  return (
    <>
      <Modal
        id="create-cards"
        show={show}
        onHide={handleClose}
        backdrop="static"
        fullscreen={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create cards</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            hello
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            id="create-cards-btn"
            className="theme-btn"
            variant="primary"
            onClick={create_cards}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateCards;
