import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import "./css/App.css";
import Forms from "./forms";
import FormBuilder from "./FormBuilder";
import Home from "./Home";
import FormSubmission from "./FormSubmission";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import NewBot from "./modals/NewBot";
import Profile from "./Profile";
import Login from "./Login";
import Signup from "./Signup";
import KnowledgeBase from "./KnowledgeBase";
import Bot_training from "./home/Bot_training";
import Bots from "./Bots";
import Conversations from "./Conversations";
import Verify from "./Verify";
import ResetPassword from "./ResetPassword";
import Plans from "./Plans";
import Trial from "./trial";
import PaymentSuccess from "./PaymentSuccess";
import PaymentFailed from "./PaymentFailed";
import Tickets from "./Tickets";
import Groups from "./Groups";
import Members from "./Members";
import MemberLogin from "./MemberLogin";
import Email from "./Email";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/plans"
            element={
              <>
                <Plans />
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            exact
            path="/trial"
            element={
              <>
                <Trial />
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            exact
            path="/app/Knowledge-base"
            element={
              <>
                <Bot_training />
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            exact
            path="/reset-password"
            element={
              <>
                <ResetPassword />
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            exact
            path="/app/conversations"
            element={
              <>
                <Conversations />
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            exact
            path="/app/bots"
            element={
              <>
                <Bots />
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            exact
            path="/verify"
            element={
              <>
                <Verify />
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            exact
            path="/login"
            element={
              <>
                <Login />
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            exact
            path="/sign-up"
            element={
              <>
                <Signup />
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            exact
            path="/app/forms"
            element={
              <>
                <Forms />
              </>
            }
          />
        </Routes>
        <Home />
        <Routes>
          <Route
            exact
            path="/app/form-builder"
            element={
              <>
                <FormBuilder />
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            exact
            path="/app/form-submissions"
            element={
              <>
                <FormSubmission />
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            exact
            path="/app/knowledge-bases"
            element={
              <>
                <KnowledgeBase />
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            exact
            path="/app/profile"
            element={
              <>
                <Profile />
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            exact
            path="/payment/success/"
            element={
              <>
                <PaymentSuccess />
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            exact
            path="/payment/failed/"
            element={
              <>
                <PaymentFailed />
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            exact
            path="/app/tickets"
            element={
              <>
                <Tickets />
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            exact
            path="/app/groups"
            element={
              <>
                <Groups />
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            exact
            path="/app/members"
            element={
              <>
                <Members />
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            exact
            path="/app/login/member"
            element={
              <>
                <MemberLogin />
              </>
            }
          />
        </Routes>
        <Routes>
          <Route
            exact
            path="/app/emails"
            element={
              <>
                <Email />
              </>
            }
          />
        </Routes>
        <NewBot />
      </BrowserRouter>
    </>
  );
}

export default App;
