import pdf from "../images/pdf.svg"
import ppt from "../images/ppt.svg"
import docx from "../images/docx.svg"
import upload from "../images/upload.svg"
import options from "../images/options.svg"
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import $ from 'jquery'
import React, { useEffect} from "react";
import Variables from "../variables";

function Section1() {
    function remove_doc(e){
        $(e.target).closest('.slide').remove()
    }
    useEffect(() => {
        $("#upload-file-input").on("change",function(){
            let files=$(this).prop('files')
            Object.keys(files).forEach((key,val) => {
                let file=files[val]
                var reader = new FileReader();
                reader.readAsDataURL(file)
                reader.onload=function () { 
                    let file_url=reader.result
                    let name=file.name
                    let file_type=file.type.split('/').pop()
                    let file_img=''
                    switch(file_type){
                        case 'pdf':
                            file_img=pdf
                            break
                        case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
                            file_img=docx
                            break

                        case 'vnd.openxmlformats-officedocument.presentationml.presentation':
                            file_img=ppt
                            break
                    }
                    let file_slide=$(`<li class="flex-horizon slide" uploaded=false type='file' file-name="${name}"><div class="content limit-text"><img src="${file_img}"><b class="limit-text">${name}</b><br></div><div class="options dropdown"><img data-bs-toggle="dropdown" src="${options}"><ul class="dropdown-menu"><li><a class="dropdown-item download" href="${file_url}" file-type="${file_type}">Download</a></li><li><a class="dropdown-item remove">Remove</a></li></ul></div></li>`)
                    $(file_slide).find('.remove').on('click',remove_doc)
                    $("#files-list").prepend(file_slide)
                 }
            });
        })
        $('#upload-card').on('click',()=>{
            $("#upload-file-input").trigger('click')
        })
    }, [])
    return (
        <div className="row files">
            <div className="upload-file-card col-sm-12 col-md-6 col-lg-6 flex-horizon">
                <div id='upload-card' className="card">
                    <img src={upload} />
                    <b style={{color:'black'}}>Drop your file or Browse</b>
                    <small style={{color:'grey'}}>Max file size - 10M<br/><br/>Supported file types <br/> (.pdf, .xlsx, .csv, .docx, .txt)</small>
                    <br/>
                </div>
                <input id="upload-file-input" multiple="multiple" accept=".pdf, .xlsx, .csv, .docx, .txt" type="file" style={{display:'none'}} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
                <h5><b>Uploaded files</b></h5>
                <ul id="files-list" className="files-list">
                </ul>
            </div>
        </div>
    )
}

export default Section1;