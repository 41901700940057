import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Variables from "../variables";
import $ from "jquery";
import axios from "axios";

function DeleteBot({ show, handleClose, data }) {
  const deleteBot = async function (e) {
    let id = data.id
    console.log(id)
    let req_data = Variables.create_req_data({
      id: id,
      ele: "Bot",
    })
    console.log('trying is ',req_data)
    Variables.loading_btn(e.target, "Deleting");
    await axios.post(Variables.serverUrl("delete/"), req_data).then((res) => {
      $("#"+data.id).remove();
      Variables.unloading_btn(e.target);
      handleClose()
    });
  };
  return (
    <>
      <Modal
        id="delete-bot"
        show={show}
        onHide={handleClose}
        backdrop="static"
        fullscreen="md-down"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>Delete the bot.</h6>
          <p><b>Note- </b>Once you delete the bot, all the conversations and its related data will be deleted permanently.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            id="delete-bot-btn"
            className="btn btn-danger"
            variant="primary"
            onClick={deleteBot}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteBot;
