import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "../css/NewBot.css";
import Variables from "../variables";
import axios from "axios";
import $ from "jquery";
import { useEffect, useState } from "react";

function CreateTicket({ show, handleClose, conv_id, func }) {
  const [subject, setSubject] = useState();
  const [body, setBody] = useState();
  const [dueDate, setDueDate] = useState();
  const [assignedTo, setAssignedTo] = useState();
  const [allMembers, setAllMembers] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [showMembers, setShowMembers] = useState("none");
  const [showGroups, setShowGroups] = useState("none");

  const assignmentChanged = (e) => {
    let assignment = e.target.value;
    setAssignedTo(e.target.value);
    $("button").prop("disabled", true);
    if (assignment == "member") {
      let req_data = Variables.create_req_data({
        action: "get-all",
      });
      if (!allMembers.length) {
        let members = axios
          .post(Variables.serverUrl("members/"), req_data)
          .then((res) => {
            if (res.status == 200) {
              setAllMembers(res.data.members);
              setShowMembers("block");
              setShowGroups("none");
            }
          });
      } else {
        setShowMembers("block");
        setShowGroups("none");
      }
    } else if (assignment == "group") {
      let req_data = Variables.create_req_data({
        action: "get-all",
      });
      if (!allGroups.length) {
        let groups = axios
          .post(Variables.serverUrl("groups/"), req_data)
          .then((res) => {
            if (res.status == 200) {
              setAllGroups(res.data.groups);
              setShowGroups("block");
              setShowMembers("none");
            }
          });
      } else {
        setShowGroups("block");
        setShowMembers("none");
      }
    }
    $("button,input[type='radio']").prop("disabled", false);
  };
  const submitForm = (e) => {
    e.preventDefault();
    if (!assignedTo) {
      return null;
    }
    let assignmentID = "";
    if (assignedTo == "group") {
      assignmentID = $("#assign-to-group").val();
    } else if (assignedTo == "member") {
      assignmentID = $("#assign-to-member").val();
    }
    if (!assignmentID || assignmentID == "") {
      return null; 
    }
    let priority=$("#priority").val()
    let type=$("#type").val()
    let req_data={}
    if(conv_id){
      req_data=Variables.create_req_data({action:"add",subject:subject,body:body,type:type,priority:priority,dueDate:dueDate,assigned_to_id:assignmentID,assigned_to:assignedTo,conv_id:conv_id})
    }else{
      req_data=Variables.create_req_data({action:"add",subject:subject,body:body,type:type,priority:priority,dueDate:dueDate,assigned_to_id:assignmentID,assigned_to:assignedTo})
    }
    Variables.loading_btn("#create-ticket-btn","Creating")
    axios.post(Variables.serverUrl("tickets/"),req_data).then((res)=>{
      if(res.status==200 || res.status>=res.status){
        if(typeof func==="function"){
          func(res.data.uid)
        }else{
          window.location.reload()
        }
      }
      Variables.unloading_btn("#create-ticket-btn")
      handleClose()
    })
  };
  return (
    <>
      <Modal
        id="create-group"
        show={show}
        onHide={handleClose}
        backdrop="static"
        fullscreen="md-down"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="create-group-form" onSubmit={submitForm}>
            <label>Subject</label>
            <input
              maxLength={250}
              className="form-control"
              onChange={(e) => setSubject(e.target.value)}
              required
            />
            <div className="form-text">Max. 250 characters</div>
            <br />
            <label>Body</label>
            <textarea
              className="form-control"
              onChange={(e) => setBody(e.target.value)}
              required
            ></textarea>
            <br />
            <div
              className="flex-horizon"
              style={{ justifyContent: "space-between" }}
            >
              <div>
                <label>Priority</label>
                <select
                  id="priority"
                  className="form-select"
                  required
                >
                  <option value="high" selected>
                    High
                  </option>
                  <option value="medium">Medium</option>
                  <option value="Low">Low</option>
                </select>
              </div>
              <div>
                <label>Type</label>
                <select
                  id="type"
                  className="form-select"
                  required
                >
                  <option value="question" selected>
                    Question
                  </option>
                  <option value="task">Task</option>
                  <option value="incident">Incident</option>
                  <option value="problem">Problem</option>
                </select>
              </div>
            </div>
            <br />
            <label>Due date</label>
            <input
              className="form-control"
              type="date"
              onChange={(e) => setDueDate(e.target.value)}
              required
            />
            <br />
            <label>Assign to</label>
            <ul className="flex-horizon" style={{ listStyle: "none" }}>
              <li>
                <input
                  className="form-check-input"
                  type="radio"
                  name="assign_to"
                  value="group"
                  onChange={assignmentChanged}
                />
                &nbsp;<label>Group</label>
              </li>
              &nbsp;&nbsp;
              <li>
                <input
                  className="form-check-input"
                  type="radio"
                  name="assign_to"
                  value="member"
                  onChange={assignmentChanged}
                />
                &nbsp;<label>Member</label>
              </li>
            </ul>
            <select
              id="assign-to-group"
              className="form-select"
              style={{ display: showGroups }}
            >
              {allGroups.map((item, index) => (
                <option value={item.uid}>{item.name}</option>
              ))}
            </select>
            <select
              id="assign-to-member"
              className="form-select"
              style={{ display: showMembers }}
            >
              {allMembers.map((item, index) => (
                <option value={item.uid}>
                  {item.name} ({item.email})
                </option>
              ))}
            </select>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            id="create-ticket-btn"
            className="theme-btn"
            variant="primary"
            type="submit"
            form="create-group-form"
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateTicket;
