import { useState } from "react";
import CancelSubscription from "../modals/CancelSubscription";
import { Link } from "react-router-dom";
import crownIcon from "../images/crown-white.svg"

function capitalizeFirstLetter(str) {
  if (!str) return str;

  return str.charAt(0).toUpperCase() + str.slice(1);
}

function bytesToSize(bytes) {
  const units = ["B", "KB", "MB", "GB"];
  let unitIndex = 0;

  while (bytes >= 1024 && unitIndex < units.length - 1) {
    bytes /= 1024;
    unitIndex++;
  }

  const size = bytes.toFixed(2);
  const unit = units[unitIndex];

  return size + " " + unit;
}
const fileSizeInBytes = 1024 * 1024 * 100;

function Section1({ data }) {
  const [cancelShow, setCancelShow] = useState(false);
  const handleCancelShow = () => setCancelShow(true);
  const handleCancelClose = () => setCancelShow(false);
  return (
    <>
      <div className="section-1 row">
        <span className="col-lg-6 col-md-6 col-sm-12">
          <h5>Name</h5>
          <p>
            <b>{data.name}</b>
          </p>
        </span>
        <span className="col-lg-6 col-md-6 col-sm-12">
          <h5>Email</h5>
          <p>
            <b>{data.email}</b>
          </p>
        </span>
        <span className="col-lg-6 col-md-6 col-sm-12">
          <h5>Organization</h5>
          <p>
            <b>{data.organisation}</b>
          </p>
        </span>
        <span className="col-lg-6 col-md-6 col-sm-12"></span>
        <span className="col-lg-6 col-md-6 col-sm-12">
          <h5>Country</h5>
          <b>{data.country}</b>
        </span>
        <span className="col-lg-6 col-md-6 col-sm-12">
          <h5>Your active plan</h5>
          <div className="flex-horizon">
            <h2>{capitalizeFirstLetter(data.plan)}</h2>&nbsp;/&nbsp;
            {data.profile.plan_duration}
          </div>

          <small className="form-text">
            {data.plan.includes("appsumo")?<>Valid till: Lifetime</>:<>Valid till: {data.profile.valid_till}</>}
          </small>
        </span>
        <span className="col-lg-6 col-md-6 col-sm-12">
          <h5>Usage</h5>
          <b>Messages- {data.usage.messages + " / " + data.credits.messages}</b>
          <br />
          <b>Tokens- {data.usage.tokens + " / " + data.credits.tokens}</b>
          <br />
          <b>
            Storage-{" "}
            {bytesToSize(data.usage.storage || 0) +
              " / " +
              bytesToSize(data.credits.storage || 0)}
          </b>
        </span>

        {data.is_paid_customer ? (
          <>
            <span className="col-lg-6 col-md-6 col-sm-12">
              {!data.cancelled ? (
                <>
                  <button
                    onClick={handleCancelShow}
                    className="btn btn-sm btn-danger round-btn"
                  >
                    Cancel subscription
                  </button>
                </>
              ) : (
                <>
                  <p
                    className="btn btn-danger round-btn disabled"
                    style={{ color: "white" }}
                  >
                    Subscription cancelled
                  </p>
                </>
              )}
            </span>
          </>
        ) : (
          <></>
        )}
        {data.cancelled == undefined || !data.cancelled ? (
          <>
            <div
              id="upgrade-card"
              className="card"
              style={{
                maxWidth: "350px",
                width: "100% !important",
                padding: "1rem",
              }}
            >
              <div
                className="card-header"
                style={{ border: "none", background: "#ffffff00" }}
              >
                <h3><img src={crownIcon} height={"30px"}/> Get more premium</h3>
              </div>
              <div className="card-body" style={{ width: "100%" }}>
                <small>
                  &#x2714; Unlock premium CRM features
                  <br />
                  &#x2714; Increased limits
                  <br />
                  &#x2714; Additional credits with our upgrade.
                  <br />
                  &#x2714; Elevate your customer relationships and maximize your
                  productivity today.
                </small>
                <br />
                <br />
                <Link to="/plans" className="btn btn-light round-btn">
                  &#x21d1; Upgrade now
                </Link>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        <br />
        <br />
        <br />
      </div>
      <br />
      <CancelSubscription show={cancelShow} handleClose={handleCancelClose} />
    </>
  );
}

export default Section1;
