import axios from "axios";
import { useLayoutEffect, useEffect, useState } from "react";
import Variables from "../variables";
import $ from "jquery";

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  const formattedTime = date.toLocaleTimeString('en-US');
  return `${formattedDate} ${formattedTime}`;
}

function Section2({ id, data, setFormName }) {
  const [submissions, setSubmissions] = useState([]);
  const [uniqueLabels, setUniqueLabels] = useState([]);
  useLayoutEffect(() => {
    (async () => {
      let req_data = Variables.create_req_data({
        id: id,
        action: "get",
      });
      await axios
        .post(Variables.serverUrl("submission/"), req_data)
        .then((res) => {
          let all_submissions = [];
          res.data.data.forEach((ele) => {
            if (JSON.parse(ele.data).length) {
              let sub=JSON.parse(ele.data)
              sub.push({"label":"Submitted on","value":formatDate(ele.date_created)})
              all_submissions.push(sub);
            }
          });
          setUniqueLabels(
            Object.keys(
              all_submissions.reduce((acc, item) => {
                item.forEach((row) => {
                  acc[row.label] = true;
                });
                return acc;
              }, {})
            )
          );
          setSubmissions(all_submissions);
          data(all_submissions);
          $("#total-submissions-count").html(all_submissions.length);
          $("#form-name").html(res.data.form_name);
          setFormName(res.data.form_name)
        });
    })();
  }, []);
  return (
    <div className="middle submissions">
      <div style={{overflow:"scroll",width:"100%"}}>
        <table id="tickets-table" class="table table-hover table-borderless" style={{"maxWidth":"100%"}}>
          <thead className="table-dark">
            <tr>
              {uniqueLabels.map((label) => (
                <th style={{minWidth:"200px"}} key={label}>{label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {submissions.map((item, index) => (
              <tr key={index} className="ticket-row">
                {uniqueLabels.map((label, cellIndex) => {
                  const cellData = item.find((row) => row.label === label);
                  return (
                    <td key={cellIndex}>{cellData ? cellData.value : null}</td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Section2;
