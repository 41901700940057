import React, { useEffect, useState } from "react";
import "./css/Leftnav.css";
import Logo from "./images/bot.png";
import bot from "./images/bot.svg";
import form from "./images/form.png";
import support from "./images/support.png";
import logout from "./images/logout.png";
import add from "./images/add.svg";
import knowledge_base from "./images/knowledge_base.svg";
import { Link, useNavigate } from "react-router-dom";
import NewBot from "./modals/NewBot";
import gear from "./images/gear.svg";
import group from "./images/group.png";
import user from "./images/user.svg";
import ticket from "./images/ticket.png";
import email from "./images/email-dark.png";
import $ from "jquery";

const is_member = localStorage.getItem("sharurai_member_login");

function Leftnav() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let navigate = new useNavigate();
  useEffect(() => {
    $("#logout-btn").on("click", function () {
      localStorage.removeItem("sharurai_access_key");
      localStorage.removeItem("sharurai_refresh_key");
      localStorage.removeItem("sharurai_member_login");
      navigate("/login");
    });
  });
  return (
    <div id="leftnav">
      <div className="logo">
        <img src={Logo} />
        <font>SharurAI</font>
      </div>
      <ul className="icons">
        {is_member == "true" ? (
          <></>
        ) : (
          <>
            <li className="left-nav-link new-bot-link">
              <a onClick={handleShow}>
                <img src={add} className="icon left-nav-icon" />
                <font>New bot</font>
              </a>
            </li>
            <NewBot show={show} handleClose={handleClose} />
          </>
        )}
        <li className="left-nav-link dashboard-link">
          <Link to="/app/bots">
            <img src={bot} className="icon left-nav-icon" />
            <font>My Bots</font>
          </Link>
        </li>
        <li className="left-nav-link forms-link">
          <Link to="/app/forms">
            <img src={form} className="icon left-nav-icon" />
            <font>Forms</font>
          </Link>
        </li>
        {is_member == "true" ? (
          <></>
        ) : (
          <li className="left-nav-link knowledge-base-link">
            <Link to="/app/knowledge-bases">
              <img src={knowledge_base} className="icon left-nav-icon" />
              <font>Knowledge Base</font>
            </Link>
          </li>
        )}
        <li className="left-nav-link tickets-link">
          <Link to="/app/tickets">
            <img src={ticket} className="icon left-nav-icon" />
            <font>Tickets</font>
          </Link>
        </li>
        <li className="left-nav-link groups-link">
          <Link to="/app/groups">
            <img src={group} className="icon left-nav-icon" />
            <font>Groups</font>
          </Link>
        </li>
        {is_member == "true" ? (
          <></>
        ) : (
          <li className="left-nav-link members-link">
            <Link to="/app/members">
              <img src={user} className="icon left-nav-icon" />
              <font>Members</font>
            </Link>
          </li>
        )}
        {is_member == "true" ? (
          <></>
        ) : (
          <li className="left-nav-link email-link">
            <Link to="/app/emails">
              <img src={email} className="icon left-nav-icon" />
              <font>Email</font>
            </Link>
          </li>
        )}
        {is_member == "true" ? (
          <></>
        ) : (
          <li className="left-nav-link profile-link">
            <Link to="/app/profile">
              <img src={gear} className="icon left-nav-icon" />
              <font>Settings</font>
            </Link>
          </li>
        )}
        <li className="left-nav-link logout-link" id="logout-btn">
          <a to="#">
            <img src={logout} className="icon left-nav-icon" />
            <font>Logout</font>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Leftnav;
