import React from "react";
import "./css/Header.css"

const Header=()=>{
    return (
        <nav style={{display:'none'}} id="navbar" className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="top-navbar-container">
                    <form className="d-flex">
                        <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                    </form>
                    <div className="right">
                        
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Header;