import React from "react";
import Insights from "./home/Insights";
import Bot_info from "./home/Bot_info";
import Intent from "./Intent";
import "./css/Home.css";
import { Routes, Route } from "react-router-dom";
import TestBot from "./TestBot";
import Livechat from "./Livechat";

function Home() {
  return (
    <>
      <Routes>
        <Route
          exact
          path="/app/insights"
          element={
            <>
              <Insights />
            </>
          }
        />
      </Routes>
      <Routes>
        <Route
          exact
          path="/app/bot-info"
          element={
            <>
              <Bot_info />
            </>
          }
        />
      </Routes>
      <Routes>
        <Route
          exact
          path="/app/intent"
          element={
            <>
              <Intent />
            </>
          }
        />
      </Routes>
      <Routes>
        <Route
          exact
          path="/app/livechat"
          element={
            <>
              <Livechat />
            </>
          }
        />
      </Routes>
      <Routes>
        <Route
          exact
          path="app/test-bot/"
          element={
            <>
              <TestBot />
            </>
          }
        />
      </Routes>
    </>
  );
}

export default Home;
